var locale = {
  _lang: 'zh-CN',
  followPrimary: '使用主色',
  reset: '重置',
  next: '下一步',
  groupView: '分组显示',
  fill: '填充',
  border: '描边',
  background: '背景',
  text: '文本',
  title: '主题编辑器',
  globalToken: '全局',
  componentToken: '组件',
  advancedMode: '高级模式',
  basicMode: '基础模式',
  export: '导出',
  import: '导入',
  color: '颜色',
  size: '尺寸',
  style: '风格',
  component: '组件',
  themeConfig: '主题配置',
  algorithmTip: "\u6CE8\u610F\uFF1A\u7B97\u6CD5\u914D\u7F6E\u4E2D\u7684\u5B57\u7B26\u4E32 dark \u548C compact \u5206\u522B\u8868\u793A\u6697\u8272\u4E0E\u7D27\u51D1\u7B97\u6CD5\uFF0C\u8FD9\u4EC5\u9002\u7528\u4E8E\u5728\u4E3B\u9898\u7F16\u8F91\u5668\u914D\u7F6E\u4E2D\u4F7F\u7528\u3002\u5982\u679C\u9700\u8981\u590D\u5236\u5230\u4EE3\u7801\u4E2D\u4F7F\u7528\uFF0C\u8BF7\u624B\u52A8\u66FF\u6362\u4E3A\u4ECE antd \u4E2D\u5F15\u5165\u7684 theme.darkAlgorithm \u548C theme.compactAlgorithm\u3002",
  total: '共',
  changes: '处修改',
  tokenInfo: 'Token 列表',
  enableComponentAlgorithm: '开启算法',
  componentAlgorithm: '开启后，组件维度的 seed token 将会进行派生计算影响其他 token 的值',
  presetColors: '预设颜色',
  demo: {
    overview: '风格概览',
    components: '组件',
    relatedTokens: '关联 Token',
    page: '页面示例',
    fitView: '适应视图',
    zoomTo: '缩放到',
    relatedTokensDescription: '相关 Token，点击查看影响范围',
    tokenCount: function tokenCount(total, global, component) {
      return "Token \u6570: ".concat(total, " (").concat(global, " \u4E2A\u5168\u5C40 Token / ").concat(component, " \u4E2A\u7EC4\u4EF6 Token)");
    }
  },
  components: {
    General: '通用',
    Layout: '布局',
    Navigation: '导航',
    'Data Entry': '数据输入',
    'Data Display': '数据展示',
    Feedback: '反馈',
    Button: '按钮',
    Icon: '图标',
    Typography: '排版',
    Divider: '分割线',
    Grid: '栅格',
    Space: '间距',
    Anchor: '锚点',
    Breadcrumb: '面包屑',
    Dropdown: '下拉菜单',
    Menu: '菜单',
    Pagination: '分页',
    Steps: '步骤条',
    AutoComplete: '自动完成',
    Cascader: '级联选择',
    Checkbox: '多选框',
    DatePicker: '日期选择框',
    Form: '表单',
    Input: '输入框',
    InputNumber: '数字输入框',
    Mentions: '提及',
    Radio: '单选框',
    Rate: '评分',
    Select: '选择器',
    Slider: '滑动输入条',
    Switch: '开关',
    TimePicker: '时间选择框',
    Transfer: '穿梭框',
    TreeSelect: '树选择',
    Upload: '上传',
    Avatar: '头像',
    Badge: '徽标数',
    Calendar: '日历',
    Card: '卡片',
    Carousel: '走马灯',
    Collapse: '折叠面板',
    Descriptions: '描述列表',
    Empty: '空状态',
    Image: '图片',
    List: '列表',
    Popover: '气泡卡片',
    Segmented: '分段器',
    Statistic: '统计数值',
    Table: '表格',
    Tabs: '标签页',
    Tag: '标签',
    Timeline: '时间轴',
    Tooltip: '文字提示',
    Tree: '树形控件',
    Alert: '警告提示',
    Drawer: '抽屉',
    Message: '全局提示',
    Modal: '对话框',
    Notification: '通知提醒框',
    Popconfirm: '气泡确认框',
    Progress: '进度条',
    Result: '结果',
    Skeleton: '骨架屏',
    Spin: '加载中'
  }
};
export default locale;