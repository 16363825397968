import ColorChunk from '/opt/buildhome/repo/.dumi/theme/builtins/ColorChunk/index.tsx';
import API from '/opt/buildhome/repo/node_modules/dumi/theme-default/builtins/API/index.js';
import Badge from '/opt/buildhome/repo/node_modules/dumi/theme-default/builtins/Badge/index.js';
import CodeGroup from '/opt/buildhome/repo/node_modules/dumi/theme-default/builtins/CodeGroup/index.js';
import Container from '/opt/buildhome/repo/.dumi/theme/builtins/Container/index.tsx';
import Previewer from '/opt/buildhome/repo/.dumi/theme/builtins/Previewer/index.tsx';
import SourceCode from '/opt/buildhome/repo/node_modules/dumi/theme-default/builtins/SourceCode/index.js';
import Table from '/opt/buildhome/repo/node_modules/dumi/theme-default/builtins/Table/index.js';
import Tree from '/opt/buildhome/repo/node_modules/dumi/theme-default/builtins/Tree/index.js';
import APITable from '/opt/buildhome/repo/.dumi/theme/builtins/APITable/index.tsx';
import ColorPalettes from '/opt/buildhome/repo/.dumi/theme/builtins/ColorPalettes/index.ts';
import ColorPaletteTool from '/opt/buildhome/repo/.dumi/theme/builtins/ColorPaletteTool/index.ts';
import ColorPaletteToolDark from '/opt/buildhome/repo/.dumi/theme/builtins/ColorPaletteToolDark/index.ts';
import ComponentMeta from '/opt/buildhome/repo/.dumi/theme/builtins/ComponentMeta/index.tsx';
import ComponentOverview from '/opt/buildhome/repo/.dumi/theme/builtins/ComponentOverview/index.tsx';
import ComponentTokenTable from '/opt/buildhome/repo/.dumi/theme/builtins/ComponentTokenTable/index.tsx';
import DemoWrapper from '/opt/buildhome/repo/.dumi/theme/builtins/DemoWrapper/index.tsx';
import IconSearch from '/opt/buildhome/repo/.dumi/theme/builtins/IconSearch/index.tsx';
import ImagePreview from '/opt/buildhome/repo/.dumi/theme/builtins/ImagePreview/index.tsx';
import InlinePopover from '/opt/buildhome/repo/.dumi/theme/builtins/InlinePopover/index.tsx';
import InstallDependencies from '/opt/buildhome/repo/.dumi/theme/builtins/InstallDependencies/index.tsx';
import LocaleLink from '/opt/buildhome/repo/.dumi/theme/builtins/LocaleLink/index.tsx';
import Palette from '/opt/buildhome/repo/.dumi/theme/builtins/Palette/index.ts';
import ResourceArticles from '/opt/buildhome/repo/.dumi/theme/builtins/ResourceArticles/index.tsx';
import ResourceCards from '/opt/buildhome/repo/.dumi/theme/builtins/ResourceCards/index.tsx';
import Sandpack from '/opt/buildhome/repo/.dumi/theme/builtins/Sandpack/index.tsx';
import TokenCompare from '/opt/buildhome/repo/.dumi/theme/builtins/TokenCompare/index.tsx';
import TokenTable from '/opt/buildhome/repo/.dumi/theme/builtins/TokenTable/index.tsx';
import VideoPlayer from '/opt/buildhome/repo/.dumi/theme/builtins/VideoPlayer/index.tsx';
import { DumiDemo } from 'dumi';
import { DumiDemoGrid } from 'dumi';
import { Link } from 'dumi';

import LoadingComponent from '@@/dumi/theme/loading';
import React, { Suspense } from 'react';
import { DumiPage, useTabMeta, useRouteMeta } from 'dumi';

function DumiMarkdownInner() {
  const { texts: $$contentTexts } = useTabMeta();

  return <><div className="markdown"><h2 id="组件定义"><LocaleLink aria-hidden="true" tabIndex="-1" href="#组件定义" sourceType="a"><span className="icon icon-link" /></LocaleLink>{"组件定义"}</h2><p>{$$contentTexts[0].value}</p></div><DumiDemo {...{
  "demo": {
    "id": "components-progress-index-tab-design-demo-behavior-pattern",
    "inline": true
  },
  "previewerProps": {}
}} /><div className="markdown"><h2 id="基础使用"><LocaleLink aria-hidden="true" tabIndex="-1" href="#基础使用" sourceType="a"><span className="icon icon-link" /></LocaleLink>{"基础使用"}</h2></div><DumiDemo {...{
  "demo": {
    "id": "components-progress-index-tab-design-demo-progress"
  },
  "previewerProps": {
    "description": "<p>以线形展示总进度和已完成进度，是最基础的使用方式</p>",
    "title": "了解任务进度",
    "filename": "components/progress/design/demo/progress.tsx",
    "pkgDependencyList": {
      "@ant-design/compatible": "^5.1.3",
      "@ant-design/happy-work-theme": "^1.0.0",
      "@ant-design/tools": "^18.0.2",
      "@antv/g6": "^4.8.24",
      "@babel/eslint-plugin": "^7.24.7",
      "@biomejs/biome": "^1.8.3",
      "@codesandbox/sandpack-react": "^2.18.0",
      "@dnd-kit/core": "^6.1.0",
      "@dnd-kit/modifiers": "^7.0.0",
      "@dnd-kit/sortable": "^8.0.0",
      "@dnd-kit/utilities": "^3.2.2",
      "@emotion/react": "^11.11.4",
      "@emotion/css": "^11.11.2",
      "@emotion/server": "^11.11.0",
      "@ianvs/prettier-plugin-sort-imports": "^4.3.1",
      "@inquirer/prompts": "^5.1.2",
      "@madccc/duplicate-package-checker-webpack-plugin": "^1.0.0",
      "@microflash/rehype-figure": "^2.1.0",
      "@npmcli/run-script": "^8.1.0",
      "@octokit/rest": "^21.0.0",
      "@qixian.cs/github-contributors-list": "^2.0.2",
      "@size-limit/file": "^11.1.4",
      "@stackblitz/sdk": "^1.11.0",
      "@testing-library/dom": "^10.3.2",
      "@testing-library/jest-dom": "^6.4.6",
      "@testing-library/react": "^16.0.0",
      "@testing-library/user-event": "^14.5.2",
      "@types/adm-zip": "^0.5.5",
      "@types/ali-oss": "^6.16.11",
      "@types/cli-progress": "^3.11.6",
      "@types/fs-extra": "^11.0.4",
      "@types/gtag.js": "^0.0.20",
      "@types/http-server": "^0.12.4",
      "@types/isomorphic-fetch": "^0.0.39",
      "@types/jest": "^29.5.12",
      "@types/jest-axe": "^3.5.9",
      "@types/jest-environment-puppeteer": "^5.0.6",
      "@types/jest-image-snapshot": "^6.4.0",
      "@types/jquery": "^3.5.30",
      "@types/jsdom": "^21.1.7",
      "@types/lodash": "^4.17.6",
      "@types/minimist": "^1.2.5",
      "@types/node": "^20.14.10",
      "@types/nprogress": "^0.2.3",
      "@types/pixelmatch": "^5.2.6",
      "@types/pngjs": "^6.0.5",
      "@types/prismjs": "^1.26.4",
      "@types/progress": "^2.0.7",
      "@types/qs": "^6.9.15",
      "@types/react": "^18.3.3",
      "@types/react-copy-to-clipboard": "^5.0.7",
      "@types/react-dom": "^18.3.0",
      "@types/react-highlight-words": "^0.20.0",
      "@types/react-resizable": "^3.0.8",
      "@types/semver": "^7.5.8",
      "@types/spinnies": "^0.5.3",
      "@types/tar": "^6.1.13",
      "@types/throttle-debounce": "^5.0.2",
      "@types/warning": "^3.0.3",
      "@typescript-eslint/eslint-plugin": "^7.16.1",
      "@typescript-eslint/parser": "^7.16.1",
      "adm-zip": "^0.5.14",
      "ali-oss": "^6.20.0",
      "antd-img-crop": "^4.22.0",
      "antd-style": "^3.6.2",
      "antd-token-previewer": "^2.0.8",
      "axios": "^1.7.2",
      "chalk": "^4.1.2",
      "cheerio": "1.0.0-rc.12",
      "circular-dependency-plugin": "^5.2.2",
      "cli-progress": "^3.12.0",
      "cross-env": "^7.0.3",
      "cross-fetch": "^4.0.0",
      "dekko": "^0.2.1",
      "dotenv": "^16.4.5",
      "dumi": "~2.4.5",
      "dumi-plugin-color-chunk": "^1.1.1",
      "esbuild-loader": "^4.2.1",
      "eslint": "^8.57.0",
      "eslint-config-airbnb": "^19.0.4",
      "eslint-config-prettier": "^9.1.0",
      "eslint-import-resolver-typescript": "^3.6.1",
      "eslint-plugin-compat": "^6.0.0",
      "eslint-plugin-import": "^2.29.1",
      "eslint-plugin-jest": "^28.6.0",
      "eslint-plugin-jsx-a11y": "^6.8.0",
      "eslint-plugin-lodash": "^7.4.0",
      "eslint-plugin-markdown": "^5.1.0",
      "eslint-plugin-react": "^7.34.4",
      "eslint-plugin-react-hooks": "^4.6.2",
      "eslint-plugin-unicorn": "^55.0.0",
      "fast-glob": "^3.3.2",
      "fetch-jsonp": "^1.3.0",
      "fs-extra": "^11.2.0",
      "gh-pages": "^6.1.1",
      "glob": "^11.0.0",
      "html2sketch": "^1.0.2",
      "http-server": "^14.1.1",
      "husky": "^9.1.2",
      "identity-obj-proxy": "^3.0.0",
      "immer": "^10.1.1",
      "is-ci": "^3.0.1",
      "isomorphic-fetch": "^3.0.0",
      "jest": "^29.7.0",
      "jest-axe": "^9.0.0",
      "jest-canvas-mock": "^2.5.2",
      "jest-environment-jsdom": "^29.7.0",
      "jest-environment-node": "^29.7.0",
      "jest-image-snapshot": "^6.4.0",
      "jest-puppeteer": "^10.0.1",
      "jquery": "^3.7.1",
      "jsdom": "^24.1.0",
      "jsonml-to-react-element": "^1.1.11",
      "jsonml.js": "^0.1.0",
      "lint-staged": "^15.2.7",
      "lodash": "^4.17.21",
      "lunar-typescript": "^1.7.5",
      "lz-string": "^1.5.0",
      "minimist": "^1.2.8",
      "mockdate": "^3.0.5",
      "node-fetch": "^3.3.2",
      "node-notifier": "^10.0.1",
      "nprogress": "^0.2.0",
      "open": "^10.1.0",
      "ora": "^8.0.1",
      "pixelmatch": "^6.0.0",
      "pngjs": "^7.0.0",
      "prettier": "^3.3.3",
      "prettier-plugin-jsdoc": "^1.3.0",
      "pretty-format": "^29.7.0",
      "prismjs": "^1.29.0",
      "puppeteer": "^22.13.0",
      "qs": "^6.12.3",
      "rc-footer": "^0.6.8",
      "rc-tween-one": "^3.0.6",
      "rc-virtual-list": "^3.14.5",
      "react": "^18.3.1",
      "react-copy-to-clipboard": "^5.1.0",
      "react-countup": "^6.5.3",
      "react-dom": "^18.3.1",
      "react-draggable": "^4.4.6",
      "react-fast-marquee": "^1.6.5",
      "react-highlight-words": "^0.20.0",
      "react-infinite-scroll-component": "^6.1.0",
      "react-intersection-observer": "^9.13.0",
      "react-resizable": "^3.0.5",
      "react-router-dom": "^6.24.1",
      "react-sticky-box": "^2.0.5",
      "regenerator-runtime": "^0.14.1",
      "rehype-stringify": "^10.0.0",
      "remark": "^15.0.1",
      "remark-cli": "^12.0.1",
      "remark-gfm": "^4.0.0",
      "remark-lint": "^10.0.0",
      "remark-lint-no-undefined-references": "^5.0.0",
      "remark-preset-lint-recommended": "^7.0.0",
      "remark-rehype": "^11.1.0",
      "runes2": "^1.1.4",
      "semver": "^7.6.2",
      "sharp": "^0.33.4",
      "simple-git": "^3.25.0",
      "size-limit": "^11.1.4",
      "spinnies": "^0.5.1",
      "sylvanas": "^0.6.1",
      "tar": "^7.4.0",
      "tar-fs": "^3.0.6",
      "terser": "^5.31.2",
      "tsx": "4.11.2",
      "typedoc": "^0.26.4",
      "typescript": "~5.5.3",
      "vanilla-jsoneditor": "^0.23.7",
      "vanilla-tilt": "^1.8.1",
      "webpack": "^5.93.0",
      "webpack-bundle-analyzer": "^4.10.2",
      "xhr-mock": "^2.5.1",
      "@ant-design/colors": "^7.1.0",
      "@ant-design/cssinjs": "^1.21.0",
      "@ant-design/cssinjs-utils": "^1.0.3",
      "@ant-design/icons": "^5.4.0",
      "@ant-design/react-slick": "~1.1.2",
      "@babel/runtime": "^7.24.8",
      "@ctrl/tinycolor": "^3.6.1",
      "@rc-component/color-picker": "~1.5.3",
      "@rc-component/mutate-observer": "^1.1.0",
      "@rc-component/qrcode": "~1.0.0",
      "@rc-component/tour": "~1.15.0",
      "@rc-component/trigger": "^2.2.0",
      "classnames": "^2.5.1",
      "copy-to-clipboard": "^3.3.3",
      "dayjs": "^1.11.11",
      "rc-cascader": "~3.27.0",
      "rc-checkbox": "~3.3.0",
      "rc-collapse": "~3.7.3",
      "rc-dialog": "~9.5.2",
      "rc-drawer": "~7.2.0",
      "rc-dropdown": "~4.2.0",
      "rc-field-form": "~2.2.1",
      "rc-image": "~7.9.0",
      "rc-input": "~1.5.1",
      "rc-input-number": "~9.1.0",
      "rc-mentions": "~2.14.0",
      "rc-menu": "~9.14.1",
      "rc-motion": "^2.9.2",
      "rc-notification": "~5.6.0",
      "rc-pagination": "~4.2.0",
      "rc-picker": "~4.6.9",
      "rc-progress": "~4.0.0",
      "rc-rate": "~2.13.0",
      "rc-resize-observer": "^1.4.0",
      "rc-segmented": "~2.3.0",
      "rc-select": "~14.15.1",
      "rc-slider": "~10.6.2",
      "rc-steps": "~6.0.1",
      "rc-switch": "~4.1.0",
      "rc-table": "~7.45.7",
      "rc-tabs": "~15.1.1",
      "rc-textarea": "~1.7.0",
      "rc-tooltip": "~6.2.0",
      "rc-tree": "~5.8.8",
      "rc-tree-select": "~5.22.1",
      "rc-upload": "~4.6.0",
      "rc-util": "^5.43.0",
      "scroll-into-view-if-needed": "^3.1.0",
      "throttle-debounce": "^5.0.2"
    },
    "jsx": "import React from 'react';\nimport { Flex, Progress } from 'antd';\nconst Demo = () => (\n  <Flex vertical gap=\"middle\">\n    <Progress\n      type=\"line\"\n      percent={50}\n      showInfo={false}\n      style={{\n        width: 320,\n      }}\n    />\n    <Progress\n      percent={50}\n      showInfo={false}\n      size=\"small\"\n      style={{\n        width: 100,\n      }}\n    />\n  </Flex>\n);\nexport default Demo;\n"
  }
}} /><DumiDemo {...{
  "demo": {
    "id": "components-progress-index-tab-design-demo-status"
  },
  "previewerProps": {
    "description": "<p>通过已完成进度的颜色，来了解当前任务的状态</p>",
    "title": "了解任务状态",
    "filename": "components/progress/design/demo/status.tsx",
    "pkgDependencyList": {
      "@ant-design/compatible": "^5.1.3",
      "@ant-design/happy-work-theme": "^1.0.0",
      "@ant-design/tools": "^18.0.2",
      "@antv/g6": "^4.8.24",
      "@babel/eslint-plugin": "^7.24.7",
      "@biomejs/biome": "^1.8.3",
      "@codesandbox/sandpack-react": "^2.18.0",
      "@dnd-kit/core": "^6.1.0",
      "@dnd-kit/modifiers": "^7.0.0",
      "@dnd-kit/sortable": "^8.0.0",
      "@dnd-kit/utilities": "^3.2.2",
      "@emotion/react": "^11.11.4",
      "@emotion/css": "^11.11.2",
      "@emotion/server": "^11.11.0",
      "@ianvs/prettier-plugin-sort-imports": "^4.3.1",
      "@inquirer/prompts": "^5.1.2",
      "@madccc/duplicate-package-checker-webpack-plugin": "^1.0.0",
      "@microflash/rehype-figure": "^2.1.0",
      "@npmcli/run-script": "^8.1.0",
      "@octokit/rest": "^21.0.0",
      "@qixian.cs/github-contributors-list": "^2.0.2",
      "@size-limit/file": "^11.1.4",
      "@stackblitz/sdk": "^1.11.0",
      "@testing-library/dom": "^10.3.2",
      "@testing-library/jest-dom": "^6.4.6",
      "@testing-library/react": "^16.0.0",
      "@testing-library/user-event": "^14.5.2",
      "@types/adm-zip": "^0.5.5",
      "@types/ali-oss": "^6.16.11",
      "@types/cli-progress": "^3.11.6",
      "@types/fs-extra": "^11.0.4",
      "@types/gtag.js": "^0.0.20",
      "@types/http-server": "^0.12.4",
      "@types/isomorphic-fetch": "^0.0.39",
      "@types/jest": "^29.5.12",
      "@types/jest-axe": "^3.5.9",
      "@types/jest-environment-puppeteer": "^5.0.6",
      "@types/jest-image-snapshot": "^6.4.0",
      "@types/jquery": "^3.5.30",
      "@types/jsdom": "^21.1.7",
      "@types/lodash": "^4.17.6",
      "@types/minimist": "^1.2.5",
      "@types/node": "^20.14.10",
      "@types/nprogress": "^0.2.3",
      "@types/pixelmatch": "^5.2.6",
      "@types/pngjs": "^6.0.5",
      "@types/prismjs": "^1.26.4",
      "@types/progress": "^2.0.7",
      "@types/qs": "^6.9.15",
      "@types/react": "^18.3.3",
      "@types/react-copy-to-clipboard": "^5.0.7",
      "@types/react-dom": "^18.3.0",
      "@types/react-highlight-words": "^0.20.0",
      "@types/react-resizable": "^3.0.8",
      "@types/semver": "^7.5.8",
      "@types/spinnies": "^0.5.3",
      "@types/tar": "^6.1.13",
      "@types/throttle-debounce": "^5.0.2",
      "@types/warning": "^3.0.3",
      "@typescript-eslint/eslint-plugin": "^7.16.1",
      "@typescript-eslint/parser": "^7.16.1",
      "adm-zip": "^0.5.14",
      "ali-oss": "^6.20.0",
      "antd-img-crop": "^4.22.0",
      "antd-style": "^3.6.2",
      "antd-token-previewer": "^2.0.8",
      "axios": "^1.7.2",
      "chalk": "^4.1.2",
      "cheerio": "1.0.0-rc.12",
      "circular-dependency-plugin": "^5.2.2",
      "cli-progress": "^3.12.0",
      "cross-env": "^7.0.3",
      "cross-fetch": "^4.0.0",
      "dekko": "^0.2.1",
      "dotenv": "^16.4.5",
      "dumi": "~2.4.5",
      "dumi-plugin-color-chunk": "^1.1.1",
      "esbuild-loader": "^4.2.1",
      "eslint": "^8.57.0",
      "eslint-config-airbnb": "^19.0.4",
      "eslint-config-prettier": "^9.1.0",
      "eslint-import-resolver-typescript": "^3.6.1",
      "eslint-plugin-compat": "^6.0.0",
      "eslint-plugin-import": "^2.29.1",
      "eslint-plugin-jest": "^28.6.0",
      "eslint-plugin-jsx-a11y": "^6.8.0",
      "eslint-plugin-lodash": "^7.4.0",
      "eslint-plugin-markdown": "^5.1.0",
      "eslint-plugin-react": "^7.34.4",
      "eslint-plugin-react-hooks": "^4.6.2",
      "eslint-plugin-unicorn": "^55.0.0",
      "fast-glob": "^3.3.2",
      "fetch-jsonp": "^1.3.0",
      "fs-extra": "^11.2.0",
      "gh-pages": "^6.1.1",
      "glob": "^11.0.0",
      "html2sketch": "^1.0.2",
      "http-server": "^14.1.1",
      "husky": "^9.1.2",
      "identity-obj-proxy": "^3.0.0",
      "immer": "^10.1.1",
      "is-ci": "^3.0.1",
      "isomorphic-fetch": "^3.0.0",
      "jest": "^29.7.0",
      "jest-axe": "^9.0.0",
      "jest-canvas-mock": "^2.5.2",
      "jest-environment-jsdom": "^29.7.0",
      "jest-environment-node": "^29.7.0",
      "jest-image-snapshot": "^6.4.0",
      "jest-puppeteer": "^10.0.1",
      "jquery": "^3.7.1",
      "jsdom": "^24.1.0",
      "jsonml-to-react-element": "^1.1.11",
      "jsonml.js": "^0.1.0",
      "lint-staged": "^15.2.7",
      "lodash": "^4.17.21",
      "lunar-typescript": "^1.7.5",
      "lz-string": "^1.5.0",
      "minimist": "^1.2.8",
      "mockdate": "^3.0.5",
      "node-fetch": "^3.3.2",
      "node-notifier": "^10.0.1",
      "nprogress": "^0.2.0",
      "open": "^10.1.0",
      "ora": "^8.0.1",
      "pixelmatch": "^6.0.0",
      "pngjs": "^7.0.0",
      "prettier": "^3.3.3",
      "prettier-plugin-jsdoc": "^1.3.0",
      "pretty-format": "^29.7.0",
      "prismjs": "^1.29.0",
      "puppeteer": "^22.13.0",
      "qs": "^6.12.3",
      "rc-footer": "^0.6.8",
      "rc-tween-one": "^3.0.6",
      "rc-virtual-list": "^3.14.5",
      "react": "^18.3.1",
      "react-copy-to-clipboard": "^5.1.0",
      "react-countup": "^6.5.3",
      "react-dom": "^18.3.1",
      "react-draggable": "^4.4.6",
      "react-fast-marquee": "^1.6.5",
      "react-highlight-words": "^0.20.0",
      "react-infinite-scroll-component": "^6.1.0",
      "react-intersection-observer": "^9.13.0",
      "react-resizable": "^3.0.5",
      "react-router-dom": "^6.24.1",
      "react-sticky-box": "^2.0.5",
      "regenerator-runtime": "^0.14.1",
      "rehype-stringify": "^10.0.0",
      "remark": "^15.0.1",
      "remark-cli": "^12.0.1",
      "remark-gfm": "^4.0.0",
      "remark-lint": "^10.0.0",
      "remark-lint-no-undefined-references": "^5.0.0",
      "remark-preset-lint-recommended": "^7.0.0",
      "remark-rehype": "^11.1.0",
      "runes2": "^1.1.4",
      "semver": "^7.6.2",
      "sharp": "^0.33.4",
      "simple-git": "^3.25.0",
      "size-limit": "^11.1.4",
      "spinnies": "^0.5.1",
      "sylvanas": "^0.6.1",
      "tar": "^7.4.0",
      "tar-fs": "^3.0.6",
      "terser": "^5.31.2",
      "tsx": "4.11.2",
      "typedoc": "^0.26.4",
      "typescript": "~5.5.3",
      "vanilla-jsoneditor": "^0.23.7",
      "vanilla-tilt": "^1.8.1",
      "webpack": "^5.93.0",
      "webpack-bundle-analyzer": "^4.10.2",
      "xhr-mock": "^2.5.1",
      "@ant-design/colors": "^7.1.0",
      "@ant-design/cssinjs": "^1.21.0",
      "@ant-design/cssinjs-utils": "^1.0.3",
      "@ant-design/icons": "^5.4.0",
      "@ant-design/react-slick": "~1.1.2",
      "@babel/runtime": "^7.24.8",
      "@ctrl/tinycolor": "^3.6.1",
      "@rc-component/color-picker": "~1.5.3",
      "@rc-component/mutate-observer": "^1.1.0",
      "@rc-component/qrcode": "~1.0.0",
      "@rc-component/tour": "~1.15.0",
      "@rc-component/trigger": "^2.2.0",
      "classnames": "^2.5.1",
      "copy-to-clipboard": "^3.3.3",
      "dayjs": "^1.11.11",
      "rc-cascader": "~3.27.0",
      "rc-checkbox": "~3.3.0",
      "rc-collapse": "~3.7.3",
      "rc-dialog": "~9.5.2",
      "rc-drawer": "~7.2.0",
      "rc-dropdown": "~4.2.0",
      "rc-field-form": "~2.2.1",
      "rc-image": "~7.9.0",
      "rc-input": "~1.5.1",
      "rc-input-number": "~9.1.0",
      "rc-mentions": "~2.14.0",
      "rc-menu": "~9.14.1",
      "rc-motion": "^2.9.2",
      "rc-notification": "~5.6.0",
      "rc-pagination": "~4.2.0",
      "rc-picker": "~4.6.9",
      "rc-progress": "~4.0.0",
      "rc-rate": "~2.13.0",
      "rc-resize-observer": "^1.4.0",
      "rc-segmented": "~2.3.0",
      "rc-select": "~14.15.1",
      "rc-slider": "~10.6.2",
      "rc-steps": "~6.0.1",
      "rc-switch": "~4.1.0",
      "rc-table": "~7.45.7",
      "rc-tabs": "~15.1.1",
      "rc-textarea": "~1.7.0",
      "rc-tooltip": "~6.2.0",
      "rc-tree": "~5.8.8",
      "rc-tree-select": "~5.22.1",
      "rc-upload": "~4.6.0",
      "rc-util": "^5.43.0",
      "scroll-into-view-if-needed": "^3.1.0",
      "throttle-debounce": "^5.0.2"
    },
    "jsx": "import React from 'react';\nimport { Flex, Progress } from 'antd';\nconst Demo = () => (\n  <Flex vertical gap=\"middle\">\n    <Flex>\n      <div\n        style={{\n          width: 106,\n        }}\n      >\n        任务进行中\n      </div>\n      <Progress\n        type=\"line\"\n        percent={50}\n        showInfo={false}\n        style={{\n          width: 320,\n        }}\n      />\n    </Flex>\n    <Flex>\n      <div\n        style={{\n          width: 106,\n        }}\n      >\n        任务完成\n      </div>\n      <Progress\n        type=\"line\"\n        percent={100}\n        status=\"success\"\n        showInfo={false}\n        style={{\n          width: 320,\n        }}\n      />\n    </Flex>\n    <Flex>\n      <div\n        style={{\n          width: 106,\n        }}\n      >\n        任务失败\n      </div>\n      <Progress\n        type=\"line\"\n        percent={30}\n        status=\"exception\"\n        showInfo={false}\n        style={{\n          width: 320,\n        }}\n      />\n    </Flex>\n  </Flex>\n);\nexport default Demo;\n"
  }
}} /><div className="markdown"><h2 id="交互变体"><LocaleLink aria-hidden="true" tabIndex="-1" href="#交互变体" sourceType="a"><span className="icon icon-link" /></LocaleLink>{"交互变体"}</h2></div><DumiDemo {...{
  "demo": {
    "id": "components-progress-index-tab-design-demo-info"
  },
  "previewerProps": {
    "description": "<p>通过文字和图标，查看进度相关描述</p>",
    "title": "查看进度相关描述",
    "filename": "components/progress/design/demo/info.tsx",
    "pkgDependencyList": {
      "@ant-design/compatible": "^5.1.3",
      "@ant-design/happy-work-theme": "^1.0.0",
      "@ant-design/tools": "^18.0.2",
      "@antv/g6": "^4.8.24",
      "@babel/eslint-plugin": "^7.24.7",
      "@biomejs/biome": "^1.8.3",
      "@codesandbox/sandpack-react": "^2.18.0",
      "@dnd-kit/core": "^6.1.0",
      "@dnd-kit/modifiers": "^7.0.0",
      "@dnd-kit/sortable": "^8.0.0",
      "@dnd-kit/utilities": "^3.2.2",
      "@emotion/react": "^11.11.4",
      "@emotion/css": "^11.11.2",
      "@emotion/server": "^11.11.0",
      "@ianvs/prettier-plugin-sort-imports": "^4.3.1",
      "@inquirer/prompts": "^5.1.2",
      "@madccc/duplicate-package-checker-webpack-plugin": "^1.0.0",
      "@microflash/rehype-figure": "^2.1.0",
      "@npmcli/run-script": "^8.1.0",
      "@octokit/rest": "^21.0.0",
      "@qixian.cs/github-contributors-list": "^2.0.2",
      "@size-limit/file": "^11.1.4",
      "@stackblitz/sdk": "^1.11.0",
      "@testing-library/dom": "^10.3.2",
      "@testing-library/jest-dom": "^6.4.6",
      "@testing-library/react": "^16.0.0",
      "@testing-library/user-event": "^14.5.2",
      "@types/adm-zip": "^0.5.5",
      "@types/ali-oss": "^6.16.11",
      "@types/cli-progress": "^3.11.6",
      "@types/fs-extra": "^11.0.4",
      "@types/gtag.js": "^0.0.20",
      "@types/http-server": "^0.12.4",
      "@types/isomorphic-fetch": "^0.0.39",
      "@types/jest": "^29.5.12",
      "@types/jest-axe": "^3.5.9",
      "@types/jest-environment-puppeteer": "^5.0.6",
      "@types/jest-image-snapshot": "^6.4.0",
      "@types/jquery": "^3.5.30",
      "@types/jsdom": "^21.1.7",
      "@types/lodash": "^4.17.6",
      "@types/minimist": "^1.2.5",
      "@types/node": "^20.14.10",
      "@types/nprogress": "^0.2.3",
      "@types/pixelmatch": "^5.2.6",
      "@types/pngjs": "^6.0.5",
      "@types/prismjs": "^1.26.4",
      "@types/progress": "^2.0.7",
      "@types/qs": "^6.9.15",
      "@types/react": "^18.3.3",
      "@types/react-copy-to-clipboard": "^5.0.7",
      "@types/react-dom": "^18.3.0",
      "@types/react-highlight-words": "^0.20.0",
      "@types/react-resizable": "^3.0.8",
      "@types/semver": "^7.5.8",
      "@types/spinnies": "^0.5.3",
      "@types/tar": "^6.1.13",
      "@types/throttle-debounce": "^5.0.2",
      "@types/warning": "^3.0.3",
      "@typescript-eslint/eslint-plugin": "^7.16.1",
      "@typescript-eslint/parser": "^7.16.1",
      "adm-zip": "^0.5.14",
      "ali-oss": "^6.20.0",
      "antd-img-crop": "^4.22.0",
      "antd-style": "^3.6.2",
      "antd-token-previewer": "^2.0.8",
      "axios": "^1.7.2",
      "chalk": "^4.1.2",
      "cheerio": "1.0.0-rc.12",
      "circular-dependency-plugin": "^5.2.2",
      "cli-progress": "^3.12.0",
      "cross-env": "^7.0.3",
      "cross-fetch": "^4.0.0",
      "dekko": "^0.2.1",
      "dotenv": "^16.4.5",
      "dumi": "~2.4.5",
      "dumi-plugin-color-chunk": "^1.1.1",
      "esbuild-loader": "^4.2.1",
      "eslint": "^8.57.0",
      "eslint-config-airbnb": "^19.0.4",
      "eslint-config-prettier": "^9.1.0",
      "eslint-import-resolver-typescript": "^3.6.1",
      "eslint-plugin-compat": "^6.0.0",
      "eslint-plugin-import": "^2.29.1",
      "eslint-plugin-jest": "^28.6.0",
      "eslint-plugin-jsx-a11y": "^6.8.0",
      "eslint-plugin-lodash": "^7.4.0",
      "eslint-plugin-markdown": "^5.1.0",
      "eslint-plugin-react": "^7.34.4",
      "eslint-plugin-react-hooks": "^4.6.2",
      "eslint-plugin-unicorn": "^55.0.0",
      "fast-glob": "^3.3.2",
      "fetch-jsonp": "^1.3.0",
      "fs-extra": "^11.2.0",
      "gh-pages": "^6.1.1",
      "glob": "^11.0.0",
      "html2sketch": "^1.0.2",
      "http-server": "^14.1.1",
      "husky": "^9.1.2",
      "identity-obj-proxy": "^3.0.0",
      "immer": "^10.1.1",
      "is-ci": "^3.0.1",
      "isomorphic-fetch": "^3.0.0",
      "jest": "^29.7.0",
      "jest-axe": "^9.0.0",
      "jest-canvas-mock": "^2.5.2",
      "jest-environment-jsdom": "^29.7.0",
      "jest-environment-node": "^29.7.0",
      "jest-image-snapshot": "^6.4.0",
      "jest-puppeteer": "^10.0.1",
      "jquery": "^3.7.1",
      "jsdom": "^24.1.0",
      "jsonml-to-react-element": "^1.1.11",
      "jsonml.js": "^0.1.0",
      "lint-staged": "^15.2.7",
      "lodash": "^4.17.21",
      "lunar-typescript": "^1.7.5",
      "lz-string": "^1.5.0",
      "minimist": "^1.2.8",
      "mockdate": "^3.0.5",
      "node-fetch": "^3.3.2",
      "node-notifier": "^10.0.1",
      "nprogress": "^0.2.0",
      "open": "^10.1.0",
      "ora": "^8.0.1",
      "pixelmatch": "^6.0.0",
      "pngjs": "^7.0.0",
      "prettier": "^3.3.3",
      "prettier-plugin-jsdoc": "^1.3.0",
      "pretty-format": "^29.7.0",
      "prismjs": "^1.29.0",
      "puppeteer": "^22.13.0",
      "qs": "^6.12.3",
      "rc-footer": "^0.6.8",
      "rc-tween-one": "^3.0.6",
      "rc-virtual-list": "^3.14.5",
      "react": "^18.3.1",
      "react-copy-to-clipboard": "^5.1.0",
      "react-countup": "^6.5.3",
      "react-dom": "^18.3.1",
      "react-draggable": "^4.4.6",
      "react-fast-marquee": "^1.6.5",
      "react-highlight-words": "^0.20.0",
      "react-infinite-scroll-component": "^6.1.0",
      "react-intersection-observer": "^9.13.0",
      "react-resizable": "^3.0.5",
      "react-router-dom": "^6.24.1",
      "react-sticky-box": "^2.0.5",
      "regenerator-runtime": "^0.14.1",
      "rehype-stringify": "^10.0.0",
      "remark": "^15.0.1",
      "remark-cli": "^12.0.1",
      "remark-gfm": "^4.0.0",
      "remark-lint": "^10.0.0",
      "remark-lint-no-undefined-references": "^5.0.0",
      "remark-preset-lint-recommended": "^7.0.0",
      "remark-rehype": "^11.1.0",
      "runes2": "^1.1.4",
      "semver": "^7.6.2",
      "sharp": "^0.33.4",
      "simple-git": "^3.25.0",
      "size-limit": "^11.1.4",
      "spinnies": "^0.5.1",
      "sylvanas": "^0.6.1",
      "tar": "^7.4.0",
      "tar-fs": "^3.0.6",
      "terser": "^5.31.2",
      "tsx": "4.11.2",
      "typedoc": "^0.26.4",
      "typescript": "~5.5.3",
      "vanilla-jsoneditor": "^0.23.7",
      "vanilla-tilt": "^1.8.1",
      "webpack": "^5.93.0",
      "webpack-bundle-analyzer": "^4.10.2",
      "xhr-mock": "^2.5.1",
      "@ant-design/colors": "^7.1.0",
      "@ant-design/cssinjs": "^1.21.0",
      "@ant-design/cssinjs-utils": "^1.0.3",
      "@ant-design/icons": "^5.4.0",
      "@ant-design/react-slick": "~1.1.2",
      "@babel/runtime": "^7.24.8",
      "@ctrl/tinycolor": "^3.6.1",
      "@rc-component/color-picker": "~1.5.3",
      "@rc-component/mutate-observer": "^1.1.0",
      "@rc-component/qrcode": "~1.0.0",
      "@rc-component/tour": "~1.15.0",
      "@rc-component/trigger": "^2.2.0",
      "classnames": "^2.5.1",
      "copy-to-clipboard": "^3.3.3",
      "dayjs": "^1.11.11",
      "rc-cascader": "~3.27.0",
      "rc-checkbox": "~3.3.0",
      "rc-collapse": "~3.7.3",
      "rc-dialog": "~9.5.2",
      "rc-drawer": "~7.2.0",
      "rc-dropdown": "~4.2.0",
      "rc-field-form": "~2.2.1",
      "rc-image": "~7.9.0",
      "rc-input": "~1.5.1",
      "rc-input-number": "~9.1.0",
      "rc-mentions": "~2.14.0",
      "rc-menu": "~9.14.1",
      "rc-motion": "^2.9.2",
      "rc-notification": "~5.6.0",
      "rc-pagination": "~4.2.0",
      "rc-picker": "~4.6.9",
      "rc-progress": "~4.0.0",
      "rc-rate": "~2.13.0",
      "rc-resize-observer": "^1.4.0",
      "rc-segmented": "~2.3.0",
      "rc-select": "~14.15.1",
      "rc-slider": "~10.6.2",
      "rc-steps": "~6.0.1",
      "rc-switch": "~4.1.0",
      "rc-table": "~7.45.7",
      "rc-tabs": "~15.1.1",
      "rc-textarea": "~1.7.0",
      "rc-tooltip": "~6.2.0",
      "rc-tree": "~5.8.8",
      "rc-tree-select": "~5.22.1",
      "rc-upload": "~4.6.0",
      "rc-util": "^5.43.0",
      "scroll-into-view-if-needed": "^3.1.0",
      "throttle-debounce": "^5.0.2"
    },
    "jsx": "import React from 'react';\nimport { Flex, Progress } from 'antd';\nconst Demo = () => (\n  <Flex vertical gap=\"middle\">\n    <Progress\n      type=\"line\"\n      percent={50}\n      style={{\n        width: 320,\n      }}\n    />\n    <Progress\n      percent={50}\n      format={() => '加载中'}\n      style={{\n        width: 320,\n      }}\n    />\n    <Progress\n      percent={100}\n      status=\"success\"\n      style={{\n        width: 320,\n      }}\n    />\n    <Progress\n      percent={70}\n      status=\"exception\"\n      style={{\n        width: 320,\n      }}\n    />\n  </Flex>\n);\nexport default Demo;\n"
  }
}} /><div className="markdown"><h2 id="样式变体"><LocaleLink aria-hidden="true" tabIndex="-1" href="#样式变体" sourceType="a"><span className="icon icon-link" /></LocaleLink>{"样式变体"}</h2></div><DumiDemo {...{
  "demo": {
    "id": "components-progress-index-tab-design-demo-circle"
  },
  "previewerProps": {
    "description": "<p>以环形展示进度，多用于需要强调百分比的场景，如 Dashboard</p>",
    "title": "环形进度条",
    "filename": "components/progress/design/demo/circle.tsx",
    "pkgDependencyList": {
      "@ant-design/compatible": "^5.1.3",
      "@ant-design/happy-work-theme": "^1.0.0",
      "@ant-design/tools": "^18.0.2",
      "@antv/g6": "^4.8.24",
      "@babel/eslint-plugin": "^7.24.7",
      "@biomejs/biome": "^1.8.3",
      "@codesandbox/sandpack-react": "^2.18.0",
      "@dnd-kit/core": "^6.1.0",
      "@dnd-kit/modifiers": "^7.0.0",
      "@dnd-kit/sortable": "^8.0.0",
      "@dnd-kit/utilities": "^3.2.2",
      "@emotion/react": "^11.11.4",
      "@emotion/css": "^11.11.2",
      "@emotion/server": "^11.11.0",
      "@ianvs/prettier-plugin-sort-imports": "^4.3.1",
      "@inquirer/prompts": "^5.1.2",
      "@madccc/duplicate-package-checker-webpack-plugin": "^1.0.0",
      "@microflash/rehype-figure": "^2.1.0",
      "@npmcli/run-script": "^8.1.0",
      "@octokit/rest": "^21.0.0",
      "@qixian.cs/github-contributors-list": "^2.0.2",
      "@size-limit/file": "^11.1.4",
      "@stackblitz/sdk": "^1.11.0",
      "@testing-library/dom": "^10.3.2",
      "@testing-library/jest-dom": "^6.4.6",
      "@testing-library/react": "^16.0.0",
      "@testing-library/user-event": "^14.5.2",
      "@types/adm-zip": "^0.5.5",
      "@types/ali-oss": "^6.16.11",
      "@types/cli-progress": "^3.11.6",
      "@types/fs-extra": "^11.0.4",
      "@types/gtag.js": "^0.0.20",
      "@types/http-server": "^0.12.4",
      "@types/isomorphic-fetch": "^0.0.39",
      "@types/jest": "^29.5.12",
      "@types/jest-axe": "^3.5.9",
      "@types/jest-environment-puppeteer": "^5.0.6",
      "@types/jest-image-snapshot": "^6.4.0",
      "@types/jquery": "^3.5.30",
      "@types/jsdom": "^21.1.7",
      "@types/lodash": "^4.17.6",
      "@types/minimist": "^1.2.5",
      "@types/node": "^20.14.10",
      "@types/nprogress": "^0.2.3",
      "@types/pixelmatch": "^5.2.6",
      "@types/pngjs": "^6.0.5",
      "@types/prismjs": "^1.26.4",
      "@types/progress": "^2.0.7",
      "@types/qs": "^6.9.15",
      "@types/react": "^18.3.3",
      "@types/react-copy-to-clipboard": "^5.0.7",
      "@types/react-dom": "^18.3.0",
      "@types/react-highlight-words": "^0.20.0",
      "@types/react-resizable": "^3.0.8",
      "@types/semver": "^7.5.8",
      "@types/spinnies": "^0.5.3",
      "@types/tar": "^6.1.13",
      "@types/throttle-debounce": "^5.0.2",
      "@types/warning": "^3.0.3",
      "@typescript-eslint/eslint-plugin": "^7.16.1",
      "@typescript-eslint/parser": "^7.16.1",
      "adm-zip": "^0.5.14",
      "ali-oss": "^6.20.0",
      "antd-img-crop": "^4.22.0",
      "antd-style": "^3.6.2",
      "antd-token-previewer": "^2.0.8",
      "axios": "^1.7.2",
      "chalk": "^4.1.2",
      "cheerio": "1.0.0-rc.12",
      "circular-dependency-plugin": "^5.2.2",
      "cli-progress": "^3.12.0",
      "cross-env": "^7.0.3",
      "cross-fetch": "^4.0.0",
      "dekko": "^0.2.1",
      "dotenv": "^16.4.5",
      "dumi": "~2.4.5",
      "dumi-plugin-color-chunk": "^1.1.1",
      "esbuild-loader": "^4.2.1",
      "eslint": "^8.57.0",
      "eslint-config-airbnb": "^19.0.4",
      "eslint-config-prettier": "^9.1.0",
      "eslint-import-resolver-typescript": "^3.6.1",
      "eslint-plugin-compat": "^6.0.0",
      "eslint-plugin-import": "^2.29.1",
      "eslint-plugin-jest": "^28.6.0",
      "eslint-plugin-jsx-a11y": "^6.8.0",
      "eslint-plugin-lodash": "^7.4.0",
      "eslint-plugin-markdown": "^5.1.0",
      "eslint-plugin-react": "^7.34.4",
      "eslint-plugin-react-hooks": "^4.6.2",
      "eslint-plugin-unicorn": "^55.0.0",
      "fast-glob": "^3.3.2",
      "fetch-jsonp": "^1.3.0",
      "fs-extra": "^11.2.0",
      "gh-pages": "^6.1.1",
      "glob": "^11.0.0",
      "html2sketch": "^1.0.2",
      "http-server": "^14.1.1",
      "husky": "^9.1.2",
      "identity-obj-proxy": "^3.0.0",
      "immer": "^10.1.1",
      "is-ci": "^3.0.1",
      "isomorphic-fetch": "^3.0.0",
      "jest": "^29.7.0",
      "jest-axe": "^9.0.0",
      "jest-canvas-mock": "^2.5.2",
      "jest-environment-jsdom": "^29.7.0",
      "jest-environment-node": "^29.7.0",
      "jest-image-snapshot": "^6.4.0",
      "jest-puppeteer": "^10.0.1",
      "jquery": "^3.7.1",
      "jsdom": "^24.1.0",
      "jsonml-to-react-element": "^1.1.11",
      "jsonml.js": "^0.1.0",
      "lint-staged": "^15.2.7",
      "lodash": "^4.17.21",
      "lunar-typescript": "^1.7.5",
      "lz-string": "^1.5.0",
      "minimist": "^1.2.8",
      "mockdate": "^3.0.5",
      "node-fetch": "^3.3.2",
      "node-notifier": "^10.0.1",
      "nprogress": "^0.2.0",
      "open": "^10.1.0",
      "ora": "^8.0.1",
      "pixelmatch": "^6.0.0",
      "pngjs": "^7.0.0",
      "prettier": "^3.3.3",
      "prettier-plugin-jsdoc": "^1.3.0",
      "pretty-format": "^29.7.0",
      "prismjs": "^1.29.0",
      "puppeteer": "^22.13.0",
      "qs": "^6.12.3",
      "rc-footer": "^0.6.8",
      "rc-tween-one": "^3.0.6",
      "rc-virtual-list": "^3.14.5",
      "react": "^18.3.1",
      "react-copy-to-clipboard": "^5.1.0",
      "react-countup": "^6.5.3",
      "react-dom": "^18.3.1",
      "react-draggable": "^4.4.6",
      "react-fast-marquee": "^1.6.5",
      "react-highlight-words": "^0.20.0",
      "react-infinite-scroll-component": "^6.1.0",
      "react-intersection-observer": "^9.13.0",
      "react-resizable": "^3.0.5",
      "react-router-dom": "^6.24.1",
      "react-sticky-box": "^2.0.5",
      "regenerator-runtime": "^0.14.1",
      "rehype-stringify": "^10.0.0",
      "remark": "^15.0.1",
      "remark-cli": "^12.0.1",
      "remark-gfm": "^4.0.0",
      "remark-lint": "^10.0.0",
      "remark-lint-no-undefined-references": "^5.0.0",
      "remark-preset-lint-recommended": "^7.0.0",
      "remark-rehype": "^11.1.0",
      "runes2": "^1.1.4",
      "semver": "^7.6.2",
      "sharp": "^0.33.4",
      "simple-git": "^3.25.0",
      "size-limit": "^11.1.4",
      "spinnies": "^0.5.1",
      "sylvanas": "^0.6.1",
      "tar": "^7.4.0",
      "tar-fs": "^3.0.6",
      "terser": "^5.31.2",
      "tsx": "4.11.2",
      "typedoc": "^0.26.4",
      "typescript": "~5.5.3",
      "vanilla-jsoneditor": "^0.23.7",
      "vanilla-tilt": "^1.8.1",
      "webpack": "^5.93.0",
      "webpack-bundle-analyzer": "^4.10.2",
      "xhr-mock": "^2.5.1",
      "@ant-design/colors": "^7.1.0",
      "@ant-design/cssinjs": "^1.21.0",
      "@ant-design/cssinjs-utils": "^1.0.3",
      "@ant-design/icons": "^5.4.0",
      "@ant-design/react-slick": "~1.1.2",
      "@babel/runtime": "^7.24.8",
      "@ctrl/tinycolor": "^3.6.1",
      "@rc-component/color-picker": "~1.5.3",
      "@rc-component/mutate-observer": "^1.1.0",
      "@rc-component/qrcode": "~1.0.0",
      "@rc-component/tour": "~1.15.0",
      "@rc-component/trigger": "^2.2.0",
      "classnames": "^2.5.1",
      "copy-to-clipboard": "^3.3.3",
      "dayjs": "^1.11.11",
      "rc-cascader": "~3.27.0",
      "rc-checkbox": "~3.3.0",
      "rc-collapse": "~3.7.3",
      "rc-dialog": "~9.5.2",
      "rc-drawer": "~7.2.0",
      "rc-dropdown": "~4.2.0",
      "rc-field-form": "~2.2.1",
      "rc-image": "~7.9.0",
      "rc-input": "~1.5.1",
      "rc-input-number": "~9.1.0",
      "rc-mentions": "~2.14.0",
      "rc-menu": "~9.14.1",
      "rc-motion": "^2.9.2",
      "rc-notification": "~5.6.0",
      "rc-pagination": "~4.2.0",
      "rc-picker": "~4.6.9",
      "rc-progress": "~4.0.0",
      "rc-rate": "~2.13.0",
      "rc-resize-observer": "^1.4.0",
      "rc-segmented": "~2.3.0",
      "rc-select": "~14.15.1",
      "rc-slider": "~10.6.2",
      "rc-steps": "~6.0.1",
      "rc-switch": "~4.1.0",
      "rc-table": "~7.45.7",
      "rc-tabs": "~15.1.1",
      "rc-textarea": "~1.7.0",
      "rc-tooltip": "~6.2.0",
      "rc-tree": "~5.8.8",
      "rc-tree-select": "~5.22.1",
      "rc-upload": "~4.6.0",
      "rc-util": "^5.43.0",
      "scroll-into-view-if-needed": "^3.1.0",
      "throttle-debounce": "^5.0.2"
    },
    "jsx": "import React from 'react';\nimport { Flex, Progress } from 'antd';\nconst Demo = () => (\n  <Flex gap=\"middle\" align=\"center\">\n    <Progress type=\"circle\" percent={68} />\n    <Progress type=\"circle\" percent={100} status=\"success\" />\n    <Progress type=\"circle\" percent={68} status=\"exception\" />\n    <Progress type=\"circle\" percent={68} size=\"small\" />\n    <Progress type=\"circle\" percent={100} status=\"success\" size=\"small\" />\n    <Progress type=\"circle\" percent={68} status=\"exception\" size=\"small\" />\n  </Flex>\n);\nexport default Demo;\n"
  }
}} /><DumiDemo {...{
  "demo": {
    "id": "components-progress-index-tab-design-demo-content"
  },
  "previewerProps": {
    "description": "<p>适用于内容级场景的微型进度条，常与文本搭配使用</p>",
    "title": "内容级进度条",
    "filename": "components/progress/design/demo/content.tsx",
    "pkgDependencyList": {
      "@ant-design/compatible": "^5.1.3",
      "@ant-design/happy-work-theme": "^1.0.0",
      "@ant-design/tools": "^18.0.2",
      "@antv/g6": "^4.8.24",
      "@babel/eslint-plugin": "^7.24.7",
      "@biomejs/biome": "^1.8.3",
      "@codesandbox/sandpack-react": "^2.18.0",
      "@dnd-kit/core": "^6.1.0",
      "@dnd-kit/modifiers": "^7.0.0",
      "@dnd-kit/sortable": "^8.0.0",
      "@dnd-kit/utilities": "^3.2.2",
      "@emotion/react": "^11.11.4",
      "@emotion/css": "^11.11.2",
      "@emotion/server": "^11.11.0",
      "@ianvs/prettier-plugin-sort-imports": "^4.3.1",
      "@inquirer/prompts": "^5.1.2",
      "@madccc/duplicate-package-checker-webpack-plugin": "^1.0.0",
      "@microflash/rehype-figure": "^2.1.0",
      "@npmcli/run-script": "^8.1.0",
      "@octokit/rest": "^21.0.0",
      "@qixian.cs/github-contributors-list": "^2.0.2",
      "@size-limit/file": "^11.1.4",
      "@stackblitz/sdk": "^1.11.0",
      "@testing-library/dom": "^10.3.2",
      "@testing-library/jest-dom": "^6.4.6",
      "@testing-library/react": "^16.0.0",
      "@testing-library/user-event": "^14.5.2",
      "@types/adm-zip": "^0.5.5",
      "@types/ali-oss": "^6.16.11",
      "@types/cli-progress": "^3.11.6",
      "@types/fs-extra": "^11.0.4",
      "@types/gtag.js": "^0.0.20",
      "@types/http-server": "^0.12.4",
      "@types/isomorphic-fetch": "^0.0.39",
      "@types/jest": "^29.5.12",
      "@types/jest-axe": "^3.5.9",
      "@types/jest-environment-puppeteer": "^5.0.6",
      "@types/jest-image-snapshot": "^6.4.0",
      "@types/jquery": "^3.5.30",
      "@types/jsdom": "^21.1.7",
      "@types/lodash": "^4.17.6",
      "@types/minimist": "^1.2.5",
      "@types/node": "^20.14.10",
      "@types/nprogress": "^0.2.3",
      "@types/pixelmatch": "^5.2.6",
      "@types/pngjs": "^6.0.5",
      "@types/prismjs": "^1.26.4",
      "@types/progress": "^2.0.7",
      "@types/qs": "^6.9.15",
      "@types/react": "^18.3.3",
      "@types/react-copy-to-clipboard": "^5.0.7",
      "@types/react-dom": "^18.3.0",
      "@types/react-highlight-words": "^0.20.0",
      "@types/react-resizable": "^3.0.8",
      "@types/semver": "^7.5.8",
      "@types/spinnies": "^0.5.3",
      "@types/tar": "^6.1.13",
      "@types/throttle-debounce": "^5.0.2",
      "@types/warning": "^3.0.3",
      "@typescript-eslint/eslint-plugin": "^7.16.1",
      "@typescript-eslint/parser": "^7.16.1",
      "adm-zip": "^0.5.14",
      "ali-oss": "^6.20.0",
      "antd-img-crop": "^4.22.0",
      "antd-style": "^3.6.2",
      "antd-token-previewer": "^2.0.8",
      "axios": "^1.7.2",
      "chalk": "^4.1.2",
      "cheerio": "1.0.0-rc.12",
      "circular-dependency-plugin": "^5.2.2",
      "cli-progress": "^3.12.0",
      "cross-env": "^7.0.3",
      "cross-fetch": "^4.0.0",
      "dekko": "^0.2.1",
      "dotenv": "^16.4.5",
      "dumi": "~2.4.5",
      "dumi-plugin-color-chunk": "^1.1.1",
      "esbuild-loader": "^4.2.1",
      "eslint": "^8.57.0",
      "eslint-config-airbnb": "^19.0.4",
      "eslint-config-prettier": "^9.1.0",
      "eslint-import-resolver-typescript": "^3.6.1",
      "eslint-plugin-compat": "^6.0.0",
      "eslint-plugin-import": "^2.29.1",
      "eslint-plugin-jest": "^28.6.0",
      "eslint-plugin-jsx-a11y": "^6.8.0",
      "eslint-plugin-lodash": "^7.4.0",
      "eslint-plugin-markdown": "^5.1.0",
      "eslint-plugin-react": "^7.34.4",
      "eslint-plugin-react-hooks": "^4.6.2",
      "eslint-plugin-unicorn": "^55.0.0",
      "fast-glob": "^3.3.2",
      "fetch-jsonp": "^1.3.0",
      "fs-extra": "^11.2.0",
      "gh-pages": "^6.1.1",
      "glob": "^11.0.0",
      "html2sketch": "^1.0.2",
      "http-server": "^14.1.1",
      "husky": "^9.1.2",
      "identity-obj-proxy": "^3.0.0",
      "immer": "^10.1.1",
      "is-ci": "^3.0.1",
      "isomorphic-fetch": "^3.0.0",
      "jest": "^29.7.0",
      "jest-axe": "^9.0.0",
      "jest-canvas-mock": "^2.5.2",
      "jest-environment-jsdom": "^29.7.0",
      "jest-environment-node": "^29.7.0",
      "jest-image-snapshot": "^6.4.0",
      "jest-puppeteer": "^10.0.1",
      "jquery": "^3.7.1",
      "jsdom": "^24.1.0",
      "jsonml-to-react-element": "^1.1.11",
      "jsonml.js": "^0.1.0",
      "lint-staged": "^15.2.7",
      "lodash": "^4.17.21",
      "lunar-typescript": "^1.7.5",
      "lz-string": "^1.5.0",
      "minimist": "^1.2.8",
      "mockdate": "^3.0.5",
      "node-fetch": "^3.3.2",
      "node-notifier": "^10.0.1",
      "nprogress": "^0.2.0",
      "open": "^10.1.0",
      "ora": "^8.0.1",
      "pixelmatch": "^6.0.0",
      "pngjs": "^7.0.0",
      "prettier": "^3.3.3",
      "prettier-plugin-jsdoc": "^1.3.0",
      "pretty-format": "^29.7.0",
      "prismjs": "^1.29.0",
      "puppeteer": "^22.13.0",
      "qs": "^6.12.3",
      "rc-footer": "^0.6.8",
      "rc-tween-one": "^3.0.6",
      "rc-virtual-list": "^3.14.5",
      "react": "^18.3.1",
      "react-copy-to-clipboard": "^5.1.0",
      "react-countup": "^6.5.3",
      "react-dom": "^18.3.1",
      "react-draggable": "^4.4.6",
      "react-fast-marquee": "^1.6.5",
      "react-highlight-words": "^0.20.0",
      "react-infinite-scroll-component": "^6.1.0",
      "react-intersection-observer": "^9.13.0",
      "react-resizable": "^3.0.5",
      "react-router-dom": "^6.24.1",
      "react-sticky-box": "^2.0.5",
      "regenerator-runtime": "^0.14.1",
      "rehype-stringify": "^10.0.0",
      "remark": "^15.0.1",
      "remark-cli": "^12.0.1",
      "remark-gfm": "^4.0.0",
      "remark-lint": "^10.0.0",
      "remark-lint-no-undefined-references": "^5.0.0",
      "remark-preset-lint-recommended": "^7.0.0",
      "remark-rehype": "^11.1.0",
      "runes2": "^1.1.4",
      "semver": "^7.6.2",
      "sharp": "^0.33.4",
      "simple-git": "^3.25.0",
      "size-limit": "^11.1.4",
      "spinnies": "^0.5.1",
      "sylvanas": "^0.6.1",
      "tar": "^7.4.0",
      "tar-fs": "^3.0.6",
      "terser": "^5.31.2",
      "tsx": "4.11.2",
      "typedoc": "^0.26.4",
      "typescript": "~5.5.3",
      "vanilla-jsoneditor": "^0.23.7",
      "vanilla-tilt": "^1.8.1",
      "webpack": "^5.93.0",
      "webpack-bundle-analyzer": "^4.10.2",
      "xhr-mock": "^2.5.1",
      "@ant-design/colors": "^7.1.0",
      "@ant-design/cssinjs": "^1.21.0",
      "@ant-design/cssinjs-utils": "^1.0.3",
      "@ant-design/icons": "^5.4.0",
      "@ant-design/react-slick": "~1.1.2",
      "@babel/runtime": "^7.24.8",
      "@ctrl/tinycolor": "^3.6.1",
      "@rc-component/color-picker": "~1.5.3",
      "@rc-component/mutate-observer": "^1.1.0",
      "@rc-component/qrcode": "~1.0.0",
      "@rc-component/tour": "~1.15.0",
      "@rc-component/trigger": "^2.2.0",
      "classnames": "^2.5.1",
      "copy-to-clipboard": "^3.3.3",
      "dayjs": "^1.11.11",
      "rc-cascader": "~3.27.0",
      "rc-checkbox": "~3.3.0",
      "rc-collapse": "~3.7.3",
      "rc-dialog": "~9.5.2",
      "rc-drawer": "~7.2.0",
      "rc-dropdown": "~4.2.0",
      "rc-field-form": "~2.2.1",
      "rc-image": "~7.9.0",
      "rc-input": "~1.5.1",
      "rc-input-number": "~9.1.0",
      "rc-mentions": "~2.14.0",
      "rc-menu": "~9.14.1",
      "rc-motion": "^2.9.2",
      "rc-notification": "~5.6.0",
      "rc-pagination": "~4.2.0",
      "rc-picker": "~4.6.9",
      "rc-progress": "~4.0.0",
      "rc-rate": "~2.13.0",
      "rc-resize-observer": "^1.4.0",
      "rc-segmented": "~2.3.0",
      "rc-select": "~14.15.1",
      "rc-slider": "~10.6.2",
      "rc-steps": "~6.0.1",
      "rc-switch": "~4.1.0",
      "rc-table": "~7.45.7",
      "rc-tabs": "~15.1.1",
      "rc-textarea": "~1.7.0",
      "rc-tooltip": "~6.2.0",
      "rc-tree": "~5.8.8",
      "rc-tree-select": "~5.22.1",
      "rc-upload": "~4.6.0",
      "rc-util": "^5.43.0",
      "scroll-into-view-if-needed": "^3.1.0",
      "throttle-debounce": "^5.0.2"
    },
    "jsx": "import React from 'react';\nimport { Flex, Progress, theme } from 'antd';\nconst Demo = () => {\n  const { token } = theme.useToken();\n  return (\n    <Flex gap=\"large\">\n      <Flex gap=\"small\" align=\"center\">\n        <Progress size={16} type=\"circle\" percent={68} trailColor={token.colorPrimaryBg} />\n        <div>进行中</div>\n      </Flex>\n      <Flex gap=\"small\" align=\"center\">\n        <Progress size={16} type=\"circle\" percent={100} status=\"success\" />\n        <div>已完成</div>\n      </Flex>\n      <Flex gap=\"small\" align=\"center\">\n        <Progress\n          size={16}\n          type=\"circle\"\n          percent={68}\n          status=\"exception\"\n          trailColor={token.colorErrorBg}\n        />\n        <div>错误/异常</div>\n      </Flex>\n    </Flex>\n  );\n};\nexport default Demo;\n"
  }
}} /></>;
}

// export named function for fastRefresh
// ref: https://github.com/pmmmwh/react-refresh-webpack-plugin/blob/main/docs/TROUBLESHOOTING.md#edits-always-lead-to-full-reload
function DumiMarkdownContent() {
  // wrap suspense for catch async meta data
  return <><Suspense fallback={<LoadingComponent />}><DumiMarkdownInner /></Suspense></>;
}

export default DumiMarkdownContent;