// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import { frontmatter as fm0, toc as t0 } from '/opt/buildhome/repo/.dumi/pages/theme-editor-cn/index.ts?type=frontmatter';
import { frontmatter as fm1, toc as t1 } from '/opt/buildhome/repo/.dumi/pages/theme-editor/index.tsx?type=frontmatter';
import { frontmatter as fm2, toc as t2 } from '/opt/buildhome/repo/.dumi/pages/index-cn/index.ts?type=frontmatter';
import { frontmatter as fm3, toc as t3 } from '/opt/buildhome/repo/.dumi/pages/index/index.tsx?type=frontmatter';
import { frontmatter as fm4, toc as t4 } from '/opt/buildhome/repo/docs/blog/contributor-development-maintenance-guide.en-US.md?type=frontmatter';
import { demoIndex as dmi4 } from '/opt/buildhome/repo/docs/blog/contributor-development-maintenance-guide.en-US.md?type=demo-index';
import { frontmatter as fm5, toc as t5 } from '/opt/buildhome/repo/docs/blog/contributor-development-maintenance-guide.zh-CN.md?type=frontmatter';
import { demoIndex as dmi5 } from '/opt/buildhome/repo/docs/blog/contributor-development-maintenance-guide.zh-CN.md?type=demo-index';
import { frontmatter as fm6, toc as t6 } from '/opt/buildhome/repo/docs/spec/research-message-and-feedback.en-US.md?type=frontmatter';
import { demoIndex as dmi6 } from '/opt/buildhome/repo/docs/spec/research-message-and-feedback.en-US.md?type=demo-index';
import { frontmatter as fm7, toc as t7 } from '/opt/buildhome/repo/docs/spec/research-message-and-feedback.zh-CN.md?type=frontmatter';
import { demoIndex as dmi7 } from '/opt/buildhome/repo/docs/spec/research-message-and-feedback.zh-CN.md?type=demo-index';
import { frontmatter as fm8, toc as t8 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=frontmatter';
import { demoIndex as dmi8 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=demo-index';
import { frontmatter as fm9, toc as t9 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=frontmatter';
import { demoIndex as dmi9 } from '/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=demo-index';
import { frontmatter as fm10, toc as t10 } from '/opt/buildhome/repo/docs/react/use-custom-date-library.en-US.md?type=frontmatter';
import { demoIndex as dmi10 } from '/opt/buildhome/repo/docs/react/use-custom-date-library.en-US.md?type=demo-index';
import { frontmatter as fm11, toc as t11 } from '/opt/buildhome/repo/docs/react/use-custom-date-library.zh-CN.md?type=frontmatter';
import { demoIndex as dmi11 } from '/opt/buildhome/repo/docs/react/use-custom-date-library.zh-CN.md?type=demo-index';
import { frontmatter as fm12, toc as t12 } from '/opt/buildhome/repo/docs/blog/github-actions-workflow.en-US.md?type=frontmatter';
import { demoIndex as dmi12 } from '/opt/buildhome/repo/docs/blog/github-actions-workflow.en-US.md?type=demo-index';
import { frontmatter as fm13, toc as t13 } from '/opt/buildhome/repo/docs/blog/github-actions-workflow.zh-CN.md?type=frontmatter';
import { demoIndex as dmi13 } from '/opt/buildhome/repo/docs/blog/github-actions-workflow.zh-CN.md?type=demo-index';
import { frontmatter as fm14, toc as t14 } from '/opt/buildhome/repo/docs/react/migrate-less-variables.en-US.md?type=frontmatter';
import { demoIndex as dmi14 } from '/opt/buildhome/repo/docs/react/migrate-less-variables.en-US.md?type=demo-index';
import { frontmatter as fm15, toc as t15 } from '/opt/buildhome/repo/docs/react/migrate-less-variables.zh-CN.md?type=frontmatter';
import { demoIndex as dmi15 } from '/opt/buildhome/repo/docs/react/migrate-less-variables.zh-CN.md?type=demo-index';
import { frontmatter as fm16, toc as t16 } from '/opt/buildhome/repo/docs/react/server-side-rendering.en-US.md?type=frontmatter';
import { demoIndex as dmi16 } from '/opt/buildhome/repo/docs/react/server-side-rendering.en-US.md?type=demo-index';
import { frontmatter as fm17, toc as t17 } from '/opt/buildhome/repo/docs/react/server-side-rendering.zh-CN.md?type=frontmatter';
import { demoIndex as dmi17 } from '/opt/buildhome/repo/docs/react/server-side-rendering.zh-CN.md?type=demo-index';
import { frontmatter as fm18, toc as t18 } from '/opt/buildhome/repo/docs/blog/config-provider-style.en-US.md?type=frontmatter';
import { demoIndex as dmi18 } from '/opt/buildhome/repo/docs/blog/config-provider-style.en-US.md?type=demo-index';
import { frontmatter as fm19, toc as t19 } from '/opt/buildhome/repo/docs/blog/config-provider-style.zh-CN.md?type=frontmatter';
import { demoIndex as dmi19 } from '/opt/buildhome/repo/docs/blog/config-provider-style.zh-CN.md?type=demo-index';
import { frontmatter as fm20, toc as t20 } from '/opt/buildhome/repo/docs/spec/research-navigation.en-US.md?type=frontmatter';
import { demoIndex as dmi20 } from '/opt/buildhome/repo/docs/spec/research-navigation.en-US.md?type=demo-index';
import { frontmatter as fm21, toc as t21 } from '/opt/buildhome/repo/docs/spec/research-navigation.zh-CN.md?type=frontmatter';
import { demoIndex as dmi21 } from '/opt/buildhome/repo/docs/spec/research-navigation.zh-CN.md?type=demo-index';
import { frontmatter as fm22, toc as t22 } from '/opt/buildhome/repo/docs/blog/mock-project-build.en-US.md?type=frontmatter';
import { demoIndex as dmi22 } from '/opt/buildhome/repo/docs/blog/mock-project-build.en-US.md?type=demo-index';
import { frontmatter as fm23, toc as t23 } from '/opt/buildhome/repo/docs/blog/mock-project-build.zh-CN.md?type=frontmatter';
import { demoIndex as dmi23 } from '/opt/buildhome/repo/docs/blog/mock-project-build.zh-CN.md?type=demo-index';
import { frontmatter as fm24, toc as t24 } from '/opt/buildhome/repo/docs/blog/notification-stack.en-US.md?type=frontmatter';
import { demoIndex as dmi24 } from '/opt/buildhome/repo/docs/blog/notification-stack.en-US.md?type=demo-index';
import { frontmatter as fm25, toc as t25 } from '/opt/buildhome/repo/docs/blog/notification-stack.zh-CN.md?type=frontmatter';
import { demoIndex as dmi25 } from '/opt/buildhome/repo/docs/blog/notification-stack.zh-CN.md?type=demo-index';
import { frontmatter as fm26, toc as t26 } from '/opt/buildhome/repo/docs/blog/to-be-collaborator.en-US.md?type=frontmatter';
import { demoIndex as dmi26 } from '/opt/buildhome/repo/docs/blog/to-be-collaborator.en-US.md?type=demo-index';
import { frontmatter as fm27, toc as t27 } from '/opt/buildhome/repo/docs/blog/to-be-collaborator.zh-CN.md?type=frontmatter';
import { demoIndex as dmi27 } from '/opt/buildhome/repo/docs/blog/to-be-collaborator.zh-CN.md?type=demo-index';
import { frontmatter as fm28, toc as t28 } from '/opt/buildhome/repo/docs/blog/to-be-what-you-see.en-US.md?type=frontmatter';
import { demoIndex as dmi28 } from '/opt/buildhome/repo/docs/blog/to-be-what-you-see.en-US.md?type=demo-index';
import { frontmatter as fm29, toc as t29 } from '/opt/buildhome/repo/docs/blog/to-be-what-you-see.zh-CN.md?type=frontmatter';
import { demoIndex as dmi29 } from '/opt/buildhome/repo/docs/blog/to-be-what-you-see.zh-CN.md?type=demo-index';
import { frontmatter as fm30, toc as t30 } from '/opt/buildhome/repo/docs/spec/research-exception.en-US.md?type=frontmatter';
import { demoIndex as dmi30 } from '/opt/buildhome/repo/docs/spec/research-exception.en-US.md?type=demo-index';
import { frontmatter as fm31, toc as t31 } from '/opt/buildhome/repo/docs/spec/research-exception.zh-CN.md?type=frontmatter';
import { demoIndex as dmi31 } from '/opt/buildhome/repo/docs/spec/research-exception.zh-CN.md?type=demo-index';
import { frontmatter as fm32, toc as t32 } from '/opt/buildhome/repo/docs/spec/research-workbench.en-US.md?type=frontmatter';
import { demoIndex as dmi32 } from '/opt/buildhome/repo/docs/spec/research-workbench.en-US.md?type=demo-index';
import { frontmatter as fm33, toc as t33 } from '/opt/buildhome/repo/docs/spec/research-workbench.zh-CN.md?type=frontmatter';
import { demoIndex as dmi33 } from '/opt/buildhome/repo/docs/spec/research-workbench.zh-CN.md?type=demo-index';
import { frontmatter as fm34, toc as t34 } from '/opt/buildhome/repo/docs/spec/visualization-page.en-US.md?type=frontmatter';
import { demoIndex as dmi34 } from '/opt/buildhome/repo/docs/spec/visualization-page.en-US.md?type=demo-index';
import { frontmatter as fm35, toc as t35 } from '/opt/buildhome/repo/docs/spec/visualization-page.zh-CN.md?type=frontmatter';
import { demoIndex as dmi35 } from '/opt/buildhome/repo/docs/spec/visualization-page.zh-CN.md?type=demo-index';
import { frontmatter as fm36, toc as t36 } from '/opt/buildhome/repo/docs/react/compatible-style.en-US.md?type=frontmatter';
import { demoIndex as dmi36 } from '/opt/buildhome/repo/docs/react/compatible-style.en-US.md?type=demo-index';
import { frontmatter as fm37, toc as t37 } from '/opt/buildhome/repo/docs/react/compatible-style.zh-CN.md?type=frontmatter';
import { demoIndex as dmi37 } from '/opt/buildhome/repo/docs/react/compatible-style.zh-CN.md?type=demo-index';
import { frontmatter as fm38, toc as t38 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=frontmatter';
import { demoIndex as dmi38 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=demo-index';
import { frontmatter as fm39, toc as t39 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=frontmatter';
import { demoIndex as dmi39 } from '/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=demo-index';
import { frontmatter as fm40, toc as t40 } from '/opt/buildhome/repo/docs/spec/research-overview.en-US.md?type=frontmatter';
import { demoIndex as dmi40 } from '/opt/buildhome/repo/docs/spec/research-overview.en-US.md?type=demo-index';
import { frontmatter as fm41, toc as t41 } from '/opt/buildhome/repo/docs/spec/research-overview.zh-CN.md?type=frontmatter';
import { demoIndex as dmi41 } from '/opt/buildhome/repo/docs/spec/research-overview.zh-CN.md?type=demo-index';
import { frontmatter as fm42, toc as t42 } from '/opt/buildhome/repo/docs/blog/modal-hook-order.en-US.md?type=frontmatter';
import { demoIndex as dmi42 } from '/opt/buildhome/repo/docs/blog/modal-hook-order.en-US.md?type=demo-index';
import { frontmatter as fm43, toc as t43 } from '/opt/buildhome/repo/docs/blog/modal-hook-order.zh-CN.md?type=frontmatter';
import { demoIndex as dmi43 } from '/opt/buildhome/repo/docs/blog/modal-hook-order.zh-CN.md?type=demo-index';
import { frontmatter as fm44, toc as t44 } from '/opt/buildhome/repo/docs/react/customize-theme.en-US.md?type=frontmatter';
import { demoIndex as dmi44 } from '/opt/buildhome/repo/docs/react/customize-theme.en-US.md?type=demo-index';
import { frontmatter as fm45, toc as t45 } from '/opt/buildhome/repo/docs/react/customize-theme.zh-CN.md?type=frontmatter';
import { demoIndex as dmi45 } from '/opt/buildhome/repo/docs/react/customize-theme.zh-CN.md?type=demo-index';
import { frontmatter as fm46, toc as t46 } from '/opt/buildhome/repo/docs/react/getting-started.en-US.md?type=frontmatter';
import { demoIndex as dmi46 } from '/opt/buildhome/repo/docs/react/getting-started.en-US.md?type=demo-index';
import { frontmatter as fm47, toc as t47 } from '/opt/buildhome/repo/docs/react/getting-started.zh-CN.md?type=frontmatter';
import { demoIndex as dmi47 } from '/opt/buildhome/repo/docs/react/getting-started.zh-CN.md?type=demo-index';
import { frontmatter as fm48, toc as t48 } from '/opt/buildhome/repo/docs/blog/historical-debt.en-US.md?type=frontmatter';
import { demoIndex as dmi48 } from '/opt/buildhome/repo/docs/blog/historical-debt.en-US.md?type=demo-index';
import { frontmatter as fm49, toc as t49 } from '/opt/buildhome/repo/docs/blog/historical-debt.zh-CN.md?type=frontmatter';
import { demoIndex as dmi49 } from '/opt/buildhome/repo/docs/blog/historical-debt.zh-CN.md?type=demo-index';
import { frontmatter as fm50, toc as t50 } from '/opt/buildhome/repo/docs/blog/hydrate-cssinjs.en-US.md?type=frontmatter';
import { demoIndex as dmi50 } from '/opt/buildhome/repo/docs/blog/hydrate-cssinjs.en-US.md?type=demo-index';
import { frontmatter as fm51, toc as t51 } from '/opt/buildhome/repo/docs/blog/hydrate-cssinjs.zh-CN.md?type=frontmatter';
import { demoIndex as dmi51 } from '/opt/buildhome/repo/docs/blog/hydrate-cssinjs.zh-CN.md?type=demo-index';
import { frontmatter as fm52, toc as t52 } from '/opt/buildhome/repo/docs/blog/testing-migrate.en-US.md?type=frontmatter';
import { demoIndex as dmi52 } from '/opt/buildhome/repo/docs/blog/testing-migrate.en-US.md?type=demo-index';
import { frontmatter as fm53, toc as t53 } from '/opt/buildhome/repo/docs/blog/testing-migrate.zh-CN.md?type=frontmatter';
import { demoIndex as dmi53 } from '/opt/buildhome/repo/docs/blog/testing-migrate.zh-CN.md?type=demo-index';
import { frontmatter as fm54, toc as t54 } from '/opt/buildhome/repo/docs/react/recommendation.en-US.md?type=frontmatter';
import { demoIndex as dmi54 } from '/opt/buildhome/repo/docs/react/recommendation.en-US.md?type=demo-index';
import { frontmatter as fm55, toc as t55 } from '/opt/buildhome/repo/docs/react/recommendation.zh-CN.md?type=frontmatter';
import { demoIndex as dmi55 } from '/opt/buildhome/repo/docs/react/recommendation.zh-CN.md?type=demo-index';
import { frontmatter as fm56, toc as t56 } from '/opt/buildhome/repo/docs/spec/research-result.en-US.md?type=frontmatter';
import { demoIndex as dmi56 } from '/opt/buildhome/repo/docs/spec/research-result.en-US.md?type=demo-index';
import { frontmatter as fm57, toc as t57 } from '/opt/buildhome/repo/docs/spec/research-result.zh-CN.md?type=frontmatter';
import { demoIndex as dmi57 } from '/opt/buildhome/repo/docs/spec/research-result.zh-CN.md?type=demo-index';
import { frontmatter as fm58, toc as t58 } from '/opt/buildhome/repo/docs/blog/why-not-static.en-US.md?type=frontmatter';
import { demoIndex as dmi58 } from '/opt/buildhome/repo/docs/blog/why-not-static.en-US.md?type=demo-index';
import { frontmatter as fm59, toc as t59 } from '/opt/buildhome/repo/docs/blog/why-not-static.zh-CN.md?type=frontmatter';
import { demoIndex as dmi59 } from '/opt/buildhome/repo/docs/blog/why-not-static.zh-CN.md?type=demo-index';
import { frontmatter as fm60, toc as t60 } from '/opt/buildhome/repo/docs/react/css-variables.en-US.md?type=frontmatter';
import { demoIndex as dmi60 } from '/opt/buildhome/repo/docs/react/css-variables.en-US.md?type=demo-index';
import { frontmatter as fm61, toc as t61 } from '/opt/buildhome/repo/docs/react/css-variables.zh-CN.md?type=frontmatter';
import { demoIndex as dmi61 } from '/opt/buildhome/repo/docs/react/css-variables.zh-CN.md?type=demo-index';
import { frontmatter as fm62, toc as t62 } from '/opt/buildhome/repo/docs/react/use-with-farm.en-US.md?type=frontmatter';
import { demoIndex as dmi62 } from '/opt/buildhome/repo/docs/react/use-with-farm.en-US.md?type=demo-index';
import { frontmatter as fm63, toc as t63 } from '/opt/buildhome/repo/docs/react/use-with-farm.zh-CN.md?type=frontmatter';
import { demoIndex as dmi63 } from '/opt/buildhome/repo/docs/react/use-with-farm.zh-CN.md?type=demo-index';
import { frontmatter as fm64, toc as t64 } from '/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=frontmatter';
import { demoIndex as dmi64 } from '/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=demo-index';
import { frontmatter as fm65, toc as t65 } from '/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=frontmatter';
import { demoIndex as dmi65 } from '/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=demo-index';
import { frontmatter as fm66, toc as t66 } from '/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=frontmatter';
import { demoIndex as dmi66 } from '/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=demo-index';
import { frontmatter as fm67, toc as t67 } from '/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=frontmatter';
import { demoIndex as dmi67 } from '/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=demo-index';
import { frontmatter as fm68, toc as t68 } from '/opt/buildhome/repo/docs/spec/research-empty.en-US.md?type=frontmatter';
import { demoIndex as dmi68 } from '/opt/buildhome/repo/docs/spec/research-empty.en-US.md?type=demo-index';
import { frontmatter as fm69, toc as t69 } from '/opt/buildhome/repo/docs/spec/research-empty.zh-CN.md?type=frontmatter';
import { demoIndex as dmi69 } from '/opt/buildhome/repo/docs/spec/research-empty.zh-CN.md?type=demo-index';
import { frontmatter as fm70, toc as t70 } from '/opt/buildhome/repo/docs/blog/check-conduct.en-US.md?type=frontmatter';
import { demoIndex as dmi70 } from '/opt/buildhome/repo/docs/blog/check-conduct.en-US.md?type=demo-index';
import { frontmatter as fm71, toc as t71 } from '/opt/buildhome/repo/docs/blog/check-conduct.zh-CN.md?type=frontmatter';
import { demoIndex as dmi71 } from '/opt/buildhome/repo/docs/blog/check-conduct.zh-CN.md?type=demo-index';
import { frontmatter as fm72, toc as t72 } from '/opt/buildhome/repo/docs/blog/line-ellipsis.en-US.md?type=frontmatter';
import { demoIndex as dmi72 } from '/opt/buildhome/repo/docs/blog/line-ellipsis.en-US.md?type=demo-index';
import { frontmatter as fm73, toc as t73 } from '/opt/buildhome/repo/docs/blog/line-ellipsis.zh-CN.md?type=frontmatter';
import { demoIndex as dmi73 } from '/opt/buildhome/repo/docs/blog/line-ellipsis.zh-CN.md?type=demo-index';
import { frontmatter as fm74, toc as t74 } from '/opt/buildhome/repo/docs/blog/tooltip-align.en-US.md?type=frontmatter';
import { demoIndex as dmi74 } from '/opt/buildhome/repo/docs/blog/tooltip-align.en-US.md?type=demo-index';
import { frontmatter as fm75, toc as t75 } from '/opt/buildhome/repo/docs/blog/tooltip-align.zh-CN.md?type=frontmatter';
import { demoIndex as dmi75 } from '/opt/buildhome/repo/docs/blog/tooltip-align.zh-CN.md?type=demo-index';
import { frontmatter as fm76, toc as t76 } from '/opt/buildhome/repo/docs/blog/virtual-table.en-US.md?type=frontmatter';
import { demoIndex as dmi76 } from '/opt/buildhome/repo/docs/blog/virtual-table.en-US.md?type=demo-index';
import { frontmatter as fm77, toc as t77 } from '/opt/buildhome/repo/docs/blog/virtual-table.zh-CN.md?type=frontmatter';
import { demoIndex as dmi77 } from '/opt/buildhome/repo/docs/blog/virtual-table.zh-CN.md?type=demo-index';
import { frontmatter as fm78, toc as t78 } from '/opt/buildhome/repo/docs/react/common-props.en-US.md?type=frontmatter';
import { demoIndex as dmi78 } from '/opt/buildhome/repo/docs/react/common-props.en-US.md?type=demo-index';
import { frontmatter as fm79, toc as t79 } from '/opt/buildhome/repo/docs/react/common-props.zh-CN.md?type=frontmatter';
import { demoIndex as dmi79 } from '/opt/buildhome/repo/docs/react/common-props.zh-CN.md?type=demo-index';
import { frontmatter as fm80, toc as t80 } from '/opt/buildhome/repo/docs/react/contributing.en-US.md?type=frontmatter';
import { demoIndex as dmi80 } from '/opt/buildhome/repo/docs/react/contributing.en-US.md?type=demo-index';
import { frontmatter as fm81, toc as t81 } from '/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=frontmatter';
import { demoIndex as dmi81 } from '/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=demo-index';
import { frontmatter as fm82, toc as t82 } from '/opt/buildhome/repo/docs/react/migration-v5.en-US.md?type=frontmatter';
import { demoIndex as dmi82 } from '/opt/buildhome/repo/docs/react/migration-v5.en-US.md?type=demo-index';
import { frontmatter as fm83, toc as t83 } from '/opt/buildhome/repo/docs/react/migration-v5.zh-CN.md?type=frontmatter';
import { demoIndex as dmi83 } from '/opt/buildhome/repo/docs/react/migration-v5.zh-CN.md?type=demo-index';
import { frontmatter as fm84, toc as t84 } from '/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=frontmatter';
import { demoIndex as dmi84 } from '/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=demo-index';
import { frontmatter as fm85, toc as t85 } from '/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=frontmatter';
import { demoIndex as dmi85 } from '/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=demo-index';
import { frontmatter as fm86, toc as t86 } from '/opt/buildhome/repo/docs/spec/research-form.en-US.md?type=frontmatter';
import { demoIndex as dmi86 } from '/opt/buildhome/repo/docs/spec/research-form.en-US.md?type=demo-index';
import { frontmatter as fm87, toc as t87 } from '/opt/buildhome/repo/docs/spec/research-form.zh-CN.md?type=frontmatter';
import { demoIndex as dmi87 } from '/opt/buildhome/repo/docs/spec/research-form.zh-CN.md?type=demo-index';
import { frontmatter as fm88, toc as t88 } from '/opt/buildhome/repo/docs/spec/research-list.en-US.md?type=frontmatter';
import { demoIndex as dmi88 } from '/opt/buildhome/repo/docs/spec/research-list.en-US.md?type=demo-index';
import { frontmatter as fm89, toc as t89 } from '/opt/buildhome/repo/docs/spec/research-list.zh-CN.md?type=frontmatter';
import { demoIndex as dmi89 } from '/opt/buildhome/repo/docs/spec/research-list.zh-CN.md?type=demo-index';
import { frontmatter as fm90, toc as t90 } from '/opt/buildhome/repo/docs/blog/color-picker.en-US.md?type=frontmatter';
import { demoIndex as dmi90 } from '/opt/buildhome/repo/docs/blog/color-picker.en-US.md?type=demo-index';
import { frontmatter as fm91, toc as t91 } from '/opt/buildhome/repo/docs/blog/color-picker.zh-CN.md?type=frontmatter';
import { demoIndex as dmi91 } from '/opt/buildhome/repo/docs/blog/color-picker.zh-CN.md?type=demo-index';
import { frontmatter as fm92, toc as t92 } from '/opt/buildhome/repo/docs/blog/css-var-plan.en-US.md?type=frontmatter';
import { demoIndex as dmi92 } from '/opt/buildhome/repo/docs/blog/css-var-plan.en-US.md?type=demo-index';
import { frontmatter as fm93, toc as t93 } from '/opt/buildhome/repo/docs/blog/css-var-plan.zh-CN.md?type=frontmatter';
import { demoIndex as dmi93 } from '/opt/buildhome/repo/docs/blog/css-var-plan.zh-CN.md?type=demo-index';
import { frontmatter as fm94, toc as t94 } from '/opt/buildhome/repo/docs/blog/getContainer.en-US.md?type=frontmatter';
import { demoIndex as dmi94 } from '/opt/buildhome/repo/docs/blog/getContainer.en-US.md?type=demo-index';
import { frontmatter as fm95, toc as t95 } from '/opt/buildhome/repo/docs/blog/getContainer.zh-CN.md?type=frontmatter';
import { demoIndex as dmi95 } from '/opt/buildhome/repo/docs/blog/getContainer.zh-CN.md?type=demo-index';
import { frontmatter as fm96, toc as t96 } from '/opt/buildhome/repo/docs/blog/issue-helper.en-US.md?type=frontmatter';
import { demoIndex as dmi96 } from '/opt/buildhome/repo/docs/blog/issue-helper.en-US.md?type=demo-index';
import { frontmatter as fm97, toc as t97 } from '/opt/buildhome/repo/docs/blog/issue-helper.zh-CN.md?type=frontmatter';
import { demoIndex as dmi97 } from '/opt/buildhome/repo/docs/blog/issue-helper.zh-CN.md?type=demo-index';
import { frontmatter as fm98, toc as t98 } from '/opt/buildhome/repo/docs/blog/render-times.en-US.md?type=frontmatter';
import { demoIndex as dmi98 } from '/opt/buildhome/repo/docs/blog/render-times.en-US.md?type=demo-index';
import { frontmatter as fm99, toc as t99 } from '/opt/buildhome/repo/docs/blog/render-times.zh-CN.md?type=frontmatter';
import { demoIndex as dmi99 } from '/opt/buildhome/repo/docs/blog/render-times.zh-CN.md?type=demo-index';
import { frontmatter as fm100, toc as t100 } from '/opt/buildhome/repo/docs/blog/tree-shaking.en-US.md?type=frontmatter';
import { demoIndex as dmi100 } from '/opt/buildhome/repo/docs/blog/tree-shaking.en-US.md?type=demo-index';
import { frontmatter as fm101, toc as t101 } from '/opt/buildhome/repo/docs/blog/tree-shaking.zh-CN.md?type=frontmatter';
import { demoIndex as dmi101 } from '/opt/buildhome/repo/docs/blog/tree-shaking.zh-CN.md?type=demo-index';
import { frontmatter as fm102, toc as t102 } from '/opt/buildhome/repo/docs/spec/data-display.en-US.md?type=frontmatter';
import { demoIndex as dmi102 } from '/opt/buildhome/repo/docs/spec/data-display.en-US.md?type=demo-index';
import { frontmatter as fm103, toc as t103 } from '/opt/buildhome/repo/docs/spec/data-display.zh-CN.md?type=frontmatter';
import { demoIndex as dmi103 } from '/opt/buildhome/repo/docs/spec/data-display.zh-CN.md?type=demo-index';
import { frontmatter as fm104, toc as t104 } from '/opt/buildhome/repo/docs/spec/illustration.en-US.md?type=frontmatter';
import { demoIndex as dmi104 } from '/opt/buildhome/repo/docs/spec/illustration.en-US.md?type=demo-index';
import { frontmatter as fm105, toc as t105 } from '/opt/buildhome/repo/docs/spec/illustration.zh-CN.md?type=frontmatter';
import { demoIndex as dmi105 } from '/opt/buildhome/repo/docs/spec/illustration.zh-CN.md?type=demo-index';
import { frontmatter as fm106, toc as t106 } from '/opt/buildhome/repo/docs/blog/build-ghost.en-US.md?type=frontmatter';
import { demoIndex as dmi106 } from '/opt/buildhome/repo/docs/blog/build-ghost.en-US.md?type=demo-index';
import { frontmatter as fm107, toc as t107 } from '/opt/buildhome/repo/docs/blog/build-ghost.zh-CN.md?type=frontmatter';
import { demoIndex as dmi107 } from '/opt/buildhome/repo/docs/blog/build-ghost.zh-CN.md?type=demo-index';
import { frontmatter as fm108, toc as t108 } from '/opt/buildhome/repo/docs/blog/extract-ssr.en-US.md?type=frontmatter';
import { demoIndex as dmi108 } from '/opt/buildhome/repo/docs/blog/extract-ssr.en-US.md?type=demo-index';
import { frontmatter as fm109, toc as t109 } from '/opt/buildhome/repo/docs/blog/extract-ssr.zh-CN.md?type=frontmatter';
import { demoIndex as dmi109 } from '/opt/buildhome/repo/docs/blog/extract-ssr.zh-CN.md?type=demo-index';
import { frontmatter as fm110, toc as t110 } from '/opt/buildhome/repo/docs/spec/copywriting.en-US.md?type=frontmatter';
import { demoIndex as dmi110 } from '/opt/buildhome/repo/docs/spec/copywriting.en-US.md?type=demo-index';
import { frontmatter as fm111, toc as t111 } from '/opt/buildhome/repo/docs/spec/copywriting.zh-CN.md?type=frontmatter';
import { demoIndex as dmi111 } from '/opt/buildhome/repo/docs/spec/copywriting.zh-CN.md?type=demo-index';
import { frontmatter as fm112, toc as t112 } from '/opt/buildhome/repo/docs/spec/data-format.en-US.md?type=frontmatter';
import { demoIndex as dmi112 } from '/opt/buildhome/repo/docs/spec/data-format.en-US.md?type=demo-index';
import { frontmatter as fm113, toc as t113 } from '/opt/buildhome/repo/docs/spec/data-format.zh-CN.md?type=frontmatter';
import { demoIndex as dmi113 } from '/opt/buildhome/repo/docs/spec/data-format.zh-CN.md?type=demo-index';
import { frontmatter as fm114, toc as t114 } from '/opt/buildhome/repo/docs/spec/detail-page.en-US.md?type=frontmatter';
import { demoIndex as dmi114 } from '/opt/buildhome/repo/docs/spec/detail-page.en-US.md?type=demo-index';
import { frontmatter as fm115, toc as t115 } from '/opt/buildhome/repo/docs/spec/detail-page.zh-CN.md?type=frontmatter';
import { demoIndex as dmi115 } from '/opt/buildhome/repo/docs/spec/detail-page.zh-CN.md?type=demo-index';
import { frontmatter as fm116, toc as t116 } from '/opt/buildhome/repo/docs/spec/lightweight.en-US.md?type=frontmatter';
import { demoIndex as dmi116 } from '/opt/buildhome/repo/docs/spec/lightweight.en-US.md?type=demo-index';
import { frontmatter as fm117, toc as t117 } from '/opt/buildhome/repo/docs/spec/lightweight.zh-CN.md?type=frontmatter';
import { demoIndex as dmi117 } from '/opt/buildhome/repo/docs/spec/lightweight.zh-CN.md?type=demo-index';
import { frontmatter as fm118, toc as t118 } from '/opt/buildhome/repo/docs/blog/form-names.en-US.md?type=frontmatter';
import { demoIndex as dmi118 } from '/opt/buildhome/repo/docs/blog/form-names.en-US.md?type=demo-index';
import { frontmatter as fm119, toc as t119 } from '/opt/buildhome/repo/docs/blog/form-names.zh-CN.md?type=frontmatter';
import { demoIndex as dmi119 } from '/opt/buildhome/repo/docs/blog/form-names.zh-CN.md?type=demo-index';
import { frontmatter as fm120, toc as t120 } from '/opt/buildhome/repo/docs/blog/happy-work.en-US.md?type=frontmatter';
import { demoIndex as dmi120 } from '/opt/buildhome/repo/docs/blog/happy-work.en-US.md?type=demo-index';
import { frontmatter as fm121, toc as t121 } from '/opt/buildhome/repo/docs/blog/happy-work.zh-CN.md?type=frontmatter';
import { demoIndex as dmi121 } from '/opt/buildhome/repo/docs/blog/happy-work.zh-CN.md?type=demo-index';
import { frontmatter as fm122, toc as t122 } from '/opt/buildhome/repo/docs/react/introduce.en-US.md?type=frontmatter';
import { demoIndex as dmi122 } from '/opt/buildhome/repo/docs/react/introduce.en-US.md?type=demo-index';
import { frontmatter as fm123, toc as t123 } from '/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=frontmatter';
import { demoIndex as dmi123 } from '/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=demo-index';
import { frontmatter as fm124, toc as t124 } from '/opt/buildhome/repo/docs/spec/data-entry.en-US.md?type=frontmatter';
import { demoIndex as dmi124 } from '/opt/buildhome/repo/docs/spec/data-entry.en-US.md?type=demo-index';
import { frontmatter as fm125, toc as t125 } from '/opt/buildhome/repo/docs/spec/data-entry.zh-CN.md?type=frontmatter';
import { demoIndex as dmi125 } from '/opt/buildhome/repo/docs/spec/data-entry.zh-CN.md?type=demo-index';
import { frontmatter as fm126, toc as t126 } from '/opt/buildhome/repo/docs/spec/invitation.en-US.md?type=frontmatter';
import { demoIndex as dmi126 } from '/opt/buildhome/repo/docs/spec/invitation.en-US.md?type=demo-index';
import { frontmatter as fm127, toc as t127 } from '/opt/buildhome/repo/docs/spec/invitation.zh-CN.md?type=frontmatter';
import { demoIndex as dmi127 } from '/opt/buildhome/repo/docs/spec/invitation.zh-CN.md?type=demo-index';
import { frontmatter as fm128, toc as t128 } from '/opt/buildhome/repo/docs/spec/navigation.en-US.md?type=frontmatter';
import { demoIndex as dmi128 } from '/opt/buildhome/repo/docs/spec/navigation.en-US.md?type=demo-index';
import { frontmatter as fm129, toc as t129 } from '/opt/buildhome/repo/docs/spec/navigation.zh-CN.md?type=frontmatter';
import { demoIndex as dmi129 } from '/opt/buildhome/repo/docs/spec/navigation.zh-CN.md?type=demo-index';
import { frontmatter as fm130, toc as t130 } from '/opt/buildhome/repo/docs/spec/repetition.en-US.md?type=frontmatter';
import { demoIndex as dmi130 } from '/opt/buildhome/repo/docs/spec/repetition.en-US.md?type=demo-index';
import { frontmatter as fm131, toc as t131 } from '/opt/buildhome/repo/docs/spec/repetition.zh-CN.md?type=frontmatter';
import { demoIndex as dmi131 } from '/opt/buildhome/repo/docs/spec/repetition.zh-CN.md?type=demo-index';
import { frontmatter as fm132, toc as t132 } from '/opt/buildhome/repo/docs/spec/transition.en-US.md?type=frontmatter';
import { demoIndex as dmi132 } from '/opt/buildhome/repo/docs/spec/transition.en-US.md?type=demo-index';
import { frontmatter as fm133, toc as t133 } from '/opt/buildhome/repo/docs/spec/transition.zh-CN.md?type=frontmatter';
import { demoIndex as dmi133 } from '/opt/buildhome/repo/docs/spec/transition.zh-CN.md?type=demo-index';
import { frontmatter as fm134, toc as t134 } from '/opt/buildhome/repo/docs/blog/css-in-js.en-US.md?type=frontmatter';
import { demoIndex as dmi134 } from '/opt/buildhome/repo/docs/blog/css-in-js.en-US.md?type=demo-index';
import { frontmatter as fm135, toc as t135 } from '/opt/buildhome/repo/docs/blog/css-in-js.zh-CN.md?type=frontmatter';
import { demoIndex as dmi135 } from '/opt/buildhome/repo/docs/blog/css-in-js.zh-CN.md?type=demo-index';
import { frontmatter as fm136, toc as t136 } from '/opt/buildhome/repo/docs/blog/type-util.en-US.md?type=frontmatter';
import { demoIndex as dmi136 } from '/opt/buildhome/repo/docs/blog/type-util.en-US.md?type=demo-index';
import { frontmatter as fm137, toc as t137 } from '/opt/buildhome/repo/docs/blog/type-util.zh-CN.md?type=frontmatter';
import { demoIndex as dmi137 } from '/opt/buildhome/repo/docs/blog/type-util.zh-CN.md?type=demo-index';
import { frontmatter as fm138, toc as t138 } from '/opt/buildhome/repo/docs/spec/alignment.en-US.md?type=frontmatter';
import { demoIndex as dmi138 } from '/opt/buildhome/repo/docs/spec/alignment.en-US.md?type=demo-index';
import { frontmatter as fm139, toc as t139 } from '/opt/buildhome/repo/docs/spec/alignment.zh-CN.md?type=frontmatter';
import { demoIndex as dmi139 } from '/opt/buildhome/repo/docs/spec/alignment.zh-CN.md?type=demo-index';
import { frontmatter as fm140, toc as t140 } from '/opt/buildhome/repo/docs/spec/data-list.en-US.md?type=frontmatter';
import { demoIndex as dmi140 } from '/opt/buildhome/repo/docs/spec/data-list.en-US.md?type=demo-index';
import { frontmatter as fm141, toc as t141 } from '/opt/buildhome/repo/docs/spec/data-list.zh-CN.md?type=frontmatter';
import { demoIndex as dmi141 } from '/opt/buildhome/repo/docs/spec/data-list.zh-CN.md?type=demo-index';
import { frontmatter as fm142, toc as t142 } from '/opt/buildhome/repo/docs/spec/introduce.en-US.md?type=frontmatter';
import { demoIndex as dmi142 } from '/opt/buildhome/repo/docs/spec/introduce.en-US.md?type=demo-index';
import { frontmatter as fm143, toc as t143 } from '/opt/buildhome/repo/docs/spec/introduce.zh-CN.md?type=frontmatter';
import { demoIndex as dmi143 } from '/opt/buildhome/repo/docs/spec/introduce.zh-CN.md?type=demo-index';
import { frontmatter as fm144, toc as t144 } from '/opt/buildhome/repo/docs/spec/proximity.en-US.md?type=frontmatter';
import { demoIndex as dmi144 } from '/opt/buildhome/repo/docs/spec/proximity.en-US.md?type=demo-index';
import { frontmatter as fm145, toc as t145 } from '/opt/buildhome/repo/docs/spec/proximity.zh-CN.md?type=frontmatter';
import { demoIndex as dmi145 } from '/opt/buildhome/repo/docs/spec/proximity.zh-CN.md?type=demo-index';
import { frontmatter as fm146, toc as t146 } from '/opt/buildhome/repo/docs/blog/suspense.en-US.md?type=frontmatter';
import { demoIndex as dmi146 } from '/opt/buildhome/repo/docs/blog/suspense.en-US.md?type=demo-index';
import { frontmatter as fm147, toc as t147 } from '/opt/buildhome/repo/docs/blog/suspense.zh-CN.md?type=frontmatter';
import { demoIndex as dmi147 } from '/opt/buildhome/repo/docs/blog/suspense.zh-CN.md?type=demo-index';
import { frontmatter as fm148, toc as t148 } from '/opt/buildhome/repo/docs/spec/contrast.en-US.md?type=frontmatter';
import { demoIndex as dmi148 } from '/opt/buildhome/repo/docs/spec/contrast.en-US.md?type=demo-index';
import { frontmatter as fm149, toc as t149 } from '/opt/buildhome/repo/docs/spec/contrast.zh-CN.md?type=frontmatter';
import { demoIndex as dmi149 } from '/opt/buildhome/repo/docs/spec/contrast.zh-CN.md?type=demo-index';
import { frontmatter as fm150, toc as t150 } from '/opt/buildhome/repo/docs/spec/feedback.en-US.md?type=frontmatter';
import { demoIndex as dmi150 } from '/opt/buildhome/repo/docs/spec/feedback.en-US.md?type=demo-index';
import { frontmatter as fm151, toc as t151 } from '/opt/buildhome/repo/docs/spec/feedback.zh-CN.md?type=frontmatter';
import { demoIndex as dmi151 } from '/opt/buildhome/repo/docs/spec/feedback.zh-CN.md?type=demo-index';
import { frontmatter as fm152, toc as t152 } from '/opt/buildhome/repo/docs/spec/overview.en-US.md?type=frontmatter';
import { demoIndex as dmi152 } from '/opt/buildhome/repo/docs/spec/overview.en-US.md?type=demo-index';
import { frontmatter as fm153, toc as t153 } from '/opt/buildhome/repo/docs/spec/overview.zh-CN.md?type=frontmatter';
import { demoIndex as dmi153 } from '/opt/buildhome/repo/docs/spec/overview.zh-CN.md?type=demo-index';
import { frontmatter as fm154, toc as t154 } from '/opt/buildhome/repo/docs/spec/reaction.en-US.md?type=frontmatter';
import { demoIndex as dmi154 } from '/opt/buildhome/repo/docs/spec/reaction.en-US.md?type=demo-index';
import { frontmatter as fm155, toc as t155 } from '/opt/buildhome/repo/docs/spec/reaction.zh-CN.md?type=frontmatter';
import { demoIndex as dmi155 } from '/opt/buildhome/repo/docs/spec/reaction.zh-CN.md?type=demo-index';
import { frontmatter as fm156, toc as t156 } from '/opt/buildhome/repo/docs/spec/buttons.en-US.md?type=frontmatter';
import { demoIndex as dmi156 } from '/opt/buildhome/repo/docs/spec/buttons.en-US.md?type=demo-index';
import { frontmatter as fm157, toc as t157 } from '/opt/buildhome/repo/docs/spec/buttons.zh-CN.md?type=frontmatter';
import { demoIndex as dmi157 } from '/opt/buildhome/repo/docs/spec/buttons.zh-CN.md?type=demo-index';
import { frontmatter as fm158, toc as t158 } from '/opt/buildhome/repo/docs/blog/v4-ood.en-US.md?type=frontmatter';
import { demoIndex as dmi158 } from '/opt/buildhome/repo/docs/blog/v4-ood.en-US.md?type=demo-index';
import { frontmatter as fm159, toc as t159 } from '/opt/buildhome/repo/docs/blog/v4-ood.zh-CN.md?type=frontmatter';
import { demoIndex as dmi159 } from '/opt/buildhome/repo/docs/blog/v4-ood.zh-CN.md?type=demo-index';
import { frontmatter as fm160, toc as t160 } from '/opt/buildhome/repo/docs/spec/colors.en-US.md?type=frontmatter';
import { demoIndex as dmi160 } from '/opt/buildhome/repo/docs/spec/colors.en-US.md?type=demo-index';
import { frontmatter as fm161, toc as t161 } from '/opt/buildhome/repo/docs/spec/colors.zh-CN.md?type=frontmatter';
import { demoIndex as dmi161 } from '/opt/buildhome/repo/docs/spec/colors.zh-CN.md?type=demo-index';
import { frontmatter as fm162, toc as t162 } from '/opt/buildhome/repo/docs/spec/direct.en-US.md?type=frontmatter';
import { demoIndex as dmi162 } from '/opt/buildhome/repo/docs/spec/direct.en-US.md?type=demo-index';
import { frontmatter as fm163, toc as t163 } from '/opt/buildhome/repo/docs/spec/direct.zh-CN.md?type=frontmatter';
import { demoIndex as dmi163 } from '/opt/buildhome/repo/docs/spec/direct.zh-CN.md?type=demo-index';
import { frontmatter as fm164, toc as t164 } from '/opt/buildhome/repo/docs/spec/layout.en-US.md?type=frontmatter';
import { demoIndex as dmi164 } from '/opt/buildhome/repo/docs/spec/layout.en-US.md?type=demo-index';
import { frontmatter as fm165, toc as t165 } from '/opt/buildhome/repo/docs/spec/layout.zh-CN.md?type=frontmatter';
import { demoIndex as dmi165 } from '/opt/buildhome/repo/docs/spec/layout.zh-CN.md?type=demo-index';
import { frontmatter as fm166, toc as t166 } from '/opt/buildhome/repo/docs/spec/motion.en-US.md?type=frontmatter';
import { demoIndex as dmi166 } from '/opt/buildhome/repo/docs/spec/motion.en-US.md?type=demo-index';
import { frontmatter as fm167, toc as t167 } from '/opt/buildhome/repo/docs/spec/motion.zh-CN.md?type=frontmatter';
import { demoIndex as dmi167 } from '/opt/buildhome/repo/docs/spec/motion.zh-CN.md?type=demo-index';
import { frontmatter as fm168, toc as t168 } from '/opt/buildhome/repo/docs/spec/shadow.en-US.md?type=frontmatter';
import { demoIndex as dmi168 } from '/opt/buildhome/repo/docs/spec/shadow.en-US.md?type=demo-index';
import { frontmatter as fm169, toc as t169 } from '/opt/buildhome/repo/docs/spec/shadow.zh-CN.md?type=frontmatter';
import { demoIndex as dmi169 } from '/opt/buildhome/repo/docs/spec/shadow.zh-CN.md?type=demo-index';
import { frontmatter as fm170, toc as t170 } from '/opt/buildhome/repo/docs/spec/values.en-US.md?type=frontmatter';
import { demoIndex as dmi170 } from '/opt/buildhome/repo/docs/spec/values.en-US.md?type=demo-index';
import { frontmatter as fm171, toc as t171 } from '/opt/buildhome/repo/docs/spec/values.zh-CN.md?type=frontmatter';
import { demoIndex as dmi171 } from '/opt/buildhome/repo/docs/spec/values.zh-CN.md?type=demo-index';
import { frontmatter as fm172, toc as t172 } from '/opt/buildhome/repo/docs/spec/visual.en-US.md?type=frontmatter';
import { demoIndex as dmi172 } from '/opt/buildhome/repo/docs/spec/visual.en-US.md?type=demo-index';
import { frontmatter as fm173, toc as t173 } from '/opt/buildhome/repo/docs/spec/visual.zh-CN.md?type=frontmatter';
import { demoIndex as dmi173 } from '/opt/buildhome/repo/docs/spec/visual.zh-CN.md?type=demo-index';
import { frontmatter as fm174, toc as t174 } from '/opt/buildhome/repo/docs/react/i18n.en-US.md?type=frontmatter';
import { demoIndex as dmi174 } from '/opt/buildhome/repo/docs/react/i18n.en-US.md?type=demo-index';
import { frontmatter as fm175, toc as t175 } from '/opt/buildhome/repo/docs/react/i18n.zh-CN.md?type=frontmatter';
import { demoIndex as dmi175 } from '/opt/buildhome/repo/docs/react/i18n.zh-CN.md?type=demo-index';
import { frontmatter as fm176, toc as t176 } from '/opt/buildhome/repo/docs/spec/cases.en-US.md?type=frontmatter';
import { demoIndex as dmi176 } from '/opt/buildhome/repo/docs/spec/cases.en-US.md?type=demo-index';
import { frontmatter as fm177, toc as t177 } from '/opt/buildhome/repo/docs/spec/cases.zh-CN.md?type=frontmatter';
import { demoIndex as dmi177 } from '/opt/buildhome/repo/docs/spec/cases.zh-CN.md?type=demo-index';
import { frontmatter as fm178, toc as t178 } from '/opt/buildhome/repo/docs/react/faq.en-US.md?type=frontmatter';
import { demoIndex as dmi178 } from '/opt/buildhome/repo/docs/react/faq.en-US.md?type=demo-index';
import { frontmatter as fm179, toc as t179 } from '/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=frontmatter';
import { demoIndex as dmi179 } from '/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=demo-index';
import { frontmatter as fm180, toc as t180 } from '/opt/buildhome/repo/docs/resources.en-US.md?type=frontmatter';
import { demoIndex as dmi180 } from '/opt/buildhome/repo/docs/resources.en-US.md?type=demo-index';
import { frontmatter as fm181, toc as t181 } from '/opt/buildhome/repo/docs/resources.zh-CN.md?type=frontmatter';
import { demoIndex as dmi181 } from '/opt/buildhome/repo/docs/resources.zh-CN.md?type=demo-index';
import { frontmatter as fm182, toc as t182 } from '/opt/buildhome/repo/docs/spec/dark.en-US.md?type=frontmatter';
import { demoIndex as dmi182 } from '/opt/buildhome/repo/docs/spec/dark.en-US.md?type=demo-index';
import { frontmatter as fm183, toc as t183 } from '/opt/buildhome/repo/docs/spec/dark.zh-CN.md?type=frontmatter';
import { demoIndex as dmi183 } from '/opt/buildhome/repo/docs/spec/dark.zh-CN.md?type=demo-index';
import { frontmatter as fm184, toc as t184 } from '/opt/buildhome/repo/docs/spec/font.en-US.md?type=frontmatter';
import { demoIndex as dmi184 } from '/opt/buildhome/repo/docs/spec/font.en-US.md?type=demo-index';
import { frontmatter as fm185, toc as t185 } from '/opt/buildhome/repo/docs/spec/font.zh-CN.md?type=frontmatter';
import { demoIndex as dmi185 } from '/opt/buildhome/repo/docs/spec/font.zh-CN.md?type=demo-index';
import { frontmatter as fm186, toc as t186 } from '/opt/buildhome/repo/docs/spec/icon.en-US.md?type=frontmatter';
import { demoIndex as dmi186 } from '/opt/buildhome/repo/docs/spec/icon.en-US.md?type=demo-index';
import { frontmatter as fm187, toc as t187 } from '/opt/buildhome/repo/docs/spec/icon.zh-CN.md?type=frontmatter';
import { demoIndex as dmi187 } from '/opt/buildhome/repo/docs/spec/icon.zh-CN.md?type=demo-index';
import { frontmatter as fm188, toc as t188 } from '/opt/buildhome/repo/docs/spec/stay.en-US.md?type=frontmatter';
import { demoIndex as dmi188 } from '/opt/buildhome/repo/docs/spec/stay.en-US.md?type=demo-index';
import { frontmatter as fm189, toc as t189 } from '/opt/buildhome/repo/docs/spec/stay.zh-CN.md?type=frontmatter';
import { demoIndex as dmi189 } from '/opt/buildhome/repo/docs/spec/stay.zh-CN.md?type=demo-index';
import { frontmatter as fm190, toc as t190 } from '/opt/buildhome/repo/components/_util/index.en-US.md?type=frontmatter';
import { demoIndex as dmi190 } from '/opt/buildhome/repo/components/_util/index.en-US.md?type=demo-index';
import { frontmatter as fm191, toc as t191 } from '/opt/buildhome/repo/components/_util/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi191 } from '/opt/buildhome/repo/components/_util/index.zh-CN.md?type=demo-index';
import { frontmatter as fm192, toc as t192 } from '/opt/buildhome/repo/components/affix/index.en-US.md?type=frontmatter';
import { demoIndex as dmi192 } from '/opt/buildhome/repo/components/affix/index.en-US.md?type=demo-index';
import { frontmatter as fm193, toc as t193 } from '/opt/buildhome/repo/components/affix/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi193 } from '/opt/buildhome/repo/components/affix/index.zh-CN.md?type=demo-index';
import { frontmatter as fm194, toc as t194 } from '/opt/buildhome/repo/components/alert/index.en-US.md?type=frontmatter';
import { demoIndex as dmi194 } from '/opt/buildhome/repo/components/alert/index.en-US.md?type=demo-index';
import { frontmatter as fm195, toc as t195 } from '/opt/buildhome/repo/components/alert/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi195 } from '/opt/buildhome/repo/components/alert/index.zh-CN.md?type=demo-index';
import { frontmatter as fm196, toc as t196 } from '/opt/buildhome/repo/components/anchor/index.en-US.md?type=frontmatter';
import { demoIndex as dmi196 } from '/opt/buildhome/repo/components/anchor/index.en-US.md?type=demo-index';
import { frontmatter as fm197, toc as t197 } from '/opt/buildhome/repo/components/anchor/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi197 } from '/opt/buildhome/repo/components/anchor/index.zh-CN.md?type=demo-index';
import { frontmatter as fm198, toc as t198 } from '/opt/buildhome/repo/components/app/index.en-US.md?type=frontmatter';
import { demoIndex as dmi198 } from '/opt/buildhome/repo/components/app/index.en-US.md?type=demo-index';
import { frontmatter as fm199, toc as t199 } from '/opt/buildhome/repo/components/app/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi199 } from '/opt/buildhome/repo/components/app/index.zh-CN.md?type=demo-index';
import { frontmatter as fm200, toc as t200 } from '/opt/buildhome/repo/components/auto-complete/index.en-US.md?type=frontmatter';
import { demoIndex as dmi200 } from '/opt/buildhome/repo/components/auto-complete/index.en-US.md?type=demo-index';
import { frontmatter as fm201, toc as t201 } from '/opt/buildhome/repo/components/auto-complete/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi201 } from '/opt/buildhome/repo/components/auto-complete/index.zh-CN.md?type=demo-index';
import { frontmatter as fm202, toc as t202 } from '/opt/buildhome/repo/components/avatar/index.en-US.md?type=frontmatter';
import { demoIndex as dmi202 } from '/opt/buildhome/repo/components/avatar/index.en-US.md?type=demo-index';
import { frontmatter as fm203, toc as t203 } from '/opt/buildhome/repo/components/avatar/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi203 } from '/opt/buildhome/repo/components/avatar/index.zh-CN.md?type=demo-index';
import { frontmatter as fm204, toc as t204 } from '/opt/buildhome/repo/components/badge/index.en-US.md?type=frontmatter';
import { demoIndex as dmi204 } from '/opt/buildhome/repo/components/badge/index.en-US.md?type=demo-index';
import { frontmatter as fm205, toc as t205 } from '/opt/buildhome/repo/components/badge/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi205 } from '/opt/buildhome/repo/components/badge/index.zh-CN.md?type=demo-index';
import { frontmatter as fm206, toc as t206 } from '/opt/buildhome/repo/components/breadcrumb/index.en-US.md?type=frontmatter';
import { demoIndex as dmi206 } from '/opt/buildhome/repo/components/breadcrumb/index.en-US.md?type=demo-index';
import { frontmatter as fm207, toc as t207 } from '/opt/buildhome/repo/components/breadcrumb/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi207 } from '/opt/buildhome/repo/components/breadcrumb/index.zh-CN.md?type=demo-index';
import { frontmatter as fm208, toc as t208 } from '/opt/buildhome/repo/components/button/index.en-US.md?type=frontmatter';
import { demoIndex as dmi208 } from '/opt/buildhome/repo/components/button/index.en-US.md?type=demo-index';
import { frontmatter as fm209, toc as t209 } from '/opt/buildhome/repo/components/button/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi209 } from '/opt/buildhome/repo/components/button/index.zh-CN.md?type=demo-index';
import { frontmatter as fm210, toc as t210 } from '/opt/buildhome/repo/components/calendar/index.en-US.md?type=frontmatter';
import { demoIndex as dmi210 } from '/opt/buildhome/repo/components/calendar/index.en-US.md?type=demo-index';
import { frontmatter as fm211, toc as t211 } from '/opt/buildhome/repo/components/calendar/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi211 } from '/opt/buildhome/repo/components/calendar/index.zh-CN.md?type=demo-index';
import { frontmatter as fm212, toc as t212 } from '/opt/buildhome/repo/components/card/index.en-US.md?type=frontmatter';
import { demoIndex as dmi212 } from '/opt/buildhome/repo/components/card/index.en-US.md?type=demo-index';
import { frontmatter as fm213, toc as t213 } from '/opt/buildhome/repo/components/card/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi213 } from '/opt/buildhome/repo/components/card/index.zh-CN.md?type=demo-index';
import { frontmatter as fm214, toc as t214 } from '/opt/buildhome/repo/components/carousel/index.en-US.md?type=frontmatter';
import { demoIndex as dmi214 } from '/opt/buildhome/repo/components/carousel/index.en-US.md?type=demo-index';
import { frontmatter as fm215, toc as t215 } from '/opt/buildhome/repo/components/carousel/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi215 } from '/opt/buildhome/repo/components/carousel/index.zh-CN.md?type=demo-index';
import { frontmatter as fm216, toc as t216 } from '/opt/buildhome/repo/components/cascader/index.en-US.md?type=frontmatter';
import { demoIndex as dmi216 } from '/opt/buildhome/repo/components/cascader/index.en-US.md?type=demo-index';
import { frontmatter as fm217, toc as t217 } from '/opt/buildhome/repo/components/cascader/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi217 } from '/opt/buildhome/repo/components/cascader/index.zh-CN.md?type=demo-index';
import { frontmatter as fm218, toc as t218 } from '/opt/buildhome/repo/components/checkbox/index.en-US.md?type=frontmatter';
import { demoIndex as dmi218 } from '/opt/buildhome/repo/components/checkbox/index.en-US.md?type=demo-index';
import { frontmatter as fm219, toc as t219 } from '/opt/buildhome/repo/components/checkbox/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi219 } from '/opt/buildhome/repo/components/checkbox/index.zh-CN.md?type=demo-index';
import { frontmatter as fm220, toc as t220 } from '/opt/buildhome/repo/components/collapse/index.en-US.md?type=frontmatter';
import { demoIndex as dmi220 } from '/opt/buildhome/repo/components/collapse/index.en-US.md?type=demo-index';
import { frontmatter as fm221, toc as t221 } from '/opt/buildhome/repo/components/collapse/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi221 } from '/opt/buildhome/repo/components/collapse/index.zh-CN.md?type=demo-index';
import { frontmatter as fm222, toc as t222 } from '/opt/buildhome/repo/components/color-picker/index.en-US.md?type=frontmatter';
import { demoIndex as dmi222 } from '/opt/buildhome/repo/components/color-picker/index.en-US.md?type=demo-index';
import { frontmatter as fm223, toc as t223 } from '/opt/buildhome/repo/components/color-picker/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi223 } from '/opt/buildhome/repo/components/color-picker/index.zh-CN.md?type=demo-index';
import { frontmatter as fm224, toc as t224 } from '/opt/buildhome/repo/components/config-provider/index.en-US.md?type=frontmatter';
import { demoIndex as dmi224 } from '/opt/buildhome/repo/components/config-provider/index.en-US.md?type=demo-index';
import { frontmatter as fm225, toc as t225 } from '/opt/buildhome/repo/components/config-provider/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi225 } from '/opt/buildhome/repo/components/config-provider/index.zh-CN.md?type=demo-index';
import { frontmatter as fm226, toc as t226 } from '/opt/buildhome/repo/components/date-picker/index.en-US.md?type=frontmatter';
import { demoIndex as dmi226 } from '/opt/buildhome/repo/components/date-picker/index.en-US.md?type=demo-index';
import { frontmatter as fm227, toc as t227 } from '/opt/buildhome/repo/components/date-picker/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi227 } from '/opt/buildhome/repo/components/date-picker/index.zh-CN.md?type=demo-index';
import { frontmatter as fm228, toc as t228 } from '/opt/buildhome/repo/components/descriptions/index.en-US.md?type=frontmatter';
import { demoIndex as dmi228 } from '/opt/buildhome/repo/components/descriptions/index.en-US.md?type=demo-index';
import { frontmatter as fm229, toc as t229 } from '/opt/buildhome/repo/components/descriptions/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi229 } from '/opt/buildhome/repo/components/descriptions/index.zh-CN.md?type=demo-index';
import { frontmatter as fm230, toc as t230 } from '/opt/buildhome/repo/components/divider/index.en-US.md?type=frontmatter';
import { demoIndex as dmi230 } from '/opt/buildhome/repo/components/divider/index.en-US.md?type=demo-index';
import { frontmatter as fm231, toc as t231 } from '/opt/buildhome/repo/components/divider/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi231 } from '/opt/buildhome/repo/components/divider/index.zh-CN.md?type=demo-index';
import { frontmatter as fm232, toc as t232 } from '/opt/buildhome/repo/components/drawer/index.en-US.md?type=frontmatter';
import { demoIndex as dmi232 } from '/opt/buildhome/repo/components/drawer/index.en-US.md?type=demo-index';
import { frontmatter as fm233, toc as t233 } from '/opt/buildhome/repo/components/drawer/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi233 } from '/opt/buildhome/repo/components/drawer/index.zh-CN.md?type=demo-index';
import { frontmatter as fm234, toc as t234 } from '/opt/buildhome/repo/components/dropdown/index.en-US.md?type=frontmatter';
import { demoIndex as dmi234 } from '/opt/buildhome/repo/components/dropdown/index.en-US.md?type=demo-index';
import { frontmatter as fm235, toc as t235 } from '/opt/buildhome/repo/components/dropdown/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi235 } from '/opt/buildhome/repo/components/dropdown/index.zh-CN.md?type=demo-index';
import { frontmatter as fm236, toc as t236 } from '/opt/buildhome/repo/components/empty/index.en-US.md?type=frontmatter';
import { demoIndex as dmi236 } from '/opt/buildhome/repo/components/empty/index.en-US.md?type=demo-index';
import { frontmatter as fm237, toc as t237 } from '/opt/buildhome/repo/components/empty/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi237 } from '/opt/buildhome/repo/components/empty/index.zh-CN.md?type=demo-index';
import { frontmatter as fm238, toc as t238 } from '/opt/buildhome/repo/components/flex/index.en-US.md?type=frontmatter';
import { demoIndex as dmi238 } from '/opt/buildhome/repo/components/flex/index.en-US.md?type=demo-index';
import { frontmatter as fm239, toc as t239 } from '/opt/buildhome/repo/components/flex/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi239 } from '/opt/buildhome/repo/components/flex/index.zh-CN.md?type=demo-index';
import { frontmatter as fm240, toc as t240 } from '/opt/buildhome/repo/components/float-button/index.en-US.md?type=frontmatter';
import { demoIndex as dmi240 } from '/opt/buildhome/repo/components/float-button/index.en-US.md?type=demo-index';
import { frontmatter as fm241, toc as t241 } from '/opt/buildhome/repo/components/float-button/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi241 } from '/opt/buildhome/repo/components/float-button/index.zh-CN.md?type=demo-index';
import { frontmatter as fm242, toc as t242 } from '/opt/buildhome/repo/components/form/index.en-US.md?type=frontmatter';
import { demoIndex as dmi242 } from '/opt/buildhome/repo/components/form/index.en-US.md?type=demo-index';
import { frontmatter as fm243, toc as t243 } from '/opt/buildhome/repo/components/form/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi243 } from '/opt/buildhome/repo/components/form/index.zh-CN.md?type=demo-index';
import { frontmatter as fm244, toc as t244 } from '/opt/buildhome/repo/components/grid/index.en-US.md?type=frontmatter';
import { demoIndex as dmi244 } from '/opt/buildhome/repo/components/grid/index.en-US.md?type=demo-index';
import { frontmatter as fm245, toc as t245 } from '/opt/buildhome/repo/components/grid/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi245 } from '/opt/buildhome/repo/components/grid/index.zh-CN.md?type=demo-index';
import { frontmatter as fm246, toc as t246 } from '/opt/buildhome/repo/components/icon/index.en-US.md?type=frontmatter';
import { demoIndex as dmi246 } from '/opt/buildhome/repo/components/icon/index.en-US.md?type=demo-index';
import { frontmatter as fm247, toc as t247 } from '/opt/buildhome/repo/components/icon/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi247 } from '/opt/buildhome/repo/components/icon/index.zh-CN.md?type=demo-index';
import { frontmatter as fm248, toc as t248 } from '/opt/buildhome/repo/components/image/index.en-US.md?type=frontmatter';
import { demoIndex as dmi248 } from '/opt/buildhome/repo/components/image/index.en-US.md?type=demo-index';
import { frontmatter as fm249, toc as t249 } from '/opt/buildhome/repo/components/image/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi249 } from '/opt/buildhome/repo/components/image/index.zh-CN.md?type=demo-index';
import { frontmatter as fm250, toc as t250 } from '/opt/buildhome/repo/components/input-number/index.en-US.md?type=frontmatter';
import { demoIndex as dmi250 } from '/opt/buildhome/repo/components/input-number/index.en-US.md?type=demo-index';
import { frontmatter as fm251, toc as t251 } from '/opt/buildhome/repo/components/input-number/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi251 } from '/opt/buildhome/repo/components/input-number/index.zh-CN.md?type=demo-index';
import { frontmatter as fm252, toc as t252 } from '/opt/buildhome/repo/components/input/index.en-US.md?type=frontmatter';
import { demoIndex as dmi252 } from '/opt/buildhome/repo/components/input/index.en-US.md?type=demo-index';
import { frontmatter as fm253, toc as t253 } from '/opt/buildhome/repo/components/input/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi253 } from '/opt/buildhome/repo/components/input/index.zh-CN.md?type=demo-index';
import { frontmatter as fm254, toc as t254 } from '/opt/buildhome/repo/components/layout/index.en-US.md?type=frontmatter';
import { demoIndex as dmi254 } from '/opt/buildhome/repo/components/layout/index.en-US.md?type=demo-index';
import { frontmatter as fm255, toc as t255 } from '/opt/buildhome/repo/components/layout/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi255 } from '/opt/buildhome/repo/components/layout/index.zh-CN.md?type=demo-index';
import { frontmatter as fm256, toc as t256 } from '/opt/buildhome/repo/components/list/index.en-US.md?type=frontmatter';
import { demoIndex as dmi256 } from '/opt/buildhome/repo/components/list/index.en-US.md?type=demo-index';
import { frontmatter as fm257, toc as t257 } from '/opt/buildhome/repo/components/list/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi257 } from '/opt/buildhome/repo/components/list/index.zh-CN.md?type=demo-index';
import { frontmatter as fm258, toc as t258 } from '/opt/buildhome/repo/components/mentions/index.en-US.md?type=frontmatter';
import { demoIndex as dmi258 } from '/opt/buildhome/repo/components/mentions/index.en-US.md?type=demo-index';
import { frontmatter as fm259, toc as t259 } from '/opt/buildhome/repo/components/mentions/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi259 } from '/opt/buildhome/repo/components/mentions/index.zh-CN.md?type=demo-index';
import { frontmatter as fm260, toc as t260 } from '/opt/buildhome/repo/components/menu/index.en-US.md?type=frontmatter';
import { demoIndex as dmi260 } from '/opt/buildhome/repo/components/menu/index.en-US.md?type=demo-index';
import { frontmatter as fm261, toc as t261 } from '/opt/buildhome/repo/components/menu/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi261 } from '/opt/buildhome/repo/components/menu/index.zh-CN.md?type=demo-index';
import { frontmatter as fm262, toc as t262 } from '/opt/buildhome/repo/components/message/index.en-US.md?type=frontmatter';
import { demoIndex as dmi262 } from '/opt/buildhome/repo/components/message/index.en-US.md?type=demo-index';
import { frontmatter as fm263, toc as t263 } from '/opt/buildhome/repo/components/message/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi263 } from '/opt/buildhome/repo/components/message/index.zh-CN.md?type=demo-index';
import { frontmatter as fm264, toc as t264 } from '/opt/buildhome/repo/components/modal/index.en-US.md?type=frontmatter';
import { demoIndex as dmi264 } from '/opt/buildhome/repo/components/modal/index.en-US.md?type=demo-index';
import { frontmatter as fm265, toc as t265 } from '/opt/buildhome/repo/components/modal/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi265 } from '/opt/buildhome/repo/components/modal/index.zh-CN.md?type=demo-index';
import { frontmatter as fm266, toc as t266 } from '/opt/buildhome/repo/components/notification/index.en-US.md?type=frontmatter';
import { demoIndex as dmi266 } from '/opt/buildhome/repo/components/notification/index.en-US.md?type=demo-index';
import { frontmatter as fm267, toc as t267 } from '/opt/buildhome/repo/components/notification/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi267 } from '/opt/buildhome/repo/components/notification/index.zh-CN.md?type=demo-index';
import { frontmatter as fm268, toc as t268 } from '/opt/buildhome/repo/components/overview/index.en-US.md?type=frontmatter';
import { demoIndex as dmi268 } from '/opt/buildhome/repo/components/overview/index.en-US.md?type=demo-index';
import { frontmatter as fm269, toc as t269 } from '/opt/buildhome/repo/components/overview/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi269 } from '/opt/buildhome/repo/components/overview/index.zh-CN.md?type=demo-index';
import { frontmatter as fm270, toc as t270 } from '/opt/buildhome/repo/components/pagination/index.en-US.md?type=frontmatter';
import { demoIndex as dmi270 } from '/opt/buildhome/repo/components/pagination/index.en-US.md?type=demo-index';
import { frontmatter as fm271, toc as t271 } from '/opt/buildhome/repo/components/pagination/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi271 } from '/opt/buildhome/repo/components/pagination/index.zh-CN.md?type=demo-index';
import { frontmatter as fm272, toc as t272 } from '/opt/buildhome/repo/components/popconfirm/index.en-US.md?type=frontmatter';
import { demoIndex as dmi272 } from '/opt/buildhome/repo/components/popconfirm/index.en-US.md?type=demo-index';
import { frontmatter as fm273, toc as t273 } from '/opt/buildhome/repo/components/popconfirm/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi273 } from '/opt/buildhome/repo/components/popconfirm/index.zh-CN.md?type=demo-index';
import { frontmatter as fm274, toc as t274 } from '/opt/buildhome/repo/components/popover/index.en-US.md?type=frontmatter';
import { demoIndex as dmi274 } from '/opt/buildhome/repo/components/popover/index.en-US.md?type=demo-index';
import { frontmatter as fm275, toc as t275 } from '/opt/buildhome/repo/components/popover/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi275 } from '/opt/buildhome/repo/components/popover/index.zh-CN.md?type=demo-index';
import { frontmatter as fm276, toc as t276 } from '/opt/buildhome/repo/components/progress/index.en-US.md?type=frontmatter';
import { demoIndex as dmi276 } from '/opt/buildhome/repo/components/progress/index.en-US.md?type=demo-index';
import { frontmatter as fm277, toc as t277 } from '/opt/buildhome/repo/components/progress/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi277 } from '/opt/buildhome/repo/components/progress/index.zh-CN.md?type=demo-index';
import { frontmatter as fm278, toc as t278 } from '/opt/buildhome/repo/components/qr-code/index.en-US.md?type=frontmatter';
import { demoIndex as dmi278 } from '/opt/buildhome/repo/components/qr-code/index.en-US.md?type=demo-index';
import { frontmatter as fm279, toc as t279 } from '/opt/buildhome/repo/components/qr-code/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi279 } from '/opt/buildhome/repo/components/qr-code/index.zh-CN.md?type=demo-index';
import { frontmatter as fm280, toc as t280 } from '/opt/buildhome/repo/components/radio/index.en-US.md?type=frontmatter';
import { demoIndex as dmi280 } from '/opt/buildhome/repo/components/radio/index.en-US.md?type=demo-index';
import { frontmatter as fm281, toc as t281 } from '/opt/buildhome/repo/components/radio/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi281 } from '/opt/buildhome/repo/components/radio/index.zh-CN.md?type=demo-index';
import { frontmatter as fm282, toc as t282 } from '/opt/buildhome/repo/components/rate/index.en-US.md?type=frontmatter';
import { demoIndex as dmi282 } from '/opt/buildhome/repo/components/rate/index.en-US.md?type=demo-index';
import { frontmatter as fm283, toc as t283 } from '/opt/buildhome/repo/components/rate/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi283 } from '/opt/buildhome/repo/components/rate/index.zh-CN.md?type=demo-index';
import { frontmatter as fm284, toc as t284 } from '/opt/buildhome/repo/components/result/index.en-US.md?type=frontmatter';
import { demoIndex as dmi284 } from '/opt/buildhome/repo/components/result/index.en-US.md?type=demo-index';
import { frontmatter as fm285, toc as t285 } from '/opt/buildhome/repo/components/result/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi285 } from '/opt/buildhome/repo/components/result/index.zh-CN.md?type=demo-index';
import { frontmatter as fm286, toc as t286 } from '/opt/buildhome/repo/components/segmented/index.en-US.md?type=frontmatter';
import { demoIndex as dmi286 } from '/opt/buildhome/repo/components/segmented/index.en-US.md?type=demo-index';
import { frontmatter as fm287, toc as t287 } from '/opt/buildhome/repo/components/segmented/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi287 } from '/opt/buildhome/repo/components/segmented/index.zh-CN.md?type=demo-index';
import { frontmatter as fm288, toc as t288 } from '/opt/buildhome/repo/components/select/index.en-US.md?type=frontmatter';
import { demoIndex as dmi288 } from '/opt/buildhome/repo/components/select/index.en-US.md?type=demo-index';
import { frontmatter as fm289, toc as t289 } from '/opt/buildhome/repo/components/select/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi289 } from '/opt/buildhome/repo/components/select/index.zh-CN.md?type=demo-index';
import { frontmatter as fm290, toc as t290 } from '/opt/buildhome/repo/components/skeleton/index.en-US.md?type=frontmatter';
import { demoIndex as dmi290 } from '/opt/buildhome/repo/components/skeleton/index.en-US.md?type=demo-index';
import { frontmatter as fm291, toc as t291 } from '/opt/buildhome/repo/components/skeleton/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi291 } from '/opt/buildhome/repo/components/skeleton/index.zh-CN.md?type=demo-index';
import { frontmatter as fm292, toc as t292 } from '/opt/buildhome/repo/components/slider/index.en-US.md?type=frontmatter';
import { demoIndex as dmi292 } from '/opt/buildhome/repo/components/slider/index.en-US.md?type=demo-index';
import { frontmatter as fm293, toc as t293 } from '/opt/buildhome/repo/components/slider/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi293 } from '/opt/buildhome/repo/components/slider/index.zh-CN.md?type=demo-index';
import { frontmatter as fm294, toc as t294 } from '/opt/buildhome/repo/components/space/index.en-US.md?type=frontmatter';
import { demoIndex as dmi294 } from '/opt/buildhome/repo/components/space/index.en-US.md?type=demo-index';
import { frontmatter as fm295, toc as t295 } from '/opt/buildhome/repo/components/space/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi295 } from '/opt/buildhome/repo/components/space/index.zh-CN.md?type=demo-index';
import { frontmatter as fm296, toc as t296 } from '/opt/buildhome/repo/components/spin/index.en-US.md?type=frontmatter';
import { demoIndex as dmi296 } from '/opt/buildhome/repo/components/spin/index.en-US.md?type=demo-index';
import { frontmatter as fm297, toc as t297 } from '/opt/buildhome/repo/components/spin/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi297 } from '/opt/buildhome/repo/components/spin/index.zh-CN.md?type=demo-index';
import { frontmatter as fm298, toc as t298 } from '/opt/buildhome/repo/components/statistic/index.en-US.md?type=frontmatter';
import { demoIndex as dmi298 } from '/opt/buildhome/repo/components/statistic/index.en-US.md?type=demo-index';
import { frontmatter as fm299, toc as t299 } from '/opt/buildhome/repo/components/statistic/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi299 } from '/opt/buildhome/repo/components/statistic/index.zh-CN.md?type=demo-index';
import { frontmatter as fm300, toc as t300 } from '/opt/buildhome/repo/components/steps/index.en-US.md?type=frontmatter';
import { demoIndex as dmi300 } from '/opt/buildhome/repo/components/steps/index.en-US.md?type=demo-index';
import { frontmatter as fm301, toc as t301 } from '/opt/buildhome/repo/components/steps/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi301 } from '/opt/buildhome/repo/components/steps/index.zh-CN.md?type=demo-index';
import { frontmatter as fm302, toc as t302 } from '/opt/buildhome/repo/components/switch/index.en-US.md?type=frontmatter';
import { demoIndex as dmi302 } from '/opt/buildhome/repo/components/switch/index.en-US.md?type=demo-index';
import { frontmatter as fm303, toc as t303 } from '/opt/buildhome/repo/components/switch/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi303 } from '/opt/buildhome/repo/components/switch/index.zh-CN.md?type=demo-index';
import { frontmatter as fm304, toc as t304 } from '/opt/buildhome/repo/components/table/index.en-US.md?type=frontmatter';
import { demoIndex as dmi304 } from '/opt/buildhome/repo/components/table/index.en-US.md?type=demo-index';
import { frontmatter as fm305, toc as t305 } from '/opt/buildhome/repo/components/table/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi305 } from '/opt/buildhome/repo/components/table/index.zh-CN.md?type=demo-index';
import { frontmatter as fm306, toc as t306 } from '/opt/buildhome/repo/components/tabs/index.en-US.md?type=frontmatter';
import { demoIndex as dmi306 } from '/opt/buildhome/repo/components/tabs/index.en-US.md?type=demo-index';
import { frontmatter as fm307, toc as t307 } from '/opt/buildhome/repo/components/tabs/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi307 } from '/opt/buildhome/repo/components/tabs/index.zh-CN.md?type=demo-index';
import { frontmatter as fm308, toc as t308 } from '/opt/buildhome/repo/components/tag/index.en-US.md?type=frontmatter';
import { demoIndex as dmi308 } from '/opt/buildhome/repo/components/tag/index.en-US.md?type=demo-index';
import { frontmatter as fm309, toc as t309 } from '/opt/buildhome/repo/components/tag/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi309 } from '/opt/buildhome/repo/components/tag/index.zh-CN.md?type=demo-index';
import { frontmatter as fm310, toc as t310 } from '/opt/buildhome/repo/components/time-picker/index.en-US.md?type=frontmatter';
import { demoIndex as dmi310 } from '/opt/buildhome/repo/components/time-picker/index.en-US.md?type=demo-index';
import { frontmatter as fm311, toc as t311 } from '/opt/buildhome/repo/components/time-picker/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi311 } from '/opt/buildhome/repo/components/time-picker/index.zh-CN.md?type=demo-index';
import { frontmatter as fm312, toc as t312 } from '/opt/buildhome/repo/components/timeline/index.en-US.md?type=frontmatter';
import { demoIndex as dmi312 } from '/opt/buildhome/repo/components/timeline/index.en-US.md?type=demo-index';
import { frontmatter as fm313, toc as t313 } from '/opt/buildhome/repo/components/timeline/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi313 } from '/opt/buildhome/repo/components/timeline/index.zh-CN.md?type=demo-index';
import { frontmatter as fm314, toc as t314 } from '/opt/buildhome/repo/components/tooltip/index.en-US.md?type=frontmatter';
import { demoIndex as dmi314 } from '/opt/buildhome/repo/components/tooltip/index.en-US.md?type=demo-index';
import { frontmatter as fm315, toc as t315 } from '/opt/buildhome/repo/components/tooltip/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi315 } from '/opt/buildhome/repo/components/tooltip/index.zh-CN.md?type=demo-index';
import { frontmatter as fm316, toc as t316 } from '/opt/buildhome/repo/components/tour/index.en-US.md?type=frontmatter';
import { demoIndex as dmi316 } from '/opt/buildhome/repo/components/tour/index.en-US.md?type=demo-index';
import { frontmatter as fm317, toc as t317 } from '/opt/buildhome/repo/components/tour/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi317 } from '/opt/buildhome/repo/components/tour/index.zh-CN.md?type=demo-index';
import { frontmatter as fm318, toc as t318 } from '/opt/buildhome/repo/components/transfer/index.en-US.md?type=frontmatter';
import { demoIndex as dmi318 } from '/opt/buildhome/repo/components/transfer/index.en-US.md?type=demo-index';
import { frontmatter as fm319, toc as t319 } from '/opt/buildhome/repo/components/transfer/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi319 } from '/opt/buildhome/repo/components/transfer/index.zh-CN.md?type=demo-index';
import { frontmatter as fm320, toc as t320 } from '/opt/buildhome/repo/components/tree-select/index.en-US.md?type=frontmatter';
import { demoIndex as dmi320 } from '/opt/buildhome/repo/components/tree-select/index.en-US.md?type=demo-index';
import { frontmatter as fm321, toc as t321 } from '/opt/buildhome/repo/components/tree-select/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi321 } from '/opt/buildhome/repo/components/tree-select/index.zh-CN.md?type=demo-index';
import { frontmatter as fm322, toc as t322 } from '/opt/buildhome/repo/components/tree/index.en-US.md?type=frontmatter';
import { demoIndex as dmi322 } from '/opt/buildhome/repo/components/tree/index.en-US.md?type=demo-index';
import { frontmatter as fm323, toc as t323 } from '/opt/buildhome/repo/components/tree/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi323 } from '/opt/buildhome/repo/components/tree/index.zh-CN.md?type=demo-index';
import { frontmatter as fm324, toc as t324 } from '/opt/buildhome/repo/components/typography/index.en-US.md?type=frontmatter';
import { demoIndex as dmi324 } from '/opt/buildhome/repo/components/typography/index.en-US.md?type=demo-index';
import { frontmatter as fm325, toc as t325 } from '/opt/buildhome/repo/components/typography/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi325 } from '/opt/buildhome/repo/components/typography/index.zh-CN.md?type=demo-index';
import { frontmatter as fm326, toc as t326 } from '/opt/buildhome/repo/components/upload/index.en-US.md?type=frontmatter';
import { demoIndex as dmi326 } from '/opt/buildhome/repo/components/upload/index.en-US.md?type=demo-index';
import { frontmatter as fm327, toc as t327 } from '/opt/buildhome/repo/components/upload/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi327 } from '/opt/buildhome/repo/components/upload/index.zh-CN.md?type=demo-index';
import { frontmatter as fm328, toc as t328 } from '/opt/buildhome/repo/components/watermark/index.en-US.md?type=frontmatter';
import { demoIndex as dmi328 } from '/opt/buildhome/repo/components/watermark/index.en-US.md?type=demo-index';
import { frontmatter as fm329, toc as t329 } from '/opt/buildhome/repo/components/watermark/index.zh-CN.md?type=frontmatter';
import { demoIndex as dmi329 } from '/opt/buildhome/repo/components/watermark/index.zh-CN.md?type=demo-index';
import { frontmatter as fm330, toc as t330 } from '/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=frontmatter';
import { demoIndex as dmi330 } from '/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=demo-index';
import { frontmatter as fm331, toc as t331 } from '/opt/buildhome/repo/CHANGELOG.en-US.md?type=frontmatter';
import { demoIndex as dmi331 } from '/opt/buildhome/repo/CHANGELOG.en-US.md?type=demo-index';
import { frontmatter as fm332, toc as t332 } from '/opt/buildhome/repo/components/alert/index.$tab-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi332 } from '/opt/buildhome/repo/components/alert/index.$tab-design.zh-CN.md?type=demo-index';
import { frontmatter as fm333, toc as t333 } from '/opt/buildhome/repo/components/breadcrumb/index.$tab-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi333 } from '/opt/buildhome/repo/components/breadcrumb/index.$tab-design.zh-CN.md?type=demo-index';
import { frontmatter as fm334, toc as t334 } from '/opt/buildhome/repo/components/date-picker/index.$tab-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi334 } from '/opt/buildhome/repo/components/date-picker/index.$tab-design.zh-CN.md?type=demo-index';
import { frontmatter as fm335, toc as t335 } from '/opt/buildhome/repo/components/pagination/index.$tab-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi335 } from '/opt/buildhome/repo/components/pagination/index.$tab-design.zh-CN.md?type=demo-index';
import { frontmatter as fm336, toc as t336 } from '/opt/buildhome/repo/components/progress/index.$tab-design.zh-CN.md?type=frontmatter';
import { demoIndex as dmi336 } from '/opt/buildhome/repo/components/progress/index.$tab-design.zh-CN.md?type=demo-index';

export const filesMeta = {
  'theme-editor-cn/index': {
    frontmatter: fm0,
    toc: t0,
  },
  'theme-editor/index': {
    frontmatter: fm1,
    toc: t1,
  },
  'index-cn/index': {
    frontmatter: fm2,
    toc: t2,
  },
  'index/index': {
    frontmatter: fm3,
    toc: t3,
  },
  'docs/blog/contributor-development-maintenance-guide.en-US': {
    frontmatter: fm4,
    toc: t4,
    demoIndex: dmi4,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/contributor-development-maintenance-guide.en-US.md?type=text'),
  },
  'docs/blog/contributor-development-maintenance-guide.zh-CN': {
    frontmatter: fm5,
    toc: t5,
    demoIndex: dmi5,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/contributor-development-maintenance-guide.zh-CN.md?type=text'),
  },
  'docs/spec/research-message-and-feedback.en-US': {
    frontmatter: fm6,
    toc: t6,
    demoIndex: dmi6,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-message-and-feedback.en-US.md?type=text'),
  },
  'docs/spec/research-message-and-feedback.zh-CN': {
    frontmatter: fm7,
    toc: t7,
    demoIndex: dmi7,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-message-and-feedback.zh-CN.md?type=text'),
  },
  'docs/react/use-with-create-react-app.en-US': {
    frontmatter: fm8,
    toc: t8,
    demoIndex: dmi8,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-create-react-app.en-US.md?type=text'),
  },
  'docs/react/use-with-create-react-app.zh-CN': {
    frontmatter: fm9,
    toc: t9,
    demoIndex: dmi9,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-create-react-app.zh-CN.md?type=text'),
  },
  'docs/react/use-custom-date-library.en-US': {
    frontmatter: fm10,
    toc: t10,
    demoIndex: dmi10,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-custom-date-library.en-US.md?type=text'),
  },
  'docs/react/use-custom-date-library.zh-CN': {
    frontmatter: fm11,
    toc: t11,
    demoIndex: dmi11,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-custom-date-library.zh-CN.md?type=text'),
  },
  'docs/blog/github-actions-workflow.en-US': {
    frontmatter: fm12,
    toc: t12,
    demoIndex: dmi12,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/github-actions-workflow.en-US.md?type=text'),
  },
  'docs/blog/github-actions-workflow.zh-CN': {
    frontmatter: fm13,
    toc: t13,
    demoIndex: dmi13,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/github-actions-workflow.zh-CN.md?type=text'),
  },
  'docs/react/migrate-less-variables.en-US': {
    frontmatter: fm14,
    toc: t14,
    demoIndex: dmi14,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/migrate-less-variables.en-US.md?type=text'),
  },
  'docs/react/migrate-less-variables.zh-CN': {
    frontmatter: fm15,
    toc: t15,
    demoIndex: dmi15,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/migrate-less-variables.zh-CN.md?type=text'),
  },
  'docs/react/server-side-rendering.en-US': {
    frontmatter: fm16,
    toc: t16,
    demoIndex: dmi16,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/server-side-rendering.en-US.md?type=text'),
  },
  'docs/react/server-side-rendering.zh-CN': {
    frontmatter: fm17,
    toc: t17,
    demoIndex: dmi17,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/server-side-rendering.zh-CN.md?type=text'),
  },
  'docs/blog/config-provider-style.en-US': {
    frontmatter: fm18,
    toc: t18,
    demoIndex: dmi18,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/config-provider-style.en-US.md?type=text'),
  },
  'docs/blog/config-provider-style.zh-CN': {
    frontmatter: fm19,
    toc: t19,
    demoIndex: dmi19,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/config-provider-style.zh-CN.md?type=text'),
  },
  'docs/spec/research-navigation.en-US': {
    frontmatter: fm20,
    toc: t20,
    demoIndex: dmi20,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-navigation.en-US.md?type=text'),
  },
  'docs/spec/research-navigation.zh-CN': {
    frontmatter: fm21,
    toc: t21,
    demoIndex: dmi21,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-navigation.zh-CN.md?type=text'),
  },
  'docs/blog/mock-project-build.en-US': {
    frontmatter: fm22,
    toc: t22,
    demoIndex: dmi22,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/mock-project-build.en-US.md?type=text'),
  },
  'docs/blog/mock-project-build.zh-CN': {
    frontmatter: fm23,
    toc: t23,
    demoIndex: dmi23,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/mock-project-build.zh-CN.md?type=text'),
  },
  'docs/blog/notification-stack.en-US': {
    frontmatter: fm24,
    toc: t24,
    demoIndex: dmi24,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/notification-stack.en-US.md?type=text'),
  },
  'docs/blog/notification-stack.zh-CN': {
    frontmatter: fm25,
    toc: t25,
    demoIndex: dmi25,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/notification-stack.zh-CN.md?type=text'),
  },
  'docs/blog/to-be-collaborator.en-US': {
    frontmatter: fm26,
    toc: t26,
    demoIndex: dmi26,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/to-be-collaborator.en-US.md?type=text'),
  },
  'docs/blog/to-be-collaborator.zh-CN': {
    frontmatter: fm27,
    toc: t27,
    demoIndex: dmi27,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/to-be-collaborator.zh-CN.md?type=text'),
  },
  'docs/blog/to-be-what-you-see.en-US': {
    frontmatter: fm28,
    toc: t28,
    demoIndex: dmi28,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/to-be-what-you-see.en-US.md?type=text'),
  },
  'docs/blog/to-be-what-you-see.zh-CN': {
    frontmatter: fm29,
    toc: t29,
    demoIndex: dmi29,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/to-be-what-you-see.zh-CN.md?type=text'),
  },
  'docs/spec/research-exception.en-US': {
    frontmatter: fm30,
    toc: t30,
    demoIndex: dmi30,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-exception.en-US.md?type=text'),
  },
  'docs/spec/research-exception.zh-CN': {
    frontmatter: fm31,
    toc: t31,
    demoIndex: dmi31,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-exception.zh-CN.md?type=text'),
  },
  'docs/spec/research-workbench.en-US': {
    frontmatter: fm32,
    toc: t32,
    demoIndex: dmi32,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-workbench.en-US.md?type=text'),
  },
  'docs/spec/research-workbench.zh-CN': {
    frontmatter: fm33,
    toc: t33,
    demoIndex: dmi33,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-workbench.zh-CN.md?type=text'),
  },
  'docs/spec/visualization-page.en-US': {
    frontmatter: fm34,
    toc: t34,
    demoIndex: dmi34,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/visualization-page.en-US.md?type=text'),
  },
  'docs/spec/visualization-page.zh-CN': {
    frontmatter: fm35,
    toc: t35,
    demoIndex: dmi35,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/visualization-page.zh-CN.md?type=text'),
  },
  'docs/react/compatible-style.en-US': {
    frontmatter: fm36,
    toc: t36,
    demoIndex: dmi36,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/compatible-style.en-US.md?type=text'),
  },
  'docs/react/compatible-style.zh-CN': {
    frontmatter: fm37,
    toc: t37,
    demoIndex: dmi37,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/compatible-style.zh-CN.md?type=text'),
  },
  'docs/react/use-with-rsbuild.en-US': {
    frontmatter: fm38,
    toc: t38,
    demoIndex: dmi38,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-rsbuild.en-US.md?type=text'),
  },
  'docs/react/use-with-rsbuild.zh-CN': {
    frontmatter: fm39,
    toc: t39,
    demoIndex: dmi39,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-rsbuild.zh-CN.md?type=text'),
  },
  'docs/spec/research-overview.en-US': {
    frontmatter: fm40,
    toc: t40,
    demoIndex: dmi40,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-overview.en-US.md?type=text'),
  },
  'docs/spec/research-overview.zh-CN': {
    frontmatter: fm41,
    toc: t41,
    demoIndex: dmi41,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-overview.zh-CN.md?type=text'),
  },
  'docs/blog/modal-hook-order.en-US': {
    frontmatter: fm42,
    toc: t42,
    demoIndex: dmi42,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/modal-hook-order.en-US.md?type=text'),
  },
  'docs/blog/modal-hook-order.zh-CN': {
    frontmatter: fm43,
    toc: t43,
    demoIndex: dmi43,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/modal-hook-order.zh-CN.md?type=text'),
  },
  'docs/react/customize-theme.en-US': {
    frontmatter: fm44,
    toc: t44,
    demoIndex: dmi44,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/customize-theme.en-US.md?type=text'),
  },
  'docs/react/customize-theme.zh-CN': {
    frontmatter: fm45,
    toc: t45,
    demoIndex: dmi45,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/customize-theme.zh-CN.md?type=text'),
  },
  'docs/react/getting-started.en-US': {
    frontmatter: fm46,
    toc: t46,
    demoIndex: dmi46,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/getting-started.en-US.md?type=text'),
  },
  'docs/react/getting-started.zh-CN': {
    frontmatter: fm47,
    toc: t47,
    demoIndex: dmi47,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/getting-started.zh-CN.md?type=text'),
  },
  'docs/blog/historical-debt.en-US': {
    frontmatter: fm48,
    toc: t48,
    demoIndex: dmi48,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/historical-debt.en-US.md?type=text'),
  },
  'docs/blog/historical-debt.zh-CN': {
    frontmatter: fm49,
    toc: t49,
    demoIndex: dmi49,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/historical-debt.zh-CN.md?type=text'),
  },
  'docs/blog/hydrate-cssinjs.en-US': {
    frontmatter: fm50,
    toc: t50,
    demoIndex: dmi50,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/hydrate-cssinjs.en-US.md?type=text'),
  },
  'docs/blog/hydrate-cssinjs.zh-CN': {
    frontmatter: fm51,
    toc: t51,
    demoIndex: dmi51,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/hydrate-cssinjs.zh-CN.md?type=text'),
  },
  'docs/blog/testing-migrate.en-US': {
    frontmatter: fm52,
    toc: t52,
    demoIndex: dmi52,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/testing-migrate.en-US.md?type=text'),
  },
  'docs/blog/testing-migrate.zh-CN': {
    frontmatter: fm53,
    toc: t53,
    demoIndex: dmi53,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/testing-migrate.zh-CN.md?type=text'),
  },
  'docs/react/recommendation.en-US': {
    frontmatter: fm54,
    toc: t54,
    demoIndex: dmi54,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/recommendation.en-US.md?type=text'),
  },
  'docs/react/recommendation.zh-CN': {
    frontmatter: fm55,
    toc: t55,
    demoIndex: dmi55,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/recommendation.zh-CN.md?type=text'),
  },
  'docs/spec/research-result.en-US': {
    frontmatter: fm56,
    toc: t56,
    demoIndex: dmi56,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-result.en-US.md?type=text'),
  },
  'docs/spec/research-result.zh-CN': {
    frontmatter: fm57,
    toc: t57,
    demoIndex: dmi57,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-result.zh-CN.md?type=text'),
  },
  'docs/blog/why-not-static.en-US': {
    frontmatter: fm58,
    toc: t58,
    demoIndex: dmi58,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/why-not-static.en-US.md?type=text'),
  },
  'docs/blog/why-not-static.zh-CN': {
    frontmatter: fm59,
    toc: t59,
    demoIndex: dmi59,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/why-not-static.zh-CN.md?type=text'),
  },
  'docs/react/css-variables.en-US': {
    frontmatter: fm60,
    toc: t60,
    demoIndex: dmi60,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/css-variables.en-US.md?type=text'),
  },
  'docs/react/css-variables.zh-CN': {
    frontmatter: fm61,
    toc: t61,
    demoIndex: dmi61,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/css-variables.zh-CN.md?type=text'),
  },
  'docs/react/use-with-farm.en-US': {
    frontmatter: fm62,
    toc: t62,
    demoIndex: dmi62,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-farm.en-US.md?type=text'),
  },
  'docs/react/use-with-farm.zh-CN': {
    frontmatter: fm63,
    toc: t63,
    demoIndex: dmi63,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-farm.zh-CN.md?type=text'),
  },
  'docs/react/use-with-next.en-US': {
    frontmatter: fm64,
    toc: t64,
    demoIndex: dmi64,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-next.en-US.md?type=text'),
  },
  'docs/react/use-with-next.zh-CN': {
    frontmatter: fm65,
    toc: t65,
    demoIndex: dmi65,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-next.zh-CN.md?type=text'),
  },
  'docs/react/use-with-vite.en-US': {
    frontmatter: fm66,
    toc: t66,
    demoIndex: dmi66,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-vite.en-US.md?type=text'),
  },
  'docs/react/use-with-vite.zh-CN': {
    frontmatter: fm67,
    toc: t67,
    demoIndex: dmi67,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-vite.zh-CN.md?type=text'),
  },
  'docs/spec/research-empty.en-US': {
    frontmatter: fm68,
    toc: t68,
    demoIndex: dmi68,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-empty.en-US.md?type=text'),
  },
  'docs/spec/research-empty.zh-CN': {
    frontmatter: fm69,
    toc: t69,
    demoIndex: dmi69,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-empty.zh-CN.md?type=text'),
  },
  'docs/blog/check-conduct.en-US': {
    frontmatter: fm70,
    toc: t70,
    demoIndex: dmi70,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/check-conduct.en-US.md?type=text'),
  },
  'docs/blog/check-conduct.zh-CN': {
    frontmatter: fm71,
    toc: t71,
    demoIndex: dmi71,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/check-conduct.zh-CN.md?type=text'),
  },
  'docs/blog/line-ellipsis.en-US': {
    frontmatter: fm72,
    toc: t72,
    demoIndex: dmi72,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/line-ellipsis.en-US.md?type=text'),
  },
  'docs/blog/line-ellipsis.zh-CN': {
    frontmatter: fm73,
    toc: t73,
    demoIndex: dmi73,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/line-ellipsis.zh-CN.md?type=text'),
  },
  'docs/blog/tooltip-align.en-US': {
    frontmatter: fm74,
    toc: t74,
    demoIndex: dmi74,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/tooltip-align.en-US.md?type=text'),
  },
  'docs/blog/tooltip-align.zh-CN': {
    frontmatter: fm75,
    toc: t75,
    demoIndex: dmi75,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/tooltip-align.zh-CN.md?type=text'),
  },
  'docs/blog/virtual-table.en-US': {
    frontmatter: fm76,
    toc: t76,
    demoIndex: dmi76,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/virtual-table.en-US.md?type=text'),
  },
  'docs/blog/virtual-table.zh-CN': {
    frontmatter: fm77,
    toc: t77,
    demoIndex: dmi77,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/virtual-table.zh-CN.md?type=text'),
  },
  'docs/react/common-props.en-US': {
    frontmatter: fm78,
    toc: t78,
    demoIndex: dmi78,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/common-props.en-US.md?type=text'),
  },
  'docs/react/common-props.zh-CN': {
    frontmatter: fm79,
    toc: t79,
    demoIndex: dmi79,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/common-props.zh-CN.md?type=text'),
  },
  'docs/react/contributing.en-US': {
    frontmatter: fm80,
    toc: t80,
    demoIndex: dmi80,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/contributing.en-US.md?type=text'),
  },
  'docs/react/contributing.zh-CN': {
    frontmatter: fm81,
    toc: t81,
    demoIndex: dmi81,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/contributing.zh-CN.md?type=text'),
  },
  'docs/react/migration-v5.en-US': {
    frontmatter: fm82,
    toc: t82,
    demoIndex: dmi82,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/migration-v5.en-US.md?type=text'),
  },
  'docs/react/migration-v5.zh-CN': {
    frontmatter: fm83,
    toc: t83,
    demoIndex: dmi83,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/migration-v5.zh-CN.md?type=text'),
  },
  'docs/react/use-with-umi.en-US': {
    frontmatter: fm84,
    toc: t84,
    demoIndex: dmi84,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/use-with-umi.en-US.md?type=text'),
  },
  'docs/react/use-with-umi.zh-CN': {
    frontmatter: fm85,
    toc: t85,
    demoIndex: dmi85,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/use-with-umi.zh-CN.md?type=text'),
  },
  'docs/spec/research-form.en-US': {
    frontmatter: fm86,
    toc: t86,
    demoIndex: dmi86,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-form.en-US.md?type=text'),
  },
  'docs/spec/research-form.zh-CN': {
    frontmatter: fm87,
    toc: t87,
    demoIndex: dmi87,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-form.zh-CN.md?type=text'),
  },
  'docs/spec/research-list.en-US': {
    frontmatter: fm88,
    toc: t88,
    demoIndex: dmi88,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/research-list.en-US.md?type=text'),
  },
  'docs/spec/research-list.zh-CN': {
    frontmatter: fm89,
    toc: t89,
    demoIndex: dmi89,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/research-list.zh-CN.md?type=text'),
  },
  'docs/blog/color-picker.en-US': {
    frontmatter: fm90,
    toc: t90,
    demoIndex: dmi90,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/color-picker.en-US.md?type=text'),
  },
  'docs/blog/color-picker.zh-CN': {
    frontmatter: fm91,
    toc: t91,
    demoIndex: dmi91,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/color-picker.zh-CN.md?type=text'),
  },
  'docs/blog/css-var-plan.en-US': {
    frontmatter: fm92,
    toc: t92,
    demoIndex: dmi92,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/css-var-plan.en-US.md?type=text'),
  },
  'docs/blog/css-var-plan.zh-CN': {
    frontmatter: fm93,
    toc: t93,
    demoIndex: dmi93,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/css-var-plan.zh-CN.md?type=text'),
  },
  'docs/blog/getContainer.en-US': {
    frontmatter: fm94,
    toc: t94,
    demoIndex: dmi94,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/getContainer.en-US.md?type=text'),
  },
  'docs/blog/getContainer.zh-CN': {
    frontmatter: fm95,
    toc: t95,
    demoIndex: dmi95,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/getContainer.zh-CN.md?type=text'),
  },
  'docs/blog/issue-helper.en-US': {
    frontmatter: fm96,
    toc: t96,
    demoIndex: dmi96,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/issue-helper.en-US.md?type=text'),
  },
  'docs/blog/issue-helper.zh-CN': {
    frontmatter: fm97,
    toc: t97,
    demoIndex: dmi97,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/issue-helper.zh-CN.md?type=text'),
  },
  'docs/blog/render-times.en-US': {
    frontmatter: fm98,
    toc: t98,
    demoIndex: dmi98,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/render-times.en-US.md?type=text'),
  },
  'docs/blog/render-times.zh-CN': {
    frontmatter: fm99,
    toc: t99,
    demoIndex: dmi99,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/render-times.zh-CN.md?type=text'),
  },
  'docs/blog/tree-shaking.en-US': {
    frontmatter: fm100,
    toc: t100,
    demoIndex: dmi100,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/tree-shaking.en-US.md?type=text'),
  },
  'docs/blog/tree-shaking.zh-CN': {
    frontmatter: fm101,
    toc: t101,
    demoIndex: dmi101,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/tree-shaking.zh-CN.md?type=text'),
  },
  'docs/spec/data-display.en-US': {
    frontmatter: fm102,
    toc: t102,
    demoIndex: dmi102,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/data-display.en-US.md?type=text'),
  },
  'docs/spec/data-display.zh-CN': {
    frontmatter: fm103,
    toc: t103,
    demoIndex: dmi103,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/data-display.zh-CN.md?type=text'),
  },
  'docs/spec/illustration.en-US': {
    frontmatter: fm104,
    toc: t104,
    demoIndex: dmi104,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/illustration.en-US.md?type=text'),
  },
  'docs/spec/illustration.zh-CN': {
    frontmatter: fm105,
    toc: t105,
    demoIndex: dmi105,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/illustration.zh-CN.md?type=text'),
  },
  'docs/blog/build-ghost.en-US': {
    frontmatter: fm106,
    toc: t106,
    demoIndex: dmi106,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/build-ghost.en-US.md?type=text'),
  },
  'docs/blog/build-ghost.zh-CN': {
    frontmatter: fm107,
    toc: t107,
    demoIndex: dmi107,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/build-ghost.zh-CN.md?type=text'),
  },
  'docs/blog/extract-ssr.en-US': {
    frontmatter: fm108,
    toc: t108,
    demoIndex: dmi108,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/extract-ssr.en-US.md?type=text'),
  },
  'docs/blog/extract-ssr.zh-CN': {
    frontmatter: fm109,
    toc: t109,
    demoIndex: dmi109,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/extract-ssr.zh-CN.md?type=text'),
  },
  'docs/spec/copywriting.en-US': {
    frontmatter: fm110,
    toc: t110,
    demoIndex: dmi110,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/copywriting.en-US.md?type=text'),
  },
  'docs/spec/copywriting.zh-CN': {
    frontmatter: fm111,
    toc: t111,
    demoIndex: dmi111,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/copywriting.zh-CN.md?type=text'),
  },
  'docs/spec/data-format.en-US': {
    frontmatter: fm112,
    toc: t112,
    demoIndex: dmi112,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/data-format.en-US.md?type=text'),
  },
  'docs/spec/data-format.zh-CN': {
    frontmatter: fm113,
    toc: t113,
    demoIndex: dmi113,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/data-format.zh-CN.md?type=text'),
  },
  'docs/spec/detail-page.en-US': {
    frontmatter: fm114,
    toc: t114,
    demoIndex: dmi114,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/detail-page.en-US.md?type=text'),
  },
  'docs/spec/detail-page.zh-CN': {
    frontmatter: fm115,
    toc: t115,
    demoIndex: dmi115,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/detail-page.zh-CN.md?type=text'),
  },
  'docs/spec/lightweight.en-US': {
    frontmatter: fm116,
    toc: t116,
    demoIndex: dmi116,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/lightweight.en-US.md?type=text'),
  },
  'docs/spec/lightweight.zh-CN': {
    frontmatter: fm117,
    toc: t117,
    demoIndex: dmi117,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/lightweight.zh-CN.md?type=text'),
  },
  'docs/blog/form-names.en-US': {
    frontmatter: fm118,
    toc: t118,
    demoIndex: dmi118,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/form-names.en-US.md?type=text'),
  },
  'docs/blog/form-names.zh-CN': {
    frontmatter: fm119,
    toc: t119,
    demoIndex: dmi119,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/form-names.zh-CN.md?type=text'),
  },
  'docs/blog/happy-work.en-US': {
    frontmatter: fm120,
    toc: t120,
    demoIndex: dmi120,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/happy-work.en-US.md?type=text'),
  },
  'docs/blog/happy-work.zh-CN': {
    frontmatter: fm121,
    toc: t121,
    demoIndex: dmi121,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/happy-work.zh-CN.md?type=text'),
  },
  'docs/react/introduce.en-US': {
    frontmatter: fm122,
    toc: t122,
    demoIndex: dmi122,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/introduce.en-US.md?type=text'),
  },
  'docs/react/introduce.zh-CN': {
    frontmatter: fm123,
    toc: t123,
    demoIndex: dmi123,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/introduce.zh-CN.md?type=text'),
  },
  'docs/spec/data-entry.en-US': {
    frontmatter: fm124,
    toc: t124,
    demoIndex: dmi124,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/data-entry.en-US.md?type=text'),
  },
  'docs/spec/data-entry.zh-CN': {
    frontmatter: fm125,
    toc: t125,
    demoIndex: dmi125,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/data-entry.zh-CN.md?type=text'),
  },
  'docs/spec/invitation.en-US': {
    frontmatter: fm126,
    toc: t126,
    demoIndex: dmi126,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/invitation.en-US.md?type=text'),
  },
  'docs/spec/invitation.zh-CN': {
    frontmatter: fm127,
    toc: t127,
    demoIndex: dmi127,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/invitation.zh-CN.md?type=text'),
  },
  'docs/spec/navigation.en-US': {
    frontmatter: fm128,
    toc: t128,
    demoIndex: dmi128,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/navigation.en-US.md?type=text'),
  },
  'docs/spec/navigation.zh-CN': {
    frontmatter: fm129,
    toc: t129,
    demoIndex: dmi129,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/navigation.zh-CN.md?type=text'),
  },
  'docs/spec/repetition.en-US': {
    frontmatter: fm130,
    toc: t130,
    demoIndex: dmi130,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/repetition.en-US.md?type=text'),
  },
  'docs/spec/repetition.zh-CN': {
    frontmatter: fm131,
    toc: t131,
    demoIndex: dmi131,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/repetition.zh-CN.md?type=text'),
  },
  'docs/spec/transition.en-US': {
    frontmatter: fm132,
    toc: t132,
    demoIndex: dmi132,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/transition.en-US.md?type=text'),
  },
  'docs/spec/transition.zh-CN': {
    frontmatter: fm133,
    toc: t133,
    demoIndex: dmi133,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/transition.zh-CN.md?type=text'),
  },
  'docs/blog/css-in-js.en-US': {
    frontmatter: fm134,
    toc: t134,
    demoIndex: dmi134,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/css-in-js.en-US.md?type=text'),
  },
  'docs/blog/css-in-js.zh-CN': {
    frontmatter: fm135,
    toc: t135,
    demoIndex: dmi135,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/css-in-js.zh-CN.md?type=text'),
  },
  'docs/blog/type-util.en-US': {
    frontmatter: fm136,
    toc: t136,
    demoIndex: dmi136,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/type-util.en-US.md?type=text'),
  },
  'docs/blog/type-util.zh-CN': {
    frontmatter: fm137,
    toc: t137,
    demoIndex: dmi137,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/type-util.zh-CN.md?type=text'),
  },
  'docs/spec/alignment.en-US': {
    frontmatter: fm138,
    toc: t138,
    demoIndex: dmi138,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/alignment.en-US.md?type=text'),
  },
  'docs/spec/alignment.zh-CN': {
    frontmatter: fm139,
    toc: t139,
    demoIndex: dmi139,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/alignment.zh-CN.md?type=text'),
  },
  'docs/spec/data-list.en-US': {
    frontmatter: fm140,
    toc: t140,
    demoIndex: dmi140,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/data-list.en-US.md?type=text'),
  },
  'docs/spec/data-list.zh-CN': {
    frontmatter: fm141,
    toc: t141,
    demoIndex: dmi141,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/data-list.zh-CN.md?type=text'),
  },
  'docs/spec/introduce.en-US': {
    frontmatter: fm142,
    toc: t142,
    demoIndex: dmi142,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/introduce.en-US.md?type=text'),
  },
  'docs/spec/introduce.zh-CN': {
    frontmatter: fm143,
    toc: t143,
    demoIndex: dmi143,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/introduce.zh-CN.md?type=text'),
  },
  'docs/spec/proximity.en-US': {
    frontmatter: fm144,
    toc: t144,
    demoIndex: dmi144,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/proximity.en-US.md?type=text'),
  },
  'docs/spec/proximity.zh-CN': {
    frontmatter: fm145,
    toc: t145,
    demoIndex: dmi145,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/proximity.zh-CN.md?type=text'),
  },
  'docs/blog/suspense.en-US': {
    frontmatter: fm146,
    toc: t146,
    demoIndex: dmi146,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/suspense.en-US.md?type=text'),
  },
  'docs/blog/suspense.zh-CN': {
    frontmatter: fm147,
    toc: t147,
    demoIndex: dmi147,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/suspense.zh-CN.md?type=text'),
  },
  'docs/spec/contrast.en-US': {
    frontmatter: fm148,
    toc: t148,
    demoIndex: dmi148,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/contrast.en-US.md?type=text'),
  },
  'docs/spec/contrast.zh-CN': {
    frontmatter: fm149,
    toc: t149,
    demoIndex: dmi149,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/contrast.zh-CN.md?type=text'),
  },
  'docs/spec/feedback.en-US': {
    frontmatter: fm150,
    toc: t150,
    demoIndex: dmi150,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/feedback.en-US.md?type=text'),
  },
  'docs/spec/feedback.zh-CN': {
    frontmatter: fm151,
    toc: t151,
    demoIndex: dmi151,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/feedback.zh-CN.md?type=text'),
  },
  'docs/spec/overview.en-US': {
    frontmatter: fm152,
    toc: t152,
    demoIndex: dmi152,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/overview.en-US.md?type=text'),
  },
  'docs/spec/overview.zh-CN': {
    frontmatter: fm153,
    toc: t153,
    demoIndex: dmi153,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/overview.zh-CN.md?type=text'),
  },
  'docs/spec/reaction.en-US': {
    frontmatter: fm154,
    toc: t154,
    demoIndex: dmi154,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/reaction.en-US.md?type=text'),
  },
  'docs/spec/reaction.zh-CN': {
    frontmatter: fm155,
    toc: t155,
    demoIndex: dmi155,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/reaction.zh-CN.md?type=text'),
  },
  'docs/spec/buttons.en-US': {
    frontmatter: fm156,
    toc: t156,
    demoIndex: dmi156,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/buttons.en-US.md?type=text'),
  },
  'docs/spec/buttons.zh-CN': {
    frontmatter: fm157,
    toc: t157,
    demoIndex: dmi157,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/buttons.zh-CN.md?type=text'),
  },
  'docs/blog/v4-ood.en-US': {
    frontmatter: fm158,
    toc: t158,
    demoIndex: dmi158,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/blog/v4-ood.en-US.md?type=text'),
  },
  'docs/blog/v4-ood.zh-CN': {
    frontmatter: fm159,
    toc: t159,
    demoIndex: dmi159,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/blog/v4-ood.zh-CN.md?type=text'),
  },
  'docs/spec/colors.en-US': {
    frontmatter: fm160,
    toc: t160,
    demoIndex: dmi160,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/colors.en-US.md?type=text'),
  },
  'docs/spec/colors.zh-CN': {
    frontmatter: fm161,
    toc: t161,
    demoIndex: dmi161,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/colors.zh-CN.md?type=text'),
  },
  'docs/spec/direct.en-US': {
    frontmatter: fm162,
    toc: t162,
    demoIndex: dmi162,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/direct.en-US.md?type=text'),
  },
  'docs/spec/direct.zh-CN': {
    frontmatter: fm163,
    toc: t163,
    demoIndex: dmi163,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/direct.zh-CN.md?type=text'),
  },
  'docs/spec/layout.en-US': {
    frontmatter: fm164,
    toc: t164,
    demoIndex: dmi164,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/layout.en-US.md?type=text'),
  },
  'docs/spec/layout.zh-CN': {
    frontmatter: fm165,
    toc: t165,
    demoIndex: dmi165,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/layout.zh-CN.md?type=text'),
  },
  'docs/spec/motion.en-US': {
    frontmatter: fm166,
    toc: t166,
    demoIndex: dmi166,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/motion.en-US.md?type=text'),
  },
  'docs/spec/motion.zh-CN': {
    frontmatter: fm167,
    toc: t167,
    demoIndex: dmi167,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/motion.zh-CN.md?type=text'),
  },
  'docs/spec/shadow.en-US': {
    frontmatter: fm168,
    toc: t168,
    demoIndex: dmi168,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/shadow.en-US.md?type=text'),
  },
  'docs/spec/shadow.zh-CN': {
    frontmatter: fm169,
    toc: t169,
    demoIndex: dmi169,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/shadow.zh-CN.md?type=text'),
  },
  'docs/spec/values.en-US': {
    frontmatter: fm170,
    toc: t170,
    demoIndex: dmi170,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/values.en-US.md?type=text'),
  },
  'docs/spec/values.zh-CN': {
    frontmatter: fm171,
    toc: t171,
    demoIndex: dmi171,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/values.zh-CN.md?type=text'),
  },
  'docs/spec/visual.en-US': {
    frontmatter: fm172,
    toc: t172,
    demoIndex: dmi172,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/visual.en-US.md?type=text'),
  },
  'docs/spec/visual.zh-CN': {
    frontmatter: fm173,
    toc: t173,
    demoIndex: dmi173,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/visual.zh-CN.md?type=text'),
  },
  'docs/react/i18n.en-US': {
    frontmatter: fm174,
    toc: t174,
    demoIndex: dmi174,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/i18n.en-US.md?type=text'),
  },
  'docs/react/i18n.zh-CN': {
    frontmatter: fm175,
    toc: t175,
    demoIndex: dmi175,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/i18n.zh-CN.md?type=text'),
  },
  'docs/spec/cases.en-US': {
    frontmatter: fm176,
    toc: t176,
    demoIndex: dmi176,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/cases.en-US.md?type=text'),
  },
  'docs/spec/cases.zh-CN': {
    frontmatter: fm177,
    toc: t177,
    demoIndex: dmi177,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/cases.zh-CN.md?type=text'),
  },
  'docs/react/faq.en-US': {
    frontmatter: fm178,
    toc: t178,
    demoIndex: dmi178,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/react/faq.en-US.md?type=text'),
  },
  'docs/react/faq.zh-CN': {
    frontmatter: fm179,
    toc: t179,
    demoIndex: dmi179,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/react/faq.zh-CN.md?type=text'),
  },
  'docs/resources.en-US': {
    frontmatter: fm180,
    toc: t180,
    demoIndex: dmi180,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/resources.en-US.md?type=text'),
  },
  'docs/resources.zh-CN': {
    frontmatter: fm181,
    toc: t181,
    demoIndex: dmi181,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/resources.zh-CN.md?type=text'),
  },
  'docs/spec/dark.en-US': {
    frontmatter: fm182,
    toc: t182,
    demoIndex: dmi182,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/dark.en-US.md?type=text'),
  },
  'docs/spec/dark.zh-CN': {
    frontmatter: fm183,
    toc: t183,
    demoIndex: dmi183,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/dark.zh-CN.md?type=text'),
  },
  'docs/spec/font.en-US': {
    frontmatter: fm184,
    toc: t184,
    demoIndex: dmi184,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/font.en-US.md?type=text'),
  },
  'docs/spec/font.zh-CN': {
    frontmatter: fm185,
    toc: t185,
    demoIndex: dmi185,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/font.zh-CN.md?type=text'),
  },
  'docs/spec/icon.en-US': {
    frontmatter: fm186,
    toc: t186,
    demoIndex: dmi186,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/icon.en-US.md?type=text'),
  },
  'docs/spec/icon.zh-CN': {
    frontmatter: fm187,
    toc: t187,
    demoIndex: dmi187,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/icon.zh-CN.md?type=text'),
  },
  'docs/spec/stay.en-US': {
    frontmatter: fm188,
    toc: t188,
    demoIndex: dmi188,
    textGetter: () => import(/* webpackChunkName: "meta__docs__en-US" */'/opt/buildhome/repo/docs/spec/stay.en-US.md?type=text'),
  },
  'docs/spec/stay.zh-CN': {
    frontmatter: fm189,
    toc: t189,
    demoIndex: dmi189,
    textGetter: () => import(/* webpackChunkName: "meta__docs__zh-CN" */'/opt/buildhome/repo/docs/spec/stay.zh-CN.md?type=text'),
  },
  'components/_util/index.en-US': {
    frontmatter: fm190,
    toc: t190,
    demoIndex: dmi190,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/_util/index.en-US.md?type=text'),
  },
  'components/_util/index.zh-CN': {
    frontmatter: fm191,
    toc: t191,
    demoIndex: dmi191,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/_util/index.zh-CN.md?type=text'),
  },
  'components/affix/index.en-US': {
    frontmatter: fm192,
    toc: t192,
    demoIndex: dmi192,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/affix/index.en-US.md?type=text'),
  },
  'components/affix/index.zh-CN': {
    frontmatter: fm193,
    toc: t193,
    demoIndex: dmi193,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/affix/index.zh-CN.md?type=text'),
  },
  'components/alert/index.en-US': {
    frontmatter: fm194,
    toc: t194,
    demoIndex: dmi194,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/alert/index.en-US.md?type=text'),
  },
  'components/alert/index.zh-CN': {
    frontmatter: fm195,
    toc: t195,
    demoIndex: dmi195,
    tabs: ["components/alert/index.$tab-design.zh-CN"],
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/alert/index.zh-CN.md?type=text'),
  },
  'components/anchor/index.en-US': {
    frontmatter: fm196,
    toc: t196,
    demoIndex: dmi196,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/anchor/index.en-US.md?type=text'),
  },
  'components/anchor/index.zh-CN': {
    frontmatter: fm197,
    toc: t197,
    demoIndex: dmi197,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/anchor/index.zh-CN.md?type=text'),
  },
  'components/app/index.en-US': {
    frontmatter: fm198,
    toc: t198,
    demoIndex: dmi198,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/app/index.en-US.md?type=text'),
  },
  'components/app/index.zh-CN': {
    frontmatter: fm199,
    toc: t199,
    demoIndex: dmi199,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/app/index.zh-CN.md?type=text'),
  },
  'components/auto-complete/index.en-US': {
    frontmatter: fm200,
    toc: t200,
    demoIndex: dmi200,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/auto-complete/index.en-US.md?type=text'),
  },
  'components/auto-complete/index.zh-CN': {
    frontmatter: fm201,
    toc: t201,
    demoIndex: dmi201,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/auto-complete/index.zh-CN.md?type=text'),
  },
  'components/avatar/index.en-US': {
    frontmatter: fm202,
    toc: t202,
    demoIndex: dmi202,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/avatar/index.en-US.md?type=text'),
  },
  'components/avatar/index.zh-CN': {
    frontmatter: fm203,
    toc: t203,
    demoIndex: dmi203,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/avatar/index.zh-CN.md?type=text'),
  },
  'components/badge/index.en-US': {
    frontmatter: fm204,
    toc: t204,
    demoIndex: dmi204,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/badge/index.en-US.md?type=text'),
  },
  'components/badge/index.zh-CN': {
    frontmatter: fm205,
    toc: t205,
    demoIndex: dmi205,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/badge/index.zh-CN.md?type=text'),
  },
  'components/breadcrumb/index.en-US': {
    frontmatter: fm206,
    toc: t206,
    demoIndex: dmi206,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/breadcrumb/index.en-US.md?type=text'),
  },
  'components/breadcrumb/index.zh-CN': {
    frontmatter: fm207,
    toc: t207,
    demoIndex: dmi207,
    tabs: ["components/breadcrumb/index.$tab-design.zh-CN"],
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/breadcrumb/index.zh-CN.md?type=text'),
  },
  'components/button/index.en-US': {
    frontmatter: fm208,
    toc: t208,
    demoIndex: dmi208,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/button/index.en-US.md?type=text'),
  },
  'components/button/index.zh-CN': {
    frontmatter: fm209,
    toc: t209,
    demoIndex: dmi209,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/button/index.zh-CN.md?type=text'),
  },
  'components/calendar/index.en-US': {
    frontmatter: fm210,
    toc: t210,
    demoIndex: dmi210,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/calendar/index.en-US.md?type=text'),
  },
  'components/calendar/index.zh-CN': {
    frontmatter: fm211,
    toc: t211,
    demoIndex: dmi211,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/calendar/index.zh-CN.md?type=text'),
  },
  'components/card/index.en-US': {
    frontmatter: fm212,
    toc: t212,
    demoIndex: dmi212,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/card/index.en-US.md?type=text'),
  },
  'components/card/index.zh-CN': {
    frontmatter: fm213,
    toc: t213,
    demoIndex: dmi213,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/card/index.zh-CN.md?type=text'),
  },
  'components/carousel/index.en-US': {
    frontmatter: fm214,
    toc: t214,
    demoIndex: dmi214,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/carousel/index.en-US.md?type=text'),
  },
  'components/carousel/index.zh-CN': {
    frontmatter: fm215,
    toc: t215,
    demoIndex: dmi215,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/carousel/index.zh-CN.md?type=text'),
  },
  'components/cascader/index.en-US': {
    frontmatter: fm216,
    toc: t216,
    demoIndex: dmi216,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/cascader/index.en-US.md?type=text'),
  },
  'components/cascader/index.zh-CN': {
    frontmatter: fm217,
    toc: t217,
    demoIndex: dmi217,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/cascader/index.zh-CN.md?type=text'),
  },
  'components/checkbox/index.en-US': {
    frontmatter: fm218,
    toc: t218,
    demoIndex: dmi218,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/checkbox/index.en-US.md?type=text'),
  },
  'components/checkbox/index.zh-CN': {
    frontmatter: fm219,
    toc: t219,
    demoIndex: dmi219,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/checkbox/index.zh-CN.md?type=text'),
  },
  'components/collapse/index.en-US': {
    frontmatter: fm220,
    toc: t220,
    demoIndex: dmi220,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/collapse/index.en-US.md?type=text'),
  },
  'components/collapse/index.zh-CN': {
    frontmatter: fm221,
    toc: t221,
    demoIndex: dmi221,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/collapse/index.zh-CN.md?type=text'),
  },
  'components/color-picker/index.en-US': {
    frontmatter: fm222,
    toc: t222,
    demoIndex: dmi222,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/color-picker/index.en-US.md?type=text'),
  },
  'components/color-picker/index.zh-CN': {
    frontmatter: fm223,
    toc: t223,
    demoIndex: dmi223,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/color-picker/index.zh-CN.md?type=text'),
  },
  'components/config-provider/index.en-US': {
    frontmatter: fm224,
    toc: t224,
    demoIndex: dmi224,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/config-provider/index.en-US.md?type=text'),
  },
  'components/config-provider/index.zh-CN': {
    frontmatter: fm225,
    toc: t225,
    demoIndex: dmi225,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/config-provider/index.zh-CN.md?type=text'),
  },
  'components/date-picker/index.en-US': {
    frontmatter: fm226,
    toc: t226,
    demoIndex: dmi226,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/date-picker/index.en-US.md?type=text'),
  },
  'components/date-picker/index.zh-CN': {
    frontmatter: fm227,
    toc: t227,
    demoIndex: dmi227,
    tabs: ["components/date-picker/index.$tab-design.zh-CN"],
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/date-picker/index.zh-CN.md?type=text'),
  },
  'components/descriptions/index.en-US': {
    frontmatter: fm228,
    toc: t228,
    demoIndex: dmi228,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/descriptions/index.en-US.md?type=text'),
  },
  'components/descriptions/index.zh-CN': {
    frontmatter: fm229,
    toc: t229,
    demoIndex: dmi229,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/descriptions/index.zh-CN.md?type=text'),
  },
  'components/divider/index.en-US': {
    frontmatter: fm230,
    toc: t230,
    demoIndex: dmi230,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/divider/index.en-US.md?type=text'),
  },
  'components/divider/index.zh-CN': {
    frontmatter: fm231,
    toc: t231,
    demoIndex: dmi231,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/divider/index.zh-CN.md?type=text'),
  },
  'components/drawer/index.en-US': {
    frontmatter: fm232,
    toc: t232,
    demoIndex: dmi232,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/drawer/index.en-US.md?type=text'),
  },
  'components/drawer/index.zh-CN': {
    frontmatter: fm233,
    toc: t233,
    demoIndex: dmi233,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/drawer/index.zh-CN.md?type=text'),
  },
  'components/dropdown/index.en-US': {
    frontmatter: fm234,
    toc: t234,
    demoIndex: dmi234,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/dropdown/index.en-US.md?type=text'),
  },
  'components/dropdown/index.zh-CN': {
    frontmatter: fm235,
    toc: t235,
    demoIndex: dmi235,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/dropdown/index.zh-CN.md?type=text'),
  },
  'components/empty/index.en-US': {
    frontmatter: fm236,
    toc: t236,
    demoIndex: dmi236,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/empty/index.en-US.md?type=text'),
  },
  'components/empty/index.zh-CN': {
    frontmatter: fm237,
    toc: t237,
    demoIndex: dmi237,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/empty/index.zh-CN.md?type=text'),
  },
  'components/flex/index.en-US': {
    frontmatter: fm238,
    toc: t238,
    demoIndex: dmi238,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/flex/index.en-US.md?type=text'),
  },
  'components/flex/index.zh-CN': {
    frontmatter: fm239,
    toc: t239,
    demoIndex: dmi239,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/flex/index.zh-CN.md?type=text'),
  },
  'components/float-button/index.en-US': {
    frontmatter: fm240,
    toc: t240,
    demoIndex: dmi240,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/float-button/index.en-US.md?type=text'),
  },
  'components/float-button/index.zh-CN': {
    frontmatter: fm241,
    toc: t241,
    demoIndex: dmi241,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/float-button/index.zh-CN.md?type=text'),
  },
  'components/form/index.en-US': {
    frontmatter: fm242,
    toc: t242,
    demoIndex: dmi242,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/form/index.en-US.md?type=text'),
  },
  'components/form/index.zh-CN': {
    frontmatter: fm243,
    toc: t243,
    demoIndex: dmi243,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/form/index.zh-CN.md?type=text'),
  },
  'components/grid/index.en-US': {
    frontmatter: fm244,
    toc: t244,
    demoIndex: dmi244,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/grid/index.en-US.md?type=text'),
  },
  'components/grid/index.zh-CN': {
    frontmatter: fm245,
    toc: t245,
    demoIndex: dmi245,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/grid/index.zh-CN.md?type=text'),
  },
  'components/icon/index.en-US': {
    frontmatter: fm246,
    toc: t246,
    demoIndex: dmi246,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/icon/index.en-US.md?type=text'),
  },
  'components/icon/index.zh-CN': {
    frontmatter: fm247,
    toc: t247,
    demoIndex: dmi247,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/icon/index.zh-CN.md?type=text'),
  },
  'components/image/index.en-US': {
    frontmatter: fm248,
    toc: t248,
    demoIndex: dmi248,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/image/index.en-US.md?type=text'),
  },
  'components/image/index.zh-CN': {
    frontmatter: fm249,
    toc: t249,
    demoIndex: dmi249,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/image/index.zh-CN.md?type=text'),
  },
  'components/input-number/index.en-US': {
    frontmatter: fm250,
    toc: t250,
    demoIndex: dmi250,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/input-number/index.en-US.md?type=text'),
  },
  'components/input-number/index.zh-CN': {
    frontmatter: fm251,
    toc: t251,
    demoIndex: dmi251,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/input-number/index.zh-CN.md?type=text'),
  },
  'components/input/index.en-US': {
    frontmatter: fm252,
    toc: t252,
    demoIndex: dmi252,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/input/index.en-US.md?type=text'),
  },
  'components/input/index.zh-CN': {
    frontmatter: fm253,
    toc: t253,
    demoIndex: dmi253,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/input/index.zh-CN.md?type=text'),
  },
  'components/layout/index.en-US': {
    frontmatter: fm254,
    toc: t254,
    demoIndex: dmi254,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/layout/index.en-US.md?type=text'),
  },
  'components/layout/index.zh-CN': {
    frontmatter: fm255,
    toc: t255,
    demoIndex: dmi255,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/layout/index.zh-CN.md?type=text'),
  },
  'components/list/index.en-US': {
    frontmatter: fm256,
    toc: t256,
    demoIndex: dmi256,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/list/index.en-US.md?type=text'),
  },
  'components/list/index.zh-CN': {
    frontmatter: fm257,
    toc: t257,
    demoIndex: dmi257,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/list/index.zh-CN.md?type=text'),
  },
  'components/mentions/index.en-US': {
    frontmatter: fm258,
    toc: t258,
    demoIndex: dmi258,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/mentions/index.en-US.md?type=text'),
  },
  'components/mentions/index.zh-CN': {
    frontmatter: fm259,
    toc: t259,
    demoIndex: dmi259,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/mentions/index.zh-CN.md?type=text'),
  },
  'components/menu/index.en-US': {
    frontmatter: fm260,
    toc: t260,
    demoIndex: dmi260,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/menu/index.en-US.md?type=text'),
  },
  'components/menu/index.zh-CN': {
    frontmatter: fm261,
    toc: t261,
    demoIndex: dmi261,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/menu/index.zh-CN.md?type=text'),
  },
  'components/message/index.en-US': {
    frontmatter: fm262,
    toc: t262,
    demoIndex: dmi262,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/message/index.en-US.md?type=text'),
  },
  'components/message/index.zh-CN': {
    frontmatter: fm263,
    toc: t263,
    demoIndex: dmi263,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/message/index.zh-CN.md?type=text'),
  },
  'components/modal/index.en-US': {
    frontmatter: fm264,
    toc: t264,
    demoIndex: dmi264,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/modal/index.en-US.md?type=text'),
  },
  'components/modal/index.zh-CN': {
    frontmatter: fm265,
    toc: t265,
    demoIndex: dmi265,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/modal/index.zh-CN.md?type=text'),
  },
  'components/notification/index.en-US': {
    frontmatter: fm266,
    toc: t266,
    demoIndex: dmi266,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/notification/index.en-US.md?type=text'),
  },
  'components/notification/index.zh-CN': {
    frontmatter: fm267,
    toc: t267,
    demoIndex: dmi267,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/notification/index.zh-CN.md?type=text'),
  },
  'components/overview/index.en-US': {
    frontmatter: fm268,
    toc: t268,
    demoIndex: dmi268,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/overview/index.en-US.md?type=text'),
  },
  'components/overview/index.zh-CN': {
    frontmatter: fm269,
    toc: t269,
    demoIndex: dmi269,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/overview/index.zh-CN.md?type=text'),
  },
  'components/pagination/index.en-US': {
    frontmatter: fm270,
    toc: t270,
    demoIndex: dmi270,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/pagination/index.en-US.md?type=text'),
  },
  'components/pagination/index.zh-CN': {
    frontmatter: fm271,
    toc: t271,
    demoIndex: dmi271,
    tabs: ["components/pagination/index.$tab-design.zh-CN"],
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/pagination/index.zh-CN.md?type=text'),
  },
  'components/popconfirm/index.en-US': {
    frontmatter: fm272,
    toc: t272,
    demoIndex: dmi272,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/popconfirm/index.en-US.md?type=text'),
  },
  'components/popconfirm/index.zh-CN': {
    frontmatter: fm273,
    toc: t273,
    demoIndex: dmi273,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/popconfirm/index.zh-CN.md?type=text'),
  },
  'components/popover/index.en-US': {
    frontmatter: fm274,
    toc: t274,
    demoIndex: dmi274,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/popover/index.en-US.md?type=text'),
  },
  'components/popover/index.zh-CN': {
    frontmatter: fm275,
    toc: t275,
    demoIndex: dmi275,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/popover/index.zh-CN.md?type=text'),
  },
  'components/progress/index.en-US': {
    frontmatter: fm276,
    toc: t276,
    demoIndex: dmi276,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/progress/index.en-US.md?type=text'),
  },
  'components/progress/index.zh-CN': {
    frontmatter: fm277,
    toc: t277,
    demoIndex: dmi277,
    tabs: ["components/progress/index.$tab-design.zh-CN"],
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/progress/index.zh-CN.md?type=text'),
  },
  'components/qr-code/index.en-US': {
    frontmatter: fm278,
    toc: t278,
    demoIndex: dmi278,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/qr-code/index.en-US.md?type=text'),
  },
  'components/qr-code/index.zh-CN': {
    frontmatter: fm279,
    toc: t279,
    demoIndex: dmi279,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/qr-code/index.zh-CN.md?type=text'),
  },
  'components/radio/index.en-US': {
    frontmatter: fm280,
    toc: t280,
    demoIndex: dmi280,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/radio/index.en-US.md?type=text'),
  },
  'components/radio/index.zh-CN': {
    frontmatter: fm281,
    toc: t281,
    demoIndex: dmi281,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/radio/index.zh-CN.md?type=text'),
  },
  'components/rate/index.en-US': {
    frontmatter: fm282,
    toc: t282,
    demoIndex: dmi282,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/rate/index.en-US.md?type=text'),
  },
  'components/rate/index.zh-CN': {
    frontmatter: fm283,
    toc: t283,
    demoIndex: dmi283,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/rate/index.zh-CN.md?type=text'),
  },
  'components/result/index.en-US': {
    frontmatter: fm284,
    toc: t284,
    demoIndex: dmi284,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/result/index.en-US.md?type=text'),
  },
  'components/result/index.zh-CN': {
    frontmatter: fm285,
    toc: t285,
    demoIndex: dmi285,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/result/index.zh-CN.md?type=text'),
  },
  'components/segmented/index.en-US': {
    frontmatter: fm286,
    toc: t286,
    demoIndex: dmi286,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/segmented/index.en-US.md?type=text'),
  },
  'components/segmented/index.zh-CN': {
    frontmatter: fm287,
    toc: t287,
    demoIndex: dmi287,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/segmented/index.zh-CN.md?type=text'),
  },
  'components/select/index.en-US': {
    frontmatter: fm288,
    toc: t288,
    demoIndex: dmi288,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/select/index.en-US.md?type=text'),
  },
  'components/select/index.zh-CN': {
    frontmatter: fm289,
    toc: t289,
    demoIndex: dmi289,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/select/index.zh-CN.md?type=text'),
  },
  'components/skeleton/index.en-US': {
    frontmatter: fm290,
    toc: t290,
    demoIndex: dmi290,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/skeleton/index.en-US.md?type=text'),
  },
  'components/skeleton/index.zh-CN': {
    frontmatter: fm291,
    toc: t291,
    demoIndex: dmi291,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/skeleton/index.zh-CN.md?type=text'),
  },
  'components/slider/index.en-US': {
    frontmatter: fm292,
    toc: t292,
    demoIndex: dmi292,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/slider/index.en-US.md?type=text'),
  },
  'components/slider/index.zh-CN': {
    frontmatter: fm293,
    toc: t293,
    demoIndex: dmi293,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/slider/index.zh-CN.md?type=text'),
  },
  'components/space/index.en-US': {
    frontmatter: fm294,
    toc: t294,
    demoIndex: dmi294,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/space/index.en-US.md?type=text'),
  },
  'components/space/index.zh-CN': {
    frontmatter: fm295,
    toc: t295,
    demoIndex: dmi295,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/space/index.zh-CN.md?type=text'),
  },
  'components/spin/index.en-US': {
    frontmatter: fm296,
    toc: t296,
    demoIndex: dmi296,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/spin/index.en-US.md?type=text'),
  },
  'components/spin/index.zh-CN': {
    frontmatter: fm297,
    toc: t297,
    demoIndex: dmi297,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/spin/index.zh-CN.md?type=text'),
  },
  'components/statistic/index.en-US': {
    frontmatter: fm298,
    toc: t298,
    demoIndex: dmi298,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/statistic/index.en-US.md?type=text'),
  },
  'components/statistic/index.zh-CN': {
    frontmatter: fm299,
    toc: t299,
    demoIndex: dmi299,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/statistic/index.zh-CN.md?type=text'),
  },
  'components/steps/index.en-US': {
    frontmatter: fm300,
    toc: t300,
    demoIndex: dmi300,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/steps/index.en-US.md?type=text'),
  },
  'components/steps/index.zh-CN': {
    frontmatter: fm301,
    toc: t301,
    demoIndex: dmi301,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/steps/index.zh-CN.md?type=text'),
  },
  'components/switch/index.en-US': {
    frontmatter: fm302,
    toc: t302,
    demoIndex: dmi302,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/switch/index.en-US.md?type=text'),
  },
  'components/switch/index.zh-CN': {
    frontmatter: fm303,
    toc: t303,
    demoIndex: dmi303,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/switch/index.zh-CN.md?type=text'),
  },
  'components/table/index.en-US': {
    frontmatter: fm304,
    toc: t304,
    demoIndex: dmi304,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/table/index.en-US.md?type=text'),
  },
  'components/table/index.zh-CN': {
    frontmatter: fm305,
    toc: t305,
    demoIndex: dmi305,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/table/index.zh-CN.md?type=text'),
  },
  'components/tabs/index.en-US': {
    frontmatter: fm306,
    toc: t306,
    demoIndex: dmi306,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/tabs/index.en-US.md?type=text'),
  },
  'components/tabs/index.zh-CN': {
    frontmatter: fm307,
    toc: t307,
    demoIndex: dmi307,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/tabs/index.zh-CN.md?type=text'),
  },
  'components/tag/index.en-US': {
    frontmatter: fm308,
    toc: t308,
    demoIndex: dmi308,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/tag/index.en-US.md?type=text'),
  },
  'components/tag/index.zh-CN': {
    frontmatter: fm309,
    toc: t309,
    demoIndex: dmi309,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/tag/index.zh-CN.md?type=text'),
  },
  'components/time-picker/index.en-US': {
    frontmatter: fm310,
    toc: t310,
    demoIndex: dmi310,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/time-picker/index.en-US.md?type=text'),
  },
  'components/time-picker/index.zh-CN': {
    frontmatter: fm311,
    toc: t311,
    demoIndex: dmi311,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/time-picker/index.zh-CN.md?type=text'),
  },
  'components/timeline/index.en-US': {
    frontmatter: fm312,
    toc: t312,
    demoIndex: dmi312,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/timeline/index.en-US.md?type=text'),
  },
  'components/timeline/index.zh-CN': {
    frontmatter: fm313,
    toc: t313,
    demoIndex: dmi313,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/timeline/index.zh-CN.md?type=text'),
  },
  'components/tooltip/index.en-US': {
    frontmatter: fm314,
    toc: t314,
    demoIndex: dmi314,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/tooltip/index.en-US.md?type=text'),
  },
  'components/tooltip/index.zh-CN': {
    frontmatter: fm315,
    toc: t315,
    demoIndex: dmi315,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/tooltip/index.zh-CN.md?type=text'),
  },
  'components/tour/index.en-US': {
    frontmatter: fm316,
    toc: t316,
    demoIndex: dmi316,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/tour/index.en-US.md?type=text'),
  },
  'components/tour/index.zh-CN': {
    frontmatter: fm317,
    toc: t317,
    demoIndex: dmi317,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/tour/index.zh-CN.md?type=text'),
  },
  'components/transfer/index.en-US': {
    frontmatter: fm318,
    toc: t318,
    demoIndex: dmi318,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/transfer/index.en-US.md?type=text'),
  },
  'components/transfer/index.zh-CN': {
    frontmatter: fm319,
    toc: t319,
    demoIndex: dmi319,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/transfer/index.zh-CN.md?type=text'),
  },
  'components/tree-select/index.en-US': {
    frontmatter: fm320,
    toc: t320,
    demoIndex: dmi320,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/tree-select/index.en-US.md?type=text'),
  },
  'components/tree-select/index.zh-CN': {
    frontmatter: fm321,
    toc: t321,
    demoIndex: dmi321,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/tree-select/index.zh-CN.md?type=text'),
  },
  'components/tree/index.en-US': {
    frontmatter: fm322,
    toc: t322,
    demoIndex: dmi322,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/tree/index.en-US.md?type=text'),
  },
  'components/tree/index.zh-CN': {
    frontmatter: fm323,
    toc: t323,
    demoIndex: dmi323,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/tree/index.zh-CN.md?type=text'),
  },
  'components/typography/index.en-US': {
    frontmatter: fm324,
    toc: t324,
    demoIndex: dmi324,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/typography/index.en-US.md?type=text'),
  },
  'components/typography/index.zh-CN': {
    frontmatter: fm325,
    toc: t325,
    demoIndex: dmi325,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/typography/index.zh-CN.md?type=text'),
  },
  'components/upload/index.en-US': {
    frontmatter: fm326,
    toc: t326,
    demoIndex: dmi326,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/upload/index.en-US.md?type=text'),
  },
  'components/upload/index.zh-CN': {
    frontmatter: fm327,
    toc: t327,
    demoIndex: dmi327,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/upload/index.zh-CN.md?type=text'),
  },
  'components/watermark/index.en-US': {
    frontmatter: fm328,
    toc: t328,
    demoIndex: dmi328,
    textGetter: () => import(/* webpackChunkName: "meta__components__en-US" */'/opt/buildhome/repo/components/watermark/index.en-US.md?type=text'),
  },
  'components/watermark/index.zh-CN': {
    frontmatter: fm329,
    toc: t329,
    demoIndex: dmi329,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/watermark/index.zh-CN.md?type=text'),
  },
  'changelog-cn': {
    frontmatter: fm330,
    toc: t330,
    demoIndex: dmi330,
    textGetter: () => import(/* webpackChunkName: "meta__CHANGELOG.zh-CN.md__zh-CN" */'/opt/buildhome/repo/CHANGELOG.zh-CN.md?type=text'),
  },
  'changelog': {
    frontmatter: fm331,
    toc: t331,
    demoIndex: dmi331,
    textGetter: () => import(/* webpackChunkName: "meta__CHANGELOG.en-US.md__en-US" */'/opt/buildhome/repo/CHANGELOG.en-US.md?type=text'),
  },
  'components/alert/index.$tab-design.zh-CN': {
    frontmatter: fm332,
    toc: t332,
    demoIndex: dmi332,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/alert/index.$tab-design.zh-CN.md?type=text'),
  },
  'components/breadcrumb/index.$tab-design.zh-CN': {
    frontmatter: fm333,
    toc: t333,
    demoIndex: dmi333,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/breadcrumb/index.$tab-design.zh-CN.md?type=text'),
  },
  'components/date-picker/index.$tab-design.zh-CN': {
    frontmatter: fm334,
    toc: t334,
    demoIndex: dmi334,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/date-picker/index.$tab-design.zh-CN.md?type=text'),
  },
  'components/pagination/index.$tab-design.zh-CN': {
    frontmatter: fm335,
    toc: t335,
    demoIndex: dmi335,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/pagination/index.$tab-design.zh-CN.md?type=text'),
  },
  'components/progress/index.$tab-design.zh-CN': {
    frontmatter: fm336,
    toc: t336,
    demoIndex: dmi336,
    textGetter: () => import(/* webpackChunkName: "meta__components__zh-CN" */'/opt/buildhome/repo/components/progress/index.$tab-design.zh-CN.md?type=text'),
  },
}

export { tabs as tabsMeta } from './tabs';
