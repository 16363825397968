// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import * as tab0 from '/opt/buildhome/repo/components/alert/index.$tab-design.zh-CN.md';
import * as tab1 from '/opt/buildhome/repo/components/breadcrumb/index.$tab-design.zh-CN.md';
import * as tab2 from '/opt/buildhome/repo/components/date-picker/index.$tab-design.zh-CN.md';
import * as tab3 from '/opt/buildhome/repo/components/pagination/index.$tab-design.zh-CN.md';
import * as tab4 from '/opt/buildhome/repo/components/progress/index.$tab-design.zh-CN.md';

export const tabs = {
  'components/alert/index.$tab-design.zh-CN': { key: 'design', title: '', titleIntlId: '', components: tab0 },
  'components/breadcrumb/index.$tab-design.zh-CN': { key: 'design', title: '', titleIntlId: '', components: tab1 },
  'components/date-picker/index.$tab-design.zh-CN': { key: 'design', title: '', titleIntlId: '', components: tab2 },
  'components/pagination/index.$tab-design.zh-CN': { key: 'design', title: '', titleIntlId: '', components: tab3 },
  'components/progress/index.$tab-design.zh-CN': { key: 'design', title: '', titleIntlId: '', components: tab4 },
}
