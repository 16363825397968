var locale = {
  _lang: 'en-US',
  followPrimary: 'Follow Brand Color',
  reset: 'Reset',
  next: 'Next',
  groupView: 'Group View',
  fill: 'Fill',
  border: 'Border',
  background: 'Background',
  text: 'Text',
  title: 'Theme Editor',
  globalToken: 'Global',
  componentToken: 'Component',
  advancedMode: 'Advanced Mode',
  basicMode: 'Basic Mode',
  import: 'Import',
  export: 'Export',
  color: 'Color',
  size: 'Size',
  style: 'Style',
  component: 'Comp',
  themeConfig: 'Config',
  algorithmTip: "Tips: The string dark and compact in algorithm config represent darkAlgorithm and compactAlgorithm from antd, this is only for theme editor config. If you want to copy to code, please replace them with theme.darkAlgorithm and theme.compactAlgorithm imported from antd.",
  total: '',
  changes: 'Changes',
  tokenInfo: 'Token',
  enableComponentAlgorithm: 'Algorithm',
  componentAlgorithm: 'If enabled, seed token of component will affect other tokens like global seed token',
  presetColors: 'Preset Colors',
  demo: {
    overview: 'Overview',
    components: 'Components',
    relatedTokens: 'Related Tokens',
    page: 'Page',
    fitView: 'Fit View',
    zoomTo: 'Zoom to',
    relatedTokensDescription: 'Related Tokens, click to view what changes',
    tokenCount: function tokenCount(total, global, component) {
      return "Token Count: ".concat(total, " (").concat(global, " Global Token / ").concat(component, " Component Token)");
    }
  },
  components: {
    General: 'General',
    Layout: 'Layout',
    Navigation: 'Navigation',
    'Data Entry': 'Data Entry',
    'Data Display': 'Data Display',
    Feedback: 'Feedback',
    Button: 'Button',
    Icon: 'Icon',
    Typography: 'Typography',
    Divider: 'Divider',
    Grid: 'Grid',
    Space: 'Space',
    Anchor: 'Anchor',
    Breadcrumb: 'Breadcrumb',
    Dropdown: 'Dropdown',
    Menu: 'Menu',
    Pagination: 'Pagination',
    Steps: 'Steps',
    AutoComplete: 'AutoComplete',
    Cascader: 'Cascader',
    Checkbox: 'Checkbox',
    DatePicker: 'DatePicker',
    Form: 'Form',
    Input: 'Input',
    InputNumber: 'InputNumber',
    Mentions: 'Mentions',
    Radio: 'Radio',
    Rate: 'Rate',
    Select: 'Select',
    Slider: 'Slider',
    Switch: 'Switch',
    TimePicker: 'TimePicker',
    Transfer: 'Transfer',
    TreeSelect: 'TreeSelect',
    Upload: 'Upload',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Calendar: 'Calendar',
    Card: 'Card',
    Carousel: 'Carousel',
    Collapse: 'Collapse',
    Descriptions: 'Descriptions',
    Empty: 'Empty',
    Image: 'Image',
    List: 'List',
    Popover: 'Popover',
    Segmented: 'Segmented',
    Statistic: 'Statistic',
    Table: 'Table',
    Tabs: 'Tabs',
    Tag: 'Tag',
    Timeline: 'Timeline',
    Tooltip: 'Tooltip',
    Tree: 'Tree',
    Alert: 'Alert',
    Drawer: 'Drawer',
    Message: 'Message',
    Modal: 'Modal',
    Notification: 'Notification',
    Popconfirm: 'Popconfirm',
    Progress: 'Progress',
    Result: 'Result',
    Skeleton: 'Skeleton',
    Spin: 'Spin'
  }
};
export default locale;